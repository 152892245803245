import React, { useEffect, useState } from 'react';
import { CLIENT_INFO, EXPORT_SUGGESTION_AND_COMPLAINTS } from 'common_constants/modals';
import { Row, Col, Typography, Spin, Card, Popover, Pagination, Button, DatePicker, Row as AntdRow } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import { setModal } from '../../store/commonReducer';
import HoverableUser from '../../components/HoverableUser';
import { request, error } from '../../tools';
import { ExcelBtn } from 'common_components';
import { CSVLink } from 'react-csv';
import { ROLES } from 'common_constants/business';
import { setMainLoader } from '../../store/uiReducer';

import Excel from '../../images/excel_logo.svg';

import './styles.scss';

dayjs.extend(relativeTime);

const Complaints = () => {
  const dispatch = useDispatch();

  const users = useSelector((state) => state.common.users);
  const userAuth = useSelector((state) => state.common.userAuth);
  const admin = ROLES[userAuth.role] === ROLES.admin;

  const [complaints, setComplaints] = useState([]);
  const [loading, setLoading] = useState(false);
  const [complaintsLength, setComplaintsLength] = useState(0);
  const [exportData, setExportData] = useState(null);
  const [exportRange, setExportRange] = useState(null);

  const { Text } = Typography;

  const COMPLAINTS_PER_PAGE = 12;

  useEffect(() => {
    getComplaints(1);
  }, []);

  const getComplaints = (page) => {
    setLoading(true);
    request.post(
      '/complaint/getAll',
      { page: page },
      ({ data, length }) => {
        setComplaints(data);
        setComplaintsLength(length);
        setLoading(false);
      },
      error,
    );
  };

  const showClientInfo = (client) => {
    dispatch(setModal({ name: CLIENT_INFO, data: client }));
  };

  const onChangePagination = (event) => {
    getComplaints(event);
  };

  const formatedExportData =
    exportData && exportData.map((item) => [dayjs(item.date).format('DD.MM.YYYY'), item.client?.[0]?.n, users[item.curator]?.name, item.text]);

  formatedExportData?.unshift(['Дата', 'Клієнт', 'Куратор', 'Скарга']);

  const loadExport = () => {
    dispatch(setMainLoader(true));

    request.post('/complaint/export', { exportRange }, ({ data }) => {
      setExportData(data);
      dispatch(setMainLoader(false));
    });
  };

  return (
    <section className="complaints_page default_page">
      <AntdRow justify={'center'} align={'middle'}>
        <h2>Скарги</h2>
      </AntdRow>
      <br />

      {admin && (
        <Row style={{ justifyContent: 'center' }}>
          {exportData ? (
            <CSVLink asyncOnClick={true} data={exportData && formatedExportData}>
              <ExcelBtn style={{ width: 'auto' }}>Скачати файл</ExcelBtn>
            </CSVLink>
          ) : (
            <>
              <DatePicker.RangePicker style={{ marginRight: 16 }} onChange={(e) => setExportRange(e)} format="DD.MM.YYYY" />
              <ExcelBtn style={{ width: 'auto' }} onClick={loadExport}>
                Export
              </ExcelBtn>
            </>
          )}
        </Row>
      )}
      <br />
      {admin && (
        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 16 }}>
          <Button
            style={{ display: 'flex', justifyContent: 'center' }}
            onClick={() => dispatch(setModal({ name: EXPORT_SUGGESTION_AND_COMPLAINTS }))}
          >
            <img src={Excel} alt="Excel" width={24} />
            <Text style={{ marginLeft: 8 }}>Експорт скарг + пропозицій</Text>
          </Button>
        </div>
      )}

      <div>
        <Spin spinning={loading}>
          <Row gutter={[16, 16]}>
            {complaints.map((complaint) => (
              <Col key={complaint._id} xs={{ span: 12 }} lg={{ span: 8 }} xl={{ span: 6 }}>
                <Card className="complaint">
                  <Typography>
                    <b>Дата:</b> {dayjs(complaint.date).fromNow()}
                  </Typography>
                  {complaint.client[0] && (
                    <Typography>
                      <b>Клієнт:</b>
                      <Button type="link" onClick={() => showClientInfo(complaint?.client[0])}>
                        {complaint?.client[0]?.n}
                      </Button>
                    </Typography>
                  )}
                  <Popover content={<HoverableUser id={complaint.curator} />} arrow placement="bottomLeft" trigger="hover">
                    <Typography>
                      <b>Куратор:</b> {users[complaint.curator].name}
                    </Typography>
                  </Popover>
                  <Typography>
                    <b>Скарга:</b> {complaint.text}
                  </Typography>
                </Card>
              </Col>
            ))}
          </Row>
          {complaintsLength !== 0 && (
            <Pagination
              className="complaints_page__pagination"
              onChange={onChangePagination}
              defaultPageSize={COMPLAINTS_PER_PAGE}
              defaultCurrent={1}
              total={complaintsLength}
            />
          )}
        </Spin>
      </div>
    </section>
  );
};

export default Complaints;
