import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ADD_HOTLINE_CALLS_REPORT, CLIENT_INFO } from 'common_constants/modals';
import { Card, Button, Input } from 'antd';
import dayjs from 'dayjs';

import { setModal, updateHotlineCall } from '../../store/commonReducer';
import { getClientColor, request, error } from '../../tools';
import { formatSecondsTime } from './helpers';
import { useHistory } from 'react-router-dom';

const HotlineCallInfo = ({ data, setLoading }) => {
  const dispatch = useDispatch();
  const filii = useSelector((state) => state.common.filii);
  const history = useHistory();

  if (!data) {
    return 'Відсутня інформація';
  }

  const { color, background } = getClientColor(data?.client);
  const [currentFil] = filii.filter((item) => item.hotlinePhones?.includes(data.to)) ?? [];

  const handleClientBtnClick = () => {
    dispatch(setModal({ name: CLIENT_INFO, data: data?.client }));
  };

  const handleAddHotlineCallsReport = (item) => () => {
    dispatch(setModal({ name: ADD_HOTLINE_CALLS_REPORT, data: { ...item } }));
  };

  return (
    <Card className="hotline-call-info">
      <div>
        <b>Дата:</b> {dayjs(data.start_time).format('DD.MM.YYYY')}
      </div>
      <div>
        <b>Час:</b> {dayjs(data.start_time).format('HH:mm:ss')}
      </div>
      <div>
        <b>З номеру:</b> <a href={`tel:${data.from}`}>{data.from}</a>
      </div>
      <div className="client-name-wrapper">
        <b>Ім'я клієнта:</b>
        {data?.client?.n ? (
          <Button className="client-btn" size="small" shape="round" style={{ color, background }} onClick={handleClientBtnClick}>
            {data?.client.n}
          </Button>
        ) : (
          <b>Невідомо</b>
        )}
      </div>
      <div>
        <b>Філія, на яку дзвонили:</b> {currentFil?.name ?? '-'}
      </div>
      <div>
        <b>Куди дзвонив(-ла):</b> {data?.to}
      </div>
      <div>
        <b>Тривалість:</b> {formatSecondsTime(data?.answer_seconds)}
      </div>
      <div>
        <b>Час очікування:</b> {formatSecondsTime(data?.waiting_seconds)}
      </div>
      <div>
        <b>Загальна тривалість дзвінка:</b> {formatSecondsTime(data?.total_seconds)}
      </div>
      <Button type="primary" style={{ marginTop: 15 }} onClick={handleAddHotlineCallsReport(data)}>
        Звітувати
      </Button>
      <Button type="primary" style={{ marginTop: 15, marginLeft: 15 }} onClick={() => history.push('/hotline', { state: { data } })}>
        Додати запис
      </Button>
    </Card>
  );
};

export default HotlineCallInfo;
