import React, { useEffect, useState } from 'react';
import {
  TableOutlined,
  MenuOutlined,
  DownOutlined,
  PlusCircleOutlined,
  RightOutlined,
  SearchOutlined,
  UploadOutlined,
  CheckCircleOutlined,
} from '@ant-design/icons';
import {
  Popover,
  Button,
  DatePicker,
  Card,
  Form,
  Input,
  InputNumber,
  Pagination,
  Spin,
  Modal,
  Typography,
  Table,
  Divider,
  Empty,
  Alert,
  Tabs,
  Row as AntdRow,
} from 'antd';
import { ExcelBtn, Row, Box } from 'common_components';
import { ADD_SUGAR, CHECK_SUGAR } from 'common_constants/modals';
import { useDispatch, useSelector } from 'react-redux';
import { setSugar, setModal, importSugar } from '../../store/commonReducer';
import { error, request, success } from '../../tools';
import SugarInfo from './SugarInfo';
import { setMainLoader } from '../../store/uiReducer';
import { CURRENCIES, PAY_PURPOSES, PAY_TYPES, ROLES } from 'common_constants/business';
import { PAGE_SIZE_OPTIONS } from 'common_constants/db';
import { CSVLink } from 'react-csv';
import dayjs from 'dayjs';
import SearchSelectFil from '../../components/SearchSelect/SearchSelectFil';
import CSVUploader from './CSVUploader';
import SearchSelectPayTypeFilter from '../../components/SearchSelect/SearchSelectPayTypeFilter';
import SearchSelectAuthor from '../../components/SearchSelect/SearchSelectAuthor';
import SearchSelectPayPurp from '../../components/SearchSelect/SearchSelectPayPurp';
import TabPane from 'antd/es/tabs/TabPane';
import HoverableUser from '../../components/HoverableUser/HoverableUser';
import PageInfo from '../../components/PageInfo/PageInfo';

import './Sugar.scss';

const Sugar = () => {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.common.users);
  const sugar = useSelector((state) => state.common.sugar || []);
  const FILII = useSelector((state) => state.common.FILII);
  const mob = useSelector((state) => state.ui.mob);

  const userAuth = useSelector((state) => state.common.userAuth);
  const inspector = ROLES[userAuth.role] <= ROLES.inspector;
  const admin = ROLES[userAuth.role] === ROLES.admin;

  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(PAGE_SIZE_OPTIONS[0]);
  const [loading, setLoading] = useState(false);
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [currentFilters, setCurrentFilters] = useState({});
  const [exportData, setExportData] = useState(null);
  const [exportRange, setExportRange] = useState(null);
  const [isImportModalVisible, setImportModalVisible] = useState(false);
  const [paymentFiltersOpen, setPaymentFiltersOpen] = useState(false);
  const [activeTab, setActiveTab] = useState('mySugar');
  const [displayMode, setDisplayMode] = useState('list');

  const { Item } = Form;
  const { Text } = Typography;
  const { RangePicker } = DatePicker;

  const getSugarData = (limits, type) => {
    setLoading(true);
    const params = { ...limits, ...currentFilters };

    request.post(
      type === 'mySugar' ? '/sugar/getMySugar' : '/sugar/getSugar',
      params,
      ({ sugar, total }) => {
        dispatch(setSugar(sugar));
        setTotal(total);
        setLoading(false);
      },
      error,
    );
  };

  const handleTabChange = (key) => {
    setActiveTab(key);
    setCurrentPage(1);
  };

  const handlePageSizeChange = (current, size) => {
    setPageSize(size);
    setCurrentPage(1);
    getSugarData({ page: 1, pageSize: size, ...currentFilters }, activeTab);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    getSugarData({ page, pageSize, ...currentFilters }, activeTab);
  };

  useEffect(() => {
    const limits = { page: currentPage, pageSize, ...currentFilters };
    getSugarData(limits, activeTab);
  }, [currentPage, currentFilters, activeTab, pageSize]);

  const handleFilterChange = (values) => {
    setCurrentFilters(values);
    setCurrentPage(1);
  };

  const loadExport = () => {
    dispatch(setMainLoader(true));

    request.post('/sugar/export', { exportRange }, ({ data }) => {
      setExportData(data);
      dispatch(setMainLoader(false));
    });
  };

  const handleTableChange = (pagination) => {
    setCurrentPage(pagination.current);
  };

  const columns = mob
    ? [
        Table.EXPAND_COLUMN,
        {
          title: '№',
          dataIndex: 'i',
          key: 'i',
          defaultSortOrder: 'descend',
          sorter: {
            compare: (a, b) => a.i - b.i,
          },
        },
        {
          title: 'Дата',
          dataIndex: 'payday',
          key: 'payday',
          render: (payday) => dayjs(payday).format('DD.MM.YYYY'),
        },
        {
          title: 'Сума',
          dataIndex: 'amount',
          key: 'amount',
          render: (amount, record) => `${amount}${record.currency ? CURRENCIES[record.currency].symbol || '₴' : '₴'}`,
        },
        {
          title: 'Автор',
          dataIndex: 'author',
          key: 'author',
          render: (author) => (
            <Popover content={<HoverableUser id={users[author]?._id ?? '-'} />} arrow trigger="hover">
              {users[author]?.p ?? '-'}
            </Popover>
          ),
        },
      ]
    : [
        Table.EXPAND_COLUMN,
        {
          title: '№',
          dataIndex: 'i',
          key: 'i',
          defaultSortOrder: 'descend',
          sorter: {
            compare: (a, b) => a.i - b.i,
          },
        },
        {
          title: 'Філія',
          dataIndex: 'fil',
          key: 'fil',
          render: (fil) => FILII[fil],
        },
        {
          title: 'Дата',
          dataIndex: 'payday',
          key: 'payday',
          render: (payday) => dayjs(payday).format('DD.MM.YYYY'),
        },
        {
          title: 'Сума',
          dataIndex: 'amount',
          key: 'amount',
          render: (amount, record) => `${amount}${record.currency ? CURRENCIES[record.currency].symbol || '₴' : '₴'}`,
        },
        {
          title: 'Автор',
          dataIndex: 'author',
          key: 'author',
          render: (author) => (
            <Popover content={<HoverableUser id={users[author]?._id ?? '-'} />} arrow trigger="hover">
              {users[author]?.p ?? '-'}
            </Popover>
          ),
        },
      ];

  const formatedExportData =
    exportData &&
    exportData
      .sort((a, b) => a.i - b.i)
      .map((item) => [
        item.i,
        FILII[item.contract_info?.[0]?.f] || FILII[item.payrollToFil] || (item.payfor ? FILII[item.fil] : FILII[3]),
        dayjs(item.payday).format('DD.MM.YYYY'),
        item.amount,
        item?.payforRest,
        users[item.author]?.p,
        item?.contractIndex,
        item?.name,
        item.createdAt && dayjs(item.createdAt).format('DD.MM.YYYY HH:mm'),
        PAY_PURPOSES[item.payfor]?.label + ' ' + (item?.payforOther || ''),
        PAY_TYPES[item.payBy]?.label + ' ' + (item?.payByOther || ''),
        FILII[item.fil],
      ]);

  formatedExportData?.unshift([
    '№ інформації',
    'філія договору',
    'Дата платежа',
    'Сума платежа',
    'Сума залишку',
    'хто вніс',
    '№договора',
    'Прізвище клієнта',
    'дата внесення',
    'призначення платежу',
    'Куди платив',
    'філія внесача',
  ]);

  const handleCSVUpload = async (file) => {
    const formData = new FormData();
    formData.append('file', file);

    request.post(
      '/sugar/import',
      formData,
      ({ sugar }) => {
        success('', `Транзакції були успішно імпортовані.`);
        dispatch(importSugar(sugar));
        setImportModalVisible(false);
        setLoading(false);
      },
      () => {
        error('', `Помилка при імпортуванні.`);
      },
    );
  };

  const handleKeyPress = (e) => {
    const key = e.key;
    if (['.', ','].includes(key)) {
      e.preventDefault();
    }
  };

  if (!users) return null;

  return (
    <div className="sugar_page default_page">
      <AntdRow justify={'center'} align={'middle'}>
        <h2>Сахорок</h2>
        <PageInfo page="sugar" />
      </AntdRow>
      <Tabs className="tabs" activeKey={activeTab} onChange={handleTabChange}>
        <TabPane tab="Мої записи" key="mySugar"></TabPane>
        <TabPane tab="Всі" key="allSugar"></TabPane>
      </Tabs>

      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button style={{ marginRight: 6 }} onClick={() => setDisplayMode('list')} disabled={displayMode === 'list'}>
          <MenuOutlined />
        </Button>
        <Button onClick={() => setDisplayMode('table')} disabled={displayMode === 'table'}>
          <TableOutlined />
        </Button>
      </div>

      <br />

      <Alert
        style={{ marginBottom: 20 }}
        description={
          <>
            <b>{userAuth.cashPercent || 0}% - відсоток від оплати готівкою, картою передаст та ФОП</b>
            {/* <br /> */}
            {/* <b>{userAuth.cardPercent || 0}% - відсоток від оплати карткою</b> */}
            <br />
            <b>{userAuth.fopPercent || 0}% - відсоток від оплати картою ГО, БН</b>
          </>
        }
      ></Alert>
      {(!userAuth.cashPercent || !userAuth.fopPercent) && (
        <Alert
          message="Відсутній відсоток від оплат. Зверніться до адміністратора. Доля від оплати не буде розподілена."
          type="error"
          style={{ fontSize: 25, marginBottom: 20 }}
          showIcon
        />
      )}
      <Row style={{ justifyContent: 'center' }}>
        <Button type="primary" onClick={() => dispatch(setModal({ name: ADD_SUGAR }))}>
          <PlusCircleOutlined /> Додати
        </Button>

        {admin && isImportModalVisible ? (
          <Modal title="Імпортувати .csv" open={isImportModalVisible} onCancel={() => setImportModalVisible(false)} footer={null}>
            <CSVUploader onUpload={handleCSVUpload} />
          </Modal>
        ) : (
          admin && (
            <Button style={{ marginLeft: '10px' }} type="primary" onClick={() => setImportModalVisible(true)}>
              <UploadOutlined /> Імпорт
            </Button>
          )
        )}
      </Row>
      <br />

      {admin && (
        <Row style={{ justifyContent: 'center' }}>
          {exportData ? (
            <CSVLink asyncOnClick={true} data={exportData && formatedExportData}>
              <ExcelBtn style={{ width: 'auto' }}>Скачати файл</ExcelBtn>
            </CSVLink>
          ) : (
            <>
              <DatePicker.RangePicker style={{ marginRight: 16 }} onChange={(e) => setExportRange(e)} format="DD.MM.YYYY" />
              <ExcelBtn style={{ width: 'auto' }} onClick={loadExport}>
                Export
              </ExcelBtn>
            </>
          )}
        </Row>
      )}

      {inspector && (
        <>
          <br />
          <Row style={{ justifyContent: 'center' }}>
            <Button type="primary" onClick={() => dispatch(setModal({ name: CHECK_SUGAR }))}>
              <CheckCircleOutlined /> Звірка суми за період
            </Button>
          </Row>
        </>
      )}

      <br />

      <div className="row align filters" onClick={() => setFiltersOpen(!filtersOpen)}>
        {filtersOpen ? <DownOutlined /> : <RightOutlined />} <h2>Пошук</h2>
      </div>

      {filtersOpen && (
        <Card className="filtersContainer">
          <Form
            onFinish={handleFilterChange}
            layout="inline"
            initialValues={{ fil: inspector ? '' : userAuth.fil, author: inspector ? '' : userAuth._id }}
          >
            <div style={{ width: '320px' }}>
              <Divider children={'Фільтри:'} style={{ margin: 0 }} orientation="left" />
              <Box>
                <Text>Філія: </Text> &nbsp;
                <Item name="fil" children={<SearchSelectFil />} />
              </Box>

              <div style={{ display: 'flex' }}>
                <Box>
                  <Text>№ транзакції: </Text> &nbsp;
                  <Item name="i" children={<InputNumber />} />
                </Box>

                <Box>
                  <Text>№ договору: </Text> &nbsp;
                  <Item name="contractIndex" children={<InputNumber />} />
                </Box>
              </div>

              <Box>
                <Text>ПІБ: </Text> &nbsp;
                <Item name="name" children={<Input />} />
              </Box>

              <Box>
                <Text>Призначення платежу: </Text> &nbsp;
                <Item name="payfor" children={<SearchSelectPayPurp />} />
              </Box>

              <Box>
                <Text>Період: </Text> &nbsp;
                <Item name="dateRange" children={<RangePicker format="DD.MM.YYYY" />} />
              </Box>

              <Box>
                <Text>Автор: </Text> &nbsp;
                <Item name="author" children={<SearchSelectAuthor disabled={!inspector} />} />
              </Box>

              <Box>
                <Button style={{ width: 150 }} type="primary" htmlType="submit">
                  Пошук <SearchOutlined />
                </Button>
              </Box>
            </div>
          </Form>
        </Card>
      )}

      <br />

      <div className="row align filters" onClick={() => setPaymentFiltersOpen(!paymentFiltersOpen)}>
        {paymentFiltersOpen ? <DownOutlined /> : <RightOutlined />} <h2>Звірити платіж</h2>
      </div>

      {paymentFiltersOpen && (
        <Card className="filtersContainer">
          <Form onFinish={handleFilterChange} layout="inline" initialValues={{ fil: inspector ? '' : userAuth.fil }}>
            <div style={{ width: '320px' }}>
              <Divider children={'Фільтри:'} style={{ margin: 0 }} orientation="left" />
              <Box>
                <Text>Карта: </Text> &nbsp;
                <Item name="payBy" children={<SearchSelectPayTypeFilter />} />
              </Box>

              <Box>
                <Text>Сума: </Text> &nbsp;
                <Item name="amount" children={<InputNumber onKeyDown={handleKeyPress} />} />
              </Box>

              <Box>
                <Text>Дата: </Text> &nbsp;
                <Item name="singleDate" children={<DatePicker format="DD.MM.YYYY" />} />
              </Box>

              <Box>
                <Button style={{ width: 150 }} type="primary" htmlType="submit">
                  Пошук <SearchOutlined />
                </Button>
              </Box>
            </div>
          </Form>
        </Card>
      )}

      <br />

      <Spin spinning={loading} tip="Зачекайте" size="large">
        {displayMode === 'list' ? (
          <>
            {sugar?.length ? sugar?.map((item) => <SugarInfo key={item._id} data={item} />) : <Empty description={<Text>Немає транзакцій</Text>} />}
            <Pagination
              style={{ textAlign: 'center' }}
              current={currentPage}
              pageSize={pageSize}
              total={total}
              onChange={handlePageChange}
              showSizeChanger
              onShowSizeChange={handlePageSizeChange}
              pageSizeOptions={PAGE_SIZE_OPTIONS.map((size) => size.toString())}
            />
          </>
        ) : (
          <Table
            dataSource={sugar}
            columns={columns}
            rowKey={(item) => item._id}
            loading={loading}
            // expandable={{
            //   expandRowByClick: true,
            //   expandedRowRender: (item) => <SugarInfo key={item._id} data={item} />,
            // }}
            pagination={{ total, current: currentPage }}
            onChange={handleTableChange}
          />
        )}
      </Spin>
    </div>
  );
};

export default Sugar;
