import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Spin, message } from 'antd';

import { setModal } from '../../store/commonReducer';
import { error, request } from '../../tools';
import {
  BlockRating,
  ContractNotFound,
  PersonalVotingResult,
  ReceivingData,
  VotingResult,
  Voting,
  VotingCompletion,
  ArchiveCandy,
} from './VotingSteps';
import { VOTING_STEPS, VOTE_ROUTES, MY_BRANCH } from './VotingConstants';
import TrafficLight from '../../pages/Contracts/ContractInfo/TrafficLight';

const VotingForArchiving = () => {
  const dispatch = useDispatch();
  const { contract, correspondence } = useSelector((state) => state.common.modal.data);
  const { _id: userId, block: userBlock } = useSelector((state) => state.common.userAuth);
  const [messageApi, contextHolder] = message.useMessage();

  const [loading, setLoading] = useState(true);
  const [currentVotingStep, setCurrentVotingStep] = useState(VOTING_STEPS.RECEIVING_DATA);
  const [latestVote, setLatestVote] = useState(null);
  const [allVotes, setAllVotes] = useState([]);
  const [vote, setVote] = useState(null);
  const [rating, setRating] = useState(null);
  const [archiveCandy, setArchiveCandy] = useState({
    candyGiven: false,
    candyNotGivenReason: '',
  });

  const notMyBranch = contract?.block !== MY_BRANCH;
  const notMyBlock = contract?.block !== userBlock;
  const isCurator = userId === contract.us;

  const info = () => {
    messageApi.info('Будь ласка, оцініть діяльність блоку!');
  };

  const onCancel = () => {
    dispatch(setModal());
  };

  const sendVote = useCallback(() => {
    if (vote && notMyBlock && notMyBranch && contract?.block && !rating) {
      info();
      return;
    }

    const voteData = { contractId: contract._id, vote };

    if (vote) {
      voteData.rating = rating;
    }

    if (vote && isCurator) {
      voteData.candyGiven = archiveCandy.candyGiven;
      voteData.candyNotGivenReason = archiveCandy.candyNotGivenReason;
    }

    const route = latestVote ? VOTE_ROUTES.UPDATE : VOTE_ROUTES.CREATE;

    setLoading(true);

    const onSuccess = () => {
      const voteToPaste = {
        _id: Date.now(),
        votedUserId: userId,
        vote,
        updatedAt: new Date(),
      };

      if (vote) {
        voteToPaste.rating = rating;
      }

      if (!allVotes?.length) {
        setAllVotes([voteToPaste]);
      }

      const temporaryVotes = allVotes?.length ? allVotes : [];

      const isPresentVote = temporaryVotes.findIndex((vote) => vote.votedUserId === userId);

      if (temporaryVotes?.length && isPresentVote >= 0) {
        const updatedAllVotes = temporaryVotes.map((voteItem) =>
          voteItem.votedUserId === userId ? { ...voteItem, vote, rating, updatedAt: new Date() } : voteItem,
        );
        setAllVotes(updatedAllVotes);
      } else {
        setAllVotes([...temporaryVotes, voteToPaste]);
      }

      setCurrentVotingStep(VOTING_STEPS.VOTING_COMPLETION);
      setLoading(false);
    };

    const onError = () => {
      error('Помилка серверу, спробуйте пізніше!', ' ');
      setLoading(false);
    };

    request.post(route, { voteData }, onSuccess, onError);
  }, [vote, contract?.block, rating, latestVote, archiveCandy]);

  useEffect(() => {
    const onSuccess = ({ votedUser, archiveVotes, candyGiven = false, candyNotGivenReason = '' }) => {
      setLoading(false);
      setLatestVote(votedUser);
      setAllVotes(archiveVotes);
      setCurrentVotingStep(votedUser ? VOTING_STEPS.PERSONAL_VOTING_RESULT : VOTING_STEPS.VOTING);
      setArchiveCandy({
        candyGiven: candyGiven,
        candyNotGivenReason: candyNotGivenReason,
      });
    };

    const onError = () => {
      error('Запис не знайдено!', ' ');
      setLoading(false);
      setCurrentVotingStep(VOTING_STEPS.CONTRACT_NOT_FOUND);
    };

    const route = VOTE_ROUTES.GET;

    request.post(route, { contractId: contract._id }, onSuccess, onError);
  }, []);

  useEffect(() => {
    if (vote === null) return;

    if (vote && currentVotingStep === VOTING_STEPS.VOTING && isCurator) {
      setCurrentVotingStep(VOTING_STEPS.ARCHIVE_CANDY);
      return;
    }

    if (
      notMyBlock &&
      notMyBranch &&
      contract?.block &&
      vote &&
      (archiveCandy.candyGiven || archiveCandy.candyNotGivenReason?.length > 5 || !isCurator)
    ) {
      setCurrentVotingStep(VOTING_STEPS.BLOCK_RATING);
      return;
    }

    sendVote();
  }, [vote, archiveCandy]);

  return (
    <Modal open title={'Голосування за архівацію'} onCancel={onCancel} footer={null} className="votingForArchiving">
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 32 }}>
        <TrafficLight data={contract} correspondence={correspondence} />
      </div>
      {contextHolder}
      <Spin tip="Завантаження" spinning={loading}>
        {currentVotingStep === VOTING_STEPS.RECEIVING_DATA && <ReceivingData />}
        {currentVotingStep === VOTING_STEPS.VOTING && <Voting setVote={setVote} />}
        {currentVotingStep === VOTING_STEPS.ARCHIVE_CANDY && <ArchiveCandy archiveCandy={archiveCandy} setArchiveCandy={setArchiveCandy} />}
        {currentVotingStep === VOTING_STEPS.BLOCK_RATING && <BlockRating rating={rating} setRating={setRating} sendVote={sendVote} />}
        {currentVotingStep === VOTING_STEPS.VOTING_COMPLETION && <VotingCompletion onCancel={onCancel} />}
        {currentVotingStep === VOTING_STEPS.PERSONAL_VOTING_RESULT && (
          <PersonalVotingResult
            latestVote={latestVote}
            setCurrentVotingStep={setCurrentVotingStep}
            block={contract?.block}
            notMyBranch={notMyBranch}
            notMyBlock={notMyBlock}
          />
        )}
        {currentVotingStep === VOTING_STEPS.CONTRACT_NOT_FOUND && <ContractNotFound onCancel={onCancel} />}

        <VotingResult allVotes={allVotes} contract={contract} notMyBranch={notMyBranch} archiveCandy={archiveCandy} />
      </Spin>
    </Modal>
  );
};

export default VotingForArchiving;
