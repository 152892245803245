import { CheckOutlined, CloseOutlined, DiffOutlined, DollarOutlined, ExclamationCircleOutlined, PhoneOutlined } from '@ant-design/icons';
import { Button, Calendar, DatePicker, Empty, Modal, Select, Spin, Switch, Typography } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PageInfo from '../../components/PageInfo';

import {
  HOTLINE_ADV_WHERE,
  HOTLINE_ADV_WHERE_MORE,
  HOTLINE_RESULT,
  HOTLINE_STATUS,
  POSLUGI,
  PRAVO,
  ROLES,
  WORK_TIME,
} from 'common_constants/business';
import { setCurrentHotlineList } from '../../store/commonReducer';
import { error, request, warn } from '../../tools';
import Item from './Item';

import Excel from '../../images/excel_logo.svg';
import './List.scss';

const { Text } = Typography;

const List = () => {
  const now = dayjs();
  const dispatch = useDispatch();
  const history = useHistory();

  const list = useSelector((state) => state.common.currentHotlineList);
  const users = useSelector((state) => state.common.users);
  const FILII = useSelector((state) => state.common.FILII);
  const { fil: userFil, role: userRole } = useSelector((state) => state.common.userAuth);

  const admin = ROLES[userRole] === ROLES.admin;
  const inspector = ROLES[userRole] <= ROLES.inspector;

  const [date, setDate] = useState(now);
  const [toDate, setToDate] = useState();
  const [fil, setFil] = useState(userFil ?? '_');
  const [loading, setLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(true);
  const [showCanceled, setShowCanceled] = useState(true);
  const [showUnreacted, setShowUnreacted] = useState(true);
  const [showVibers, setShowVibers] = useState(true);
  const [showService1, setShowService1] = useState(true);
  const [showService2, setShowService2] = useState(true);
  const [showService3, setShowService3] = useState(true);
  const [showService5, setShowService5] = useState(true);
  const [showService6, setShowService6] = useState(true);
  const [noMoney, setNoMoney] = useState(false);
  const [uklOpl, setUklOpl] = useState(false);
  const [uklNeOpl, setUklNeOpl] = useState(false);
  const [neUkl, setNeUkl] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);

  const updateRecordInList = (updatedRecord) => {
    const updatedList = list.map((item) => (item.phone === updatedRecord.phone ? updatedRecord : item));

    dispatch(setCurrentHotlineList(updatedList));
  };
  const getList = async (date, toDate) => {
    const validation = (date) => {
      if (!date) {
        warn('', 'Для списку консультацій потрібно обрати дату', { date: date });
        return false; // Change to false to stop execution
      }
      if (toDate && !toDate.$D) {
        warn('', 'Для списку консультацій потрібно обрати період', { toDate: toDate, 'toDate.$D': toDate.$D });
        return false; // Change to false to stop execution
      }
      return true;
    };

    if (!validation(date, toDate)) return;

    const dateString = date?.toISOString();
    const toDateString = toDate?.toISOString();

    setLoading(true);

    const transaction = {
      date: dateString,
      toDate: toDateString,
    };

    await request.post(
      '/hotline/getAll',
      transaction,
      (req) => {
        dispatch(setCurrentHotlineList(req.result));
      },
      (error) => {
        console.error('Error in request:', error);
      },
    );

    setLoading(false);
  };

  const removeConfirm = (data) => {
    const now = dayjs();
    const _time = dayjs(data.date)
      .set('hour', parseInt(data.startT.split(':')[0]))
      .set('minute', parseInt(data.startT.split(':')[1]));

    if (now.isAfter(_time.subtract(2, 'hour'))) {
      Modal.confirm({
        title: 'Видалити неможливо у звязку з часовими обмеженнями',
        icon: <ExclamationCircleOutlined />,
        okText: 'Ok',
        cancelButtonProps: { style: { display: 'none' } },
      });
      return;
    }

    Modal.confirm({
      title: 'Ви впевнені у видаленні запису?',
      icon: <ExclamationCircleOutlined />,
      // content: 'Some descriptions',
      okText: 'Так',
      okType: 'danger',
      cancelText: 'Ні',
      onOk() {
        console.log('');
        onRemoveClick(data);
      },
      onCancel() {
        console.log('');
      },
    });
  };

  const onDateChange = (value) => {
    setDate(value);
    getList(value);
  };

  const onChangeRange = (value) => {
    if (!value) return;
    setToDate(value[1]);
    getList(value[0], value[1]);
  };

  const onChangeRangeDate = (value) => {
    if (!value) {
      setToDate();
      getList(date);
    }
  };

  const onEditClick = (data) => {
    history.push({
      pathname: '/hotline/' + data._id,
      state: { data: data },
    });
  };

  const onRemoveClick = (data) => {
    const validation = (data) => {
      if (!data._id) {
        warn('', 'Для видалення запису потрібно вказати чинного клієнта', { 'data.id': data.id });
        return;
      }
      return true;
    };

    if (!validation(data)) return;

    setLoading(true);

    const transaction = {
      _id: data._id,
    };

    request.post(
      '/hotline/remove',
      transaction,
      (req) => {
        req.status && getList(date);
        setLoading(false);
      },
      error,
    );
  };

  useEffect(() => {
    getList(date);
  }, []);

  const csvData = [
    [
      'Філія',
      'Послуга',
      'Дата',
      "Ім'я",
      'Час',
      'Телефон',
      'Вид',
      'Сума',
      'Коментар',
      'Автор',
      'Створено',
      'Завершеність',
      'Категорія анкетування',
      'Що саме',
      'Призначено',
      'Анкетування',
      'Названа ціна',
      'Результат зустрічі',
    ],
  ];
  const counterObj = {
    konsultation: 0,
    konsultationExist: 0,
    meets: 0,
    signContract: 0,
    signDocs: 0,
    s0: 0,
    s1: 0,
    s2: 0,
    s3: 0,
    noMoney: 0,
    ukl_opl: 0,
    ukl_neopl: 0,
    neukl: 0,
    empty: 0,
    ic: 0,
  };

  const listToRender =
    list
      ?.filter((i) => (fil && fil !== '_' ? i.fil === fil : true))
      .filter((i) => (selectedUsers.length === 0 ? true : selectedUsers.includes(i.userId)))
      .sort((a, b) => WORK_TIME.indexOf(a.startT) - WORK_TIME.indexOf(b.startT))
      .map((i) => {
        if (i.disabled || i.e) return { ...i }; // don't calc edited & disabled

        const k = i.type === '0' || i.type === '3' || i.type === '8';
        const was = i.s === '1';
        if (k) counterObj.konsultation++;
        if (k && was) counterObj.konsultationExist++;
        if (i.type === '1') counterObj.meets++;
        if (i.type === '2') counterObj.signContract++;
        if (i.type === '4') counterObj.signDocs++;
        if (i.type === '6') counterObj.ic++;
        if (i.s === '0' || !i.s) counterObj.s0++;
        if (i.s === '1') counterObj.s1++;
        if (i.s === '2') counterObj.s2++;
        if (i.s === '3') counterObj.s3++;

        if (i.disabled || !was || !k) return { ...i };

        if (i.amr === '0' || i.amr === '1') counterObj.noMoney++;
        if (i.amr === '0') counterObj.neukl++;
        if (i.amr === '1') counterObj.ukl_neopl++;
        if (i.amr === '2') counterObj.ukl_opl++;
        if (!i.amr) counterObj.empty++;

        return { ...i };
      }) ?? [];

  const MainFilters = (
    <div className="filter">
      <div className="row">
        <div className="filter-switch">
          {counterObj.s1} <CheckOutlined />
          <Switch
            defaultChecked
            onChange={() => {
              setShowSuccess(!showSuccess);
            }}
          />
        </div>
        <div className="filter-switch">
          {POSLUGI[0]} {counterObj.konsultation}
          <Switch
            defaultChecked
            onChange={() => {
              setShowService1(!showService1);
            }}
          />
        </div>
      </div>
      <div className="row">
        <div className="filter-switch">
          {counterObj.s2} <CloseOutlined />
          <Switch
            defaultChecked
            onChange={() => {
              setShowCanceled(!showCanceled);
            }}
          />
        </div>
        <div className="filter-switch">
          {POSLUGI[1]} {counterObj.meets}
          <Switch
            defaultChecked
            onChange={() => {
              setShowService2(!showService2);
            }}
          />
        </div>
      </div>
      <div className="row">
        <div className="filter-switch">
          {counterObj.s3} <PhoneOutlined />
          <Switch
            defaultChecked
            onChange={() => {
              setShowVibers(!showVibers);
            }}
          />
        </div>
        <div className="filter-switch">
          {POSLUGI[2]} {counterObj.signContract}
          <Switch
            defaultChecked
            onChange={() => {
              setShowService3(!showService3);
            }}
          />
        </div>
      </div>
      <div className="row">
        <div className="filter-switch">
          {counterObj.s0} <DiffOutlined />
          <Switch
            defaultChecked
            onChange={() => {
              setShowUnreacted(!showUnreacted);
            }}
          />
        </div>
        <div className="filter-switch">
          {POSLUGI[4]} {counterObj.signDocs}
          <Switch
            defaultChecked
            onChange={() => {
              setShowService5(!showService5);
            }}
          />
        </div>
      </div>
      <div className="row">
        <div className="filter-switch"></div>
        <div className="filter-switch">
          {POSLUGI[6]} {counterObj.ic}
          <Switch
            defaultChecked
            onChange={() => {
              setShowService6(!showService6);
            }}
          />
        </div>
      </div>
    </div>
  );

  const SmallFiltersRow = (
    <div className="row align" style={{ marginBottom: '10px' }}>
      {inspector && (
        <CSVLink data={csvData}>
          <Button className="excel" icon={<img src={Excel} alt="Excel" />}>
            Export .csv
          </Button>
        </CSVLink>
      )}
      К&nbsp; {counterObj.konsultationExist} &nbsp;
      <span className={noMoney ? 'highlight' : ''} onClick={() => setNoMoney(!noMoney)}>
        <DollarOutlined className="red" /> &nbsp; {counterObj.neukl + counterObj.ukl_neopl} &nbsp;
      </span>
      <span className={neUkl ? 'highlight' : ''} onClick={() => setNeUkl(!neUkl)}>
        НУ&nbsp; {counterObj.neukl} &nbsp;
      </span>
      <span className={uklNeOpl ? 'highlight' : ''} onClick={() => setUklNeOpl(!uklNeOpl)}>
        УНO&nbsp; {counterObj.ukl_neopl} &nbsp;
      </span>
      <span className={uklOpl ? 'highlight' : ''} onClick={() => setUklOpl(!uklOpl)}>
        УO&nbsp; {counterObj.ukl_opl} &nbsp;
      </span>
    </div>
  );

  if (!users) return null;

  return (
    <Spin tip="Завантаження" spinning={loading}>
      <div className="list-page default-page">
        <h2>
          Існуючі записи <PageInfo />
        </h2>
        <Calendar fullscreen={false} onSelect={onDateChange} style={{ opacity: toDate && 0.2 }} />

        {admin && (
          <>
            <span style={{ display: 'inline-block', marginTop: '10px' }}>Обрати період:</span> <br />
            <DatePicker.RangePicker style={{ margin: '10px 10px 0 0' }} onChange={onChangeRange} onCalendarChange={onChangeRangeDate} />
          </>
        )}

        <Select
          className="fil"
          showSearch
          disabled={!inspector}
          defaultValue={fil}
          onChange={(value) => setFil(String(value))}
          filterOption={(input, option) => option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >
          <Select.Option value="_">Всі філії</Select.Option>
          {FILII.map((i, index) => (
            <Select.Option key={i} value={String(index)}>
              {i}
            </Select.Option>
          ))}
        </Select>

        {/* <SearchSelect
            className='fil'
            list={listToRender.map(i => users[i.userId])}
            value={selectedUsers}
            onChange={setSelectedUsers}
            placeholder="Працівники на сайті"
            valueName="id"
            labelName="name"
        /> */}

        {inspector && MainFilters}

        {SmallFiltersRow}

        {!listToRender.length && <Empty description={<Text>Нема записів</Text>} />}
        {listToRender.map((i, index) => {
          if (!showSuccess && i.s === '1') return null;
          if (!showCanceled && i.s === '2') return null;
          if (!showVibers && i.s === '3') return null;
          if (!showUnreacted && !i.s) return null;

          if (!showService1 && (i.type === '0' || i.type === '3' || i.type === '8')) return null;
          if (!showService2 && i.type === '1') return null;
          if (!showService3 && i.type === '2') return null;
          if (!showService5 && i.type === '4') return null;
          if (!showService6 && i.type === '6') return null;

          const wasKoons = i.s === '1' && (i.type === '0' || i.type === '3');
          if ((noMoney || neUkl || uklNeOpl || uklOpl) && !wasKoons) return null;
          if (noMoney && i.amr === '2') return null;
          if (neUkl && i.amr !== '0') return null;
          if (uklNeOpl && i.amr !== '1') return null;
          if (uklOpl && i.amr !== '2') return null;

          i.date = dayjs(i.date);
          i.crAt = dayjs(i.crAt);

          const itemComment = i.comment ?? '-';

          csvData.push([
            FILII[i.fil] ?? '-',
            POSLUGI[i.type] ?? '-',
            i.date?.format('DD.MM.YYYY') ?? '-',
            i.name ?? '-',
            (i.startT ?? '-') + '-' + (i.endT ?? '-'),
            i.phone ?? '-',
            PRAVO[i.pravo] ?? '-',
            i.cost ?? '-',
            i.isAcceptedHackwork ? 'X ' + itemComment : itemComment,
            (window.users && window.users[i.author]?.p) ?? '-',
            i.crAt?.format('DD.MM.YYYY HH:mm:ss') ?? '-',
            HOTLINE_STATUS[i.s ?? 0],
            HOTLINE_ADV_WHERE[i.aw ?? 0],
            HOTLINE_ADV_WHERE_MORE[i.am ?? 0],
            (window.users && window.users[i.amm]?.p) ?? users[i.amm]?.p ?? '-',
            'AUTO',
            i.amp ?? '-',
            HOTLINE_RESULT[i.amr] ?? '-',
          ]);

          return (
            <Item
              i={i}
              key={i._id}
              index={index}
              onEditClick={onEditClick}
              removeConfirm={removeConfirm}
              updateRecord={updateRecordInList}
              blocked={i.bl}
            />
          );
        })}
      </div>
    </Spin>
  );
};

export default List;
