import dayjs from 'dayjs';
import { request, error, success } from '../../../tools';

export const pageSize = 20;

export const filialInitialState = {
  totalBalance: 0,
  totalBalanceLoading: false,
  sugarLoading: false,
  sugarData: [],
  sugarPageSize: pageSize,
  sugarCurrentPage: 1,
  sugarTotalRecords: 0,
  sugarTotalAmount: 0,
  sugarSelectedDate: dayjs().startOf('month'),
  expensesLoading: false,
  expensesData: [],
  expensesPageSize: pageSize,
  expensesCurrentPage: 1,
  expensesTotalRecords: 0,
  expensesTotalAmount: 0,
  expensesSelectedDate: dayjs().startOf('month'),
};

export const personalInitState = {
  sugarLoading: false,
  sugarData: [],
  sugarPageSize: pageSize,
  sugarCurrentPage: 1,
  sugarTotalRecords: 0,
  sugarTotalAmount: 0,
  sugarSelectedDate: dayjs().startOf('month'),
  expensesLoading: false,
  expensesData: [],
  expensesPageSize: pageSize,
  expensesCurrentPage: 1,
  expensesTotalRecords: 0,
  expensesTotalAmount: 0,
  expensesSelectedDate: dayjs().startOf('month'),
};

export const fullBalanceInitialState = {
  sugarLoading: false,
  sugarData: [],
  sugarPageSize: pageSize,
  sugarCurrentPage: 1,
  sugarTotalRecords: 0,
  sugarTotalAmount: 0,
  sugarSelectedDate: dayjs().startOf('month'),
};

export const payrollsInitState = {
  data: [],
  loading: false,
  totalAmount: 0,
  pageSize: pageSize,
  currentPage: 1,
  totalRecords: 0,
  selectedDate: dayjs().startOf('month'),
};

export const handleUpdateTotalBalance = ({ setLoading, type, setBalance, filter }) => {
  setLoading(true);
  const routeType = type === 'filial' ? 'filii' : 'auth';
  const route = `/${routeType}/totalBalance/update`;
  const onSuccess = ({ totalBalance }) => {
    setBalance(totalBalance);
    setLoading(false);
    success('Баланс оновлено.');
  };
  const onError = (_, __, err) => {
    setLoading(false);
    error('', 'Не вдалось оновити баланс. Будь ласка, спробуйте пізніше.');
  };
  request.post(route, { ...filter }, onSuccess, onError);
};

export const handleSugarDateChange = (value, setState) => {
  const date = value ? value : dayjs().startOf('month');
  setState((prevState) => ({ ...prevState, sugarSelectedDate: date }));
};

export const onChangeSugarPage = (page, setState) => {
  setState((prevState) => ({ ...prevState, sugarCurrentPage: page }));
};

export const onChangeSugarPageSize = (currentPage, pageSize, setState) => {
  setState((prevState) => ({
    ...prevState,
    sugarPageSize: pageSize,
    sugarCurrentPage: currentPage,
  }));
};

export const onChangeExpensesPageSize = (currentPage, pageSize, setState) => {
  setState((prevState) => ({
    ...prevState,
    expensesPageSize: pageSize,
    expensesCurrentPage: currentPage,
  }));
};

export const onChangePayrollsPageSize = (currentPage, pageSize, setState) => {
  setState((prevState) => ({
    ...prevState,
    pageSize,
    currentPage,
  }));
};

export const fetchSugar = ({ setLoading, type, state, setState, filter }) => {
  setLoading(true);
  const route = `/sugar/${type}`;
  const body = {
    page: state.sugarCurrentPage,
    pageSize: state.sugarPageSize,
    date: state.sugarSelectedDate.toISOString(),
    ...filter,
  };
  const onSuccess = (res) => {
    setState((prevState) => ({
      ...prevState,
      sugarData: res.transactions,
      sugarTotalRecords: res.total,
      sugarTotalAmount: res.totalAmount,
      sugarLoading: false,
    }));
  };
  const onError = (_, __, err) => {
    setLoading(false);
    error('', 'Не вдалось отримати дані. Будь ласка, спробуйте пізніше.');
  };
  request.post(route, body, onSuccess, onError);
};
export const fetchSugarMonthly = ({ setLoading, type, filial, userId }) => {
  setLoading(true);
  const route = `/sugar/monthly/${type}`;
  const body = {
    filial: filial,
    userId: userId,
  };
  return new Promise((resolve, reject) => {
    const onSuccess = (res) => {
      setLoading(false);
      resolve(res.result);
    };

    const onError = (err) => {
      setLoading(false);
      error('', 'Не вдалось отримати дані. Будь ласка, спробуйте пізніше.');
      reject(err);
    };

    request.post(route, body, onSuccess, onError);
  });
};

export const fetchExpenses = ({ setLoading, type, state, setState, filter }) => {
  setLoading(true);
  const route = `/expense/${type}`;
  const body = {
    page: state.expensesCurrentPage,
    pageSize: state.expensesPageSize,
    date: state.expensesSelectedDate.toISOString(),
    ...filter,
  };
  const onSuccess = (res) => {
    setState((prevState) => ({
      ...prevState,
      expensesData: res.transactions,
      expensesTotalRecords: res.total,
      expensesTotalAmount: res.totalAmount,
      expensesLoading: false,
    }));
  };
  const onError = (_, __, err) => {
    setLoading(false);
    error('', 'Не вдалось отримати дані. Будь ласка, спробуйте пізніше.');
  };
  request.post(route, body, onSuccess, onError);
};

export const fetchExpensesMonthly = ({ setLoading, type, filial, userId }) => {
  setLoading(true);
  const route = `/expense/monthly/${type}`;
  const body = {
    filial: filial,
    userId: userId,
  };
  return new Promise((resolve, reject) => {
    const onSuccess = (res) => {
      setLoading(false);
      resolve(res.result);
    };

    const onError = (err) => {
      setLoading(false);
      error('', 'Не вдалось отримати дані. Будь ласка, спробуйте пізніше.');
      reject(err);
    };

    request.post(route, body, onSuccess, onError);
  });
};

export const handleExpensesDateChange = (value, setState) => {
  const date = value ? value : dayjs().startOf('month');
  setState((prevState) => ({ ...prevState, expensesSelectedDate: date }));
};

export const onChangeExpensesPage = (page, setState) => {
  setState((prevState) => ({ ...prevState, expensesCurrentPage: page }));
};

export const updateTotalPayrolsAmount = ({ setLoading, setAmount, userId }) => {
  setLoading(true);
  const route = '/payroll/user/total/update';
  const onSuccess = ({ totalPayrolls }) => {
    setAmount(totalPayrolls);
    setLoading(false);
    success('Баланс оновлено.');
  };
  const onError = (_, __, err) => {
    setLoading(false);
    error('', 'Не вдалось оновити баланс. Будь ласка, спробуйте пізніше.');
  };
  request.post(route, { userId }, onSuccess, onError);
};

export const fetchPayrolls = ({ setLoading, state, setState, filter }) => {
  setLoading(true);
  const route = '/payroll/get';
  const body = {
    page: state.currentPage,
    pageSize: state.pageSize,
    date: state.selectedDate.toISOString(),
    ...filter,
  };
  const onSuccess = (res) => {
    setState((prevState) => ({
      ...prevState,
      data: res.transactions,
      totalRecords: res.total,
      totalAmount: res.totalAmount,
      loading: false,
    }));
  };
  const onError = (_, __, err) => {
    setLoading(false);
    error('', 'Не вдалось оновити баланс. Будь ласка, спробуйте пізніше.');
  };
  request.post(route, body, onSuccess, onError);
};

export const getContractByIndex = async ({ index, dispatch, setCurrentClient, setModal, CONFIRM_ADD_CONTRACT }) => {
  await request.post(
    '/contracts/getContractWithClientByIndex',
    { i: index },
    ({ contract }) => {
      const { client, ...currentContract } = contract;
      dispatch(setCurrentClient(client));
      dispatch(
        setModal({
          name: CONFIRM_ADD_CONTRACT,
          data: { contract: currentContract },
          fromWallet: true,
        }),
      );
    },
    error,
  );
};

export const pageSizeOptions = ['10', '20', '50', '100', '200', '1000'];
