import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import { green, red } from '@ant-design/colors';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Alert, Button, Card, Checkbox, Collapse, Input, Modal, Space, Typography, Spin, Select, Row } from 'antd';
import { Box } from 'common_components';
import { ROLES, SALES_TYPE, WORK_TIME } from 'common_constants/business';
import { HOTLINE_SUBMIT } from 'common_constants/modals';
import { DECLINE_REASONS, HOTLINE_RESULT, POSLUGI, PRAVO, COUNTRY_PHONE_CODES } from 'common_constants/business/index';

import CloseCabinet from '../../Modals/CloseCabinet/index';
import { setMainLoader } from '../../store/uiReducer';
import { error, info, request, warn } from '../../tools';
import Step2 from './Step2';
import Step3 from './Step3';
import { setModal } from '../../store/commonReducer';
import PageInfo from '../../components/PageInfo';
import Step1CRM from './Step1CRM';

import './Hotline.scss';
import phoneNumberValidator from 'common_components/src/tools/phoneNumberValidator';
import ActiveCalls from './ActiveCalls';

import 'dayjs/locale/uk';

dayjs.locale('uk');

const prepareDataForStorage = (data) => {
  return {
    ...data,
    crAt: data.crAt ? data.crAt.toISOString() : null,
    date: data.date ? data.date.toISOString() : null,
  };
};

const Hotline = (props) => {
  const [dispatch, history, { _id }] = [useDispatch(), useHistory(), useParams()];
  const [user, filii, topFilials, activeFilii, pravoRating, users] = [
    useSelector((state) => state.common.userAuth || {}),
    useSelector((state) => state.common.filii),
    useSelector((state) => state.common.topFilials),
    useSelector((state) => state.common.activeFilii),
    useSelector((state) => state.common.pravoRating),
    useSelector((state) => state.common.users),
  ];
  const workerOnly = ROLES[user.role] === ROLES.worker;
  const isEditing = _id ? true : false;

  const location = useLocation();
  const state = location.state || {};
  const prevPageCall = state?.state?.data;

  const [data, setData] = useState(loadData() ?? props.data ?? { type: '0', date: dayjs(new Date()), fil: '0', vip: false, pravo: '0' });
  const [suggestionPrice, setSuggestionPrice] = useState(data?.cost ?? 0);
  const [tip, setTip] = useState(localStorage.getItem('tip') === 'false' ? false : true);
  const [phone, setPhone] = useState(data?.phone);
  const [countryPhCode, setCountryPhCode] = useState(data?.countryPhCode || COUNTRY_PHONE_CODES.UA);
  const [blocked, setBlocked] = useState(false);
  const [hotlines, setHotlines] = useState([]);
  const [userName, setUserName] = useState();
  const [certificate, setCertificate] = useState();
  const [certificateExist, setCertificateExist] = useState();
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [firstHotline, setFirstHotline] = useState();
  const [restOfTheMeetings, setRestOfTheMeetings] = useState('--');
  const [clubcard, setClubcard] = useState();
  const [current, setCurrent] = useState();
  const mounted = useRef(false);

  const [{ Panel }, { Text }] = [Collapse, Typography];

  const saveData = () => {
    const preparedData = prepareDataForStorage(data);

    sessionStorage.setItem('hotlineData', JSON.stringify(preparedData));
  };
  function loadData() {
    const savedData = sessionStorage.getItem('hotlineData');
    if (savedData) {
      const parsedData = JSON.parse(savedData);
      return {
        ...parsedData,
        crAt: parsedData.crAt ? dayjs(parsedData.crAt) : null,
        date: parsedData.date ? dayjs(parsedData.date) : null,
      };
    }
    return null;
  }
  const checkFirstHotline = (data) => {
    if (data.length >= 1) {
      setFirstHotline(false);
    } else {
      setFirstHotline(true);
    }
  };

  const setLoading = (loadingState) => dispatch(setMainLoader(loadingState));

  const addOnlineStatus = (fil, date) => {
    const requestData = {
      fil,
      type: '5',
      startT: WORK_TIME[0],
      endT: WORK_TIME[WORK_TIME.length - 1],
      date: date.toISOString(),
    };

    request.post('/hotline/addOnlineFil', requestData, saveCallback, error);
  };

  const onCloseFilClick = (fil, date) => {
    Modal.confirm({
      title: 'Усі години будуть зайнятими. Ви впевнені, що хочете зачинити філію на цілий день?',
      icon: <ExclamationCircleOutlined />,
      // content: 'Some descriptions',
      okText: 'Так',
      okType: 'primary',
      okButtonProps: { style: { backgroundColor: red[5], color: 'white' } },
      cancelText: 'Ні',
      onOk() {
        sendClosingFil(fil, date);
      },
    });

    const sendClosingFil = (fil, date) => {
      const validation = (_fil, _date) => {
        if (!_fil) {
          warn('', 'Для закриття філії, потрібно вказати саму філію', { fil: !!_fil });
          return;
        }
        if (!_date) {
          warn('', 'Для закриття філії, потрібно вказати дату відкриття', { date: !!_date });
          return;
        }
        return true;
      };

      if (!validation(fil, date)) return;

      const requestData = {
        fil,
        type: '5',
        startT: WORK_TIME[0],
        endT: WORK_TIME[WORK_TIME.length - 1],
        date: date.toISOString(),
      };

      request.post('/hotline/addCloseFil', requestData, saveCallback, error);
    };
  };

  const onOpenFilClick = (fil, date) => {
    Modal.confirm({
      title: 'Усі години будуть вільними. Ви впевнені, що хочете відкрити філію на цілий день?',
      icon: <ExclamationCircleOutlined />,
      // content: 'Some descriptions',
      okText: 'Так',
      okType: 'primary',
      okButtonProps: { style: { backgroundColor: green.primary, color: 'white' } },
      cancelText: 'Ні',
      onOk() {
        sendOpenFil(fil, date);
      },
    });

    const sendOpenFil = (fil, date) => {
      const validation = (_fil, _date) => {
        if (!_fil) {
          warn('', 'Для відкривання філії, потрібно вказати саму філію', { fil: !!_fil });
          return;
        }
        if (!_date) {
          warn('', 'Для відкривання філії, потрібно вказати дату відкриття', { date: !!_date });
          return;
        }
        return true;
      };

      if (!validation(fil, date)) return;

      const requestData = {
        fil,
        date: date.toISOString(),
      };

      request.post('/hotline/addOpenFil', requestData, saveCallback, error);
    };
  };

  const onCloseCabinetClick = (fil, date, cabinet) => {
    const requestData = {
      fil,
      type: '5',
      startT: WORK_TIME[0],
      endT: WORK_TIME[WORK_TIME.length - 1],
      date: date.toISOString(),
      cabinet: cabinet,
    };

    request.post('/hotline/addCloseFil', requestData, saveCallback, error);
  };

  const saveCallback = () => {
    dispatch(setMainLoader(false));
    // if (!workerOnly) CheckUserInfo(dispatch);
    history.push('/hotlineSaved');
  };

  const resetAll = () => {
    Modal.confirm({
      title: 'Ви хочете скасувати запис?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Так',
      okType: 'danger',
      cancelText: 'Ні',
      content: 'Усі введені дані буде втрачено, і ви перейдете на самий початок. Ви впевнені?',
      onOk() {
        if (props.updateClientHotline) {
          setData(props.data);
        } else {
          setData({});
          window.location.reload();
        }
      },
      onCancel() {},
    });
  };
  const onClickEditHandle = () => {
    // const usersArray = Object.values(users);
    // const filManager = usersArray.find((user) => user.fil === data.fil && user.role === 'manager');
    // data.bmm = filManager?._id;
    dispatch(setModal({ name: HOTLINE_SUBMIT, data: data }));
  };

  useEffect(() => {
    if (data && data?.startT && data?.endT && data?.phone && data?.name) {
      setIsButtonEnabled(true);
    } else {
      setIsButtonEnabled(false);
    }
  }, [data, data?.name]);

  useEffect(() => {
    getSaleByPhone();
  }, [props.data]);

  useEffect(() => {
    localStorage.setItem('tip', tip);
  }, [tip]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    const newData = { [name]: value };

    setData((prev) => ({ ...prev, ...newData }));
  };

  const changePhone = (item) => {
    const validPhoneNumber = phoneNumberValidator(item);

    setPhone(validPhoneNumber.trim());
  };

  const checkingForExistCert = () => {
    const validation = (phoneVal) => {
      if (!phoneVal) {
        warn('', 'Для запису на консультацію (чи зустріч) потрібно вказати номер телефону.', {
          phone: phoneVal,
          text: 'Помилка при написанні номера.',
        });
        return;
      }
      if (!(phone.match(/^.{10}$/) || phone.match(/^.{9}$/))) {
        warn('', 'Номер телефону введений некоректно, розсилка СМС на нього працювати не буде.', {
          phone: phone,
          text: 'Повинно бути 10-9 символів.',
        });
        setCertificate(undefined);
        return;
      }
      if (!(data?.type === '0' || data?.type === '3' || data?.type === '6')) return;

      return true;
    };

    if (!validation(phone)) return;

    const transaction = {
      phone,
      countryPhCode,
    };

    request.post(
      '/hotline/checkingForExistCert',
      transaction,
      (req) => {
        setCertificate(req.chn);
        setCertificateExist(req.chn[1]);
      },
      error,
    );
  };

  const hotlinesForPhone = () => {
    const body = { phone, countryPhCode };

    request.post(
      '/block/blockCheck',
      body,
      (res) => {
        setBlocked(res.blockedUser ? true : false);
      },
      error,
    );

    const validation = (phoneVal) => {
      if (!phoneVal) return;
      if (!(phone.match(/^.{10}$/) || phone.match(/^.{9}$/))) return;
      return true;
    };

    if (!validation(phone)) {
      setHotlines(0);
      return;
    }

    request.post(
      '/hotline/getByPhone',
      body,
      (res) => {
        // sort by date
        const sorted =
          res.data?.sort((a, b) => {
            return new Date(b.date) - new Date(a.date);
          }) || [];
        setHotlines(sorted);
      },
      error,
    );
  };

  const getNameByPhone = () => {
    const body = { phone, countryPhCode };

    request.post(
      '/hotline/getNameByPhone',
      body,
      (res) => {
        if (res.data?.name) {
          setUserName(res.data?.name);
          setRestOfTheMeetings(res.data?.restOfTheMeetings);
          setClubcard(res.data?.clubCard);
          setIsPopoverVisible(true);
        } else {
          setUserName('');
          setRestOfTheMeetings(5);
          setClubcard(undefined);
        }
      },
      error,
    );
  };

  const getSaleByPhone = () => {
    if (props.data?.cost) return;
    const body = { phone, countryPhCode };

    request.post(
      '/sales/getByPhone',
      body,
      (res) => {
        if (res.data) {
          setData((prev) => ({ ...prev, sale: res.data }));
        }
      },
      error,
    );
  };

  useEffect(() => {
    setData((prev) => ({ ...prev, countryPhCode: countryPhCode }));
  }, [countryPhCode]);

  useEffect(() => {
    setData((prev) => ({ ...prev, phone }));
  }, [phone]);

  useEffect(() => {
    window.addEventListener('beforeunload', saveData);

    const unlisten = history.listen(() => {
      sessionStorage.removeItem('hotlineData');
    });

    return () => {
      window.removeEventListener('beforeunload', saveData);
      unlisten();
    };
  }, [history, data]);

  const isDisabledByInfoCenter = pravoRating.find((i) => i.pravo === data?.pravo)?.infoCenter ?? false;

  let reapeatHotlineRule = true;
  const latestHotline = hotlines?.[0];
  if (latestHotline) {
    const htDate = dayjs(latestHotline.date);
    const today = dayjs();
    const sixMonthsAgo = today.subtract(6, 'months');
    reapeatHotlineRule = !htDate.isBefore(sixMonthsAgo);
  }

  const removeCall = () => {
    setData((prev) => ({ ...prev, call_id: undefined }));
    setCurrent(undefined);
    info("Дзвінок відв'язано");
  };

  const choose = ({ client, ...call }) => {
    const names = client?.n?.split(' ');
    const [lastName, ...restNames] = names || [];
    const secondName = restNames.length ? restNames.join(' ') : '';

    setCurrent({ client, ...call });
    info("Дзвінок прив'язано до консультації");
    setData((prev) => ({
      ...prev,
      phone: client?.ph || call?.from?.padStart(10, '0'),
      name: secondName || '',
      surname: lastName || '',
      call_id: call.callId ?? call._id,
    }));

    setUserName(client?.name || '');
    setPhone(client?.ph || call?.from?.padStart(10, '0'));
  };

  useEffect(() => {
    if (!current && prevPageCall && !mounted.current) {
      mounted.current = true;
      choose(prevPageCall);
    }
  }, [prevPageCall, current]);

  return (
    <div className="hotline_page default_page">
      <h2 style={{ marginBottom: '40px' }}>
        Додати запис <PageInfo />
      </h2>
      <ActiveCalls removeCall={removeCall} choose={choose} current={current} data={data} />
      {tip && (
        <Alert
          message="Вітання з клієнтом"
          description={
            <>
              <b>Адвокатське обʼєднання «Захист», чим ми можемо Вам допомогти?</b>
            </>
          }
          type="info"
          showIcon
        />
      )}

      <div>
        <h3>Телефон</h3>
        <Box>
          <Text strong>Телефон: (0501234567)</Text>
          <Row justify={'start'}>
            <Select
              value={countryPhCode}
              className="countryCode"
              onChange={(value) => {
                setData((prev) => {
                  return { ...prev, countryPhCode: value };
                });
                setCountryPhCode(value);
              }}
            >
              <Select.Option value={COUNTRY_PHONE_CODES.UA}>+{COUNTRY_PHONE_CODES.UA}</Select.Option>
              <Select.Option value={COUNTRY_PHONE_CODES.DE}>+{COUNTRY_PHONE_CODES.DE}</Select.Option>
              <Select.Option value={COUNTRY_PHONE_CODES.CZ}>+{COUNTRY_PHONE_CODES.CZ}</Select.Option>
            </Select>
            &nbsp;
            <Input
              className="phoneInput"
              aria-label="write phone client"
              value={phone}
              name="phone"
              placeholder="Бажано, щоб це був мобільний телефон"
              type="tel"
              onChange={(item) => {
                handleChange(item);
                changePhone(item);
              }}
              onBlur={() => {
                checkingForExistCert();
                hotlinesForPhone();
                getNameByPhone();
                getSaleByPhone();
              }}
            />
          </Row>
          {blocked && <Alert message="Цей номер заблоковано" type="error" showIcon />}
          {!!data.call_id && (
            <Row align={'middle'} style={{ margin: '8px 0' }}>
              <Alert message="Дзвінок прив'язано" type="info" showIcon />
              <Button danger style={{ marginLeft: 12 }} onClick={removeCall}>
                Відв'язати
              </Button>
            </Row>
          )}
        </Box>
        {clubcard && (
          <Box>
            <Alert message={`Клієнт має клубну карту рівня ${clubcard.cardType}`} type={'warning'} />
          </Box>
        )}
        {certificate && (
          <Box>
            <Alert message={certificate[0]} type={certificate[1] ? 'success' : certificate[1] === 0 ? 'warning' : 'info'} showIcon />
          </Box>
        )}
        {hotlines.length > 0 && (
          <Alert
            style={{ margin: '10px 0' }}
            message=""
            description={
              <>
                <b>
                  Я перевірила інформацію, і бачу що раніше Ви уже отримувати консультацію у нашого спеціаліста. Я можу записати Вас на повторну
                  консультацію, проте мушу попередити, що усі повторні консультації у нас являються платними. Вас записати на консультацію?
                </b>
              </>
            }
            showIcon
          />
        )}
        {certificateExist !== false && (
          <Alert
            style={{ margin: '10px 0' }}
            message=""
            description={
              <>
                <b>Ви маєте сертифікати для використання</b>
              </>
            }
            showIcon
          />
        )}

        {!reapeatHotlineRule && (
          <Alert
            style={{ margin: '10px 0' }}
            message=""
            description={
              <>
                <b>Правило повторності не працює</b>
              </>
            }
            type="warning"
            showIcon
          />
        )}

        {data.sale && (
          <Alert
            style={{ margin: '10px 0' }}
            message=""
            description={
              <>
                <b>Клієнт має знижку:</b>
                <p>
                  -{data.sale.saleAmount * 100}% {SALES_TYPE[data.sale?.saleType]?.label}
                </p>
              </>
            }
            showIcon
          />
        )}

        {hotlines.length > 0 && (
          <Box>
            <Collapse size="small" accordion>
              <Panel
                header={`Консультації по вказаному номеру. За місяць: ${
                  hotlines?.filter((i) => dayjs(i.date).isBetween(dayjs().subtract(1, 'month'), dayjs(), null, '[]'))?.length ?? 0
                }. Загалом: ${hotlines.length}. `}
              >
                {hotlines.map((item) => (
                  <Card size="small" key={item._id}>
                    <Space wrap>
                      <Text strong>
                        {dayjs(item.date).format('DD.MM.YYYY')} - {item.name} - {item.phone}
                      </Text>
                    </Space>
                    <br />
                    <Space wrap>
                      <span>
                        <Text mark>На {POSLUGI[item.type]}</Text>
                      </span>
                      <span>
                        <Text strong>Вид:</Text> {PRAVO[item.pravo]}
                      </span>
                      <span>
                        <Text strong>Статус:</Text>&nbsp;
                        <Text type={item.s === '1' ? 'success' : 'danger'}>{item.s === '1' ? 'В' : 'Не в'}ідбулась</Text>
                      </span>
                      {item.s === '1' && (
                        <span>
                          <Text strong>Результат:</Text>&nbsp;
                          <Text type={item.amr === '2' ? 'success' : 'warning'}>{HOTLINE_RESULT[item.amr]}</Text>
                        </span>
                      )}
                      {item.s === '2' && item.meetDeclineReason && (
                        <span>
                          <Text strong>Причина відмови:</Text>&nbsp;
                          <Text type="warning">
                            {item.meetDeclineReason?.reason ? DECLINE_REASONS?.[item.meetDeclineReason?.reason] : item.meetDeclineReason?.ownReason}
                          </Text>
                          &nbsp;&nbsp;
                          <Text strong>Чи перенесено зустріч?</Text>&nbsp;
                          <Text type={item.meetDeclineReason?.moved ? 'success' : 'warning'}>
                            {item.meetDeclineReason?.moved ? 'П' : 'Не п'}еренесено
                          </Text>
                        </span>
                      )}

                      <span>
                        <Text strong>Коментар:</Text> {item.comment}
                      </span>
                    </Space>
                  </Card>
                ))}
              </Panel>
            </Collapse>
          </Box>
        )}
      </div>

      <Alert
        type="info"
        style={{ marginTop: '10px' }}
        message={
          <Space>
            Залишок зустрічей:
            {restOfTheMeetings}
          </Space>
        }
      />

      <div>
        <h3>Крок - 1</h3>
        <Step1CRM
          isEditing={isEditing}
          data={data}
          setData={setData}
          onCloseFilClick={onCloseFilClick}
          onOpenFilClick={onOpenFilClick}
          onCloseCabinetClick={onCloseCabinetClick}
          role={props.role}
          fil={user.fil}
          activeFilii={activeFilii}
          filii={filii}
          token={window.localStorage.getItem('accessToken')}
          host={process.env.REACT_APP_API}
          setLoading={setLoading}
          addOnlineStatus={addOnlineStatus}
          CloseCabinetModal={CloseCabinet}
          updateClientHotline={props?.updateClientHotline}
          topFilials={topFilials}
          pravoRating={pravoRating}
          setSuggestionPrice={setSuggestionPrice}
          suggestionPrice={suggestionPrice}
          firstHotline={firstHotline}
          tip={tip}
        />
      </div>
      <div>
        <h3>Крок - 2</h3>

        <Step2
          data={data}
          setData={setData}
          checkFirstHotline={checkFirstHotline}
          suggestionPrice={suggestionPrice}
          tip={tip}
          pravoRating={pravoRating}
          isEditing={isEditing}
          isPopoverVisible={isPopoverVisible}
          setIsPopoverVisible={setIsPopoverVisible}
          userName={userName}
        />
      </div>
      {data?.type !== '7' && (
        <div>
          <h3>Анкетування</h3>

          <Step3 data={data} setData={setData} tip={tip} />
          {isButtonEnabled && tip && (
            <Alert
              style={{ margin: '10px 0 ' }}
              message="Підсумок"
              description={
                <>
                  <b>
                    На номер телефону, який Ви зазначили, надійде СМС повідомлення з посиланням на направлення до адвоката з детальною інформацією про
                    запис на консультацію.
                  </b>
                  <br />
                  Якщо згідно з СРМ клієнт має отримати сертифікат, додаємо фразу:
                  <br /> <b>Зверніть увагу, в направленні буде подарунок від нашої компанії.</b> <br /> Якщо консультація платна додаємо фразу: <br />
                  <b>
                    Також Ви отримаєте СМС з посиланням на оплату консультації. Нагадую, Вам необхідно оплатити бронь протягом двох годин для
                    отримання знижки.
                  </b>
                  <br />
                  <b>Скажіть, чи є у Вас якісь питання? </b> <br />
                  Відповідаємо на питання <br /> <b>Якщо у Вас більше немає питань, тоді бажаю Вам гарного дня. На все добре.</b>
                </>
              }
              type="info"
              showIcon
            />
          )}
        </div>
      )}
      {!Number.isInteger(data.cost) && <Alert style={{ margin: '10px 0 ' }} message="Введіть ціну за консультацію" type="error" showIcon />}
      <div className="hotline_page-footer">
        <div>
          <Button danger onClick={resetAll} style={{ marginRight: '5px' }}>
            Cкасувати запис
          </Button>
          <Button
            disabled={!isButtonEnabled || (data?.type === '6' && isDisabledByInfoCenter) || !Number.isInteger(data.cost)}
            type="primary"
            onClick={onClickEditHandle}
            style={{ backgroundColor: green.primary }}
          >
            Підтвердити
          </Button>
        </div>
      </div>
      <Checkbox
        className="show-tip"
        checked={tip}
        onChange={(e) => {
          setTip(e.target.checked);
        }}
      >
        Підказки
      </Checkbox>
    </div>
  );
};

export default Hotline;
