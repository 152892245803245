import React from 'react';
import { List, Typography, Spin } from 'antd';
import dayjs from 'dayjs';
import { FileOutlined } from '@ant-design/icons';
import clsx from 'clsx';
import Row from '../Row';
import Column from '../Column';
import { formatBytes } from '../../tools';

import ChatImage from './ChatImage';

const { Text } = Typography;
const { Item } = List;

const Message = ({ item, onDownload }) => {
  if (!item)
    return (
      <div className="message-loading">
        <Spin size="small" />
      </div>
    );

  if (item.removed) return <></>;

  const ifItemIsImage =
    'fileName' in item && (item?.fileName?.endsWith('.png') || item?.fileName?.endsWith('.jpg') || item?.fileName?.endsWith('.jpeg'));

  return (
    <Item className="list-item" style={{ alignItems: item.isSentByCurrentUser ? 'flex-end' : 'flex-start' }}>
      <Text className="author">
        {item.name} - {dayjs(item.date).format('DD.MM.YYYY')}
      </Text>
      <Column
        className={clsx(
          'message',
          item.fileId && 'file',
          item.fileId && ifItemIsImage && 'chatImage-inner',
          item.isSentByCurrentUser ? 'sent' : 'received',
          item.isSendByClient && 'client',
        )}
      >
        {item.message && <Text className={'text'}>{item.message}</Text>}
        {item.fileId && ifItemIsImage && <ChatImage item={item} />}

        {item.fileId && !ifItemIsImage && (
          <>
            <FileOutlined style={{ marginRight: 8 }} onClick={onDownload} />
            <Column>
              <Text strong>{item.fileName}</Text>
              <Text>{formatBytes(item.fileSize)}</Text>
            </Column>
          </>
        )}
        <Row className="bottom">
          {item.edited && (
            <Text italic className={'edited-text'}>
              Відредаговано
            </Text>
          )}
          <Text className={'time'}>{dayjs(item.date).format('HH:mm')}</Text>
        </Row>
      </Column>
    </Item>
  );
};

export default Message;
