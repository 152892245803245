import React, { useState } from 'react';
import { Card, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { ROLES, CHAT_TYPES } from 'common_constants/business';

import { setMainLoader } from '../../../../../store/uiReducer';
import { editContract, setContractsWithoutBlockCount } from '../../../../../store/commonReducer';
import { request, error, warn, success } from '../../../../../tools';
import SearchSelectBlock from '../../../../../components/SearchSelect/SearchSelectBlock';
import SearchSelectUsersMultiple from '../../../../../components/SearchSelect/SearchSelectUsersMultiple';
import { Box } from 'common_components';

const BlockManagement = ({ data }) => {
  const dispatch = useDispatch();
  const [block, setBlock] = useState(data.block);

  const userAuth = useSelector((state) => state.common.userAuth);
  const users = useSelector((state) => state.common.users);
  const contractsWithoutBlockCount = useSelector((state) => state.common.contractsWithoutBlockCount);

  const inspector = ROLES[userAuth.role] <= ROLES.inspector;
  const blockManagerOnly = ROLES[userAuth.role] === ROLES.blockManager;
  const preparedUsers = Object.values(users).filter((user) => user?.fired !== true);
  const preparedUsersForInspector = Object.values(users).filter((user) => user?.fired !== true && user.block);

  const { Text, Title } = Typography;

  const handleChangeBlock = async (value) => {
    const validation = (_value) => {
      if (typeof _value !== 'string') {
        warn('', 'Для призначення, потрібно вказати блок.', { value: 'not string' });
        return;
      }

      return true;
    };

    if (!validation(value)) return;

    dispatch(setMainLoader(true));

    await request.post(
      '/contracts/add',
      {
        _id: data._id,
        block: value,
      },
      () => {
        setBlock(value);
        dispatch(editContract({ _id: data._id, block: value }));
        dispatch(
          setContractsWithoutBlockCount(
            !data?.block && value
              ? contractsWithoutBlockCount - 1
              : data?.block && !value
              ? contractsWithoutBlockCount + 1
              : contractsWithoutBlockCount,
          ),
        );
        success('', value === '' ? '' : 'Було призначено блоку цей договір');
      },
      error,
    );

    dispatch(setMainLoader(false));
  };

  const handleChangeAssigned = async (value) => {
    const validation = (_value) => {
      if (!Array.isArray(_value)) {
        warn('', 'Для призначення, потрібно вказати адвоката.', { value: 'not array' });
        return;
      }

      if (blockManagerOnly && block === undefined) {
        warn('', 'Щоб призначити людину, треба спочатку щоб контракт був призначений за блоком.', { block: 'undefined' });
        return;
      }

      return true;
    };

    if (!validation(value)) return;

    dispatch(setMainLoader(true));

    const isAddedLawyer = !data.accessBlock || data.accessBlock?.length < value.length;
    await request.post(
      '/contracts/setRabfilAccess',
      {
        contractId: data._id,
        accessBlock: value,
      },
      () => {
        dispatch(editContract({ _id: data._id, accessBlock: value }));
        success('', isAddedLawyer ? 'Було призначено адвокату цей договір' : '');
      },
      error,
    );

    if (isAddedLawyer) {
      const assignedUser = preparedUsers.find((user) => user._id === value[value.length - 1]);

      const messageData = {
        chatId: data._id,
        sender: assignedUser?._id,
        message: `Доброго дня, мене звуть ${assignedUser?.name} ${
          assignedUser?.userPersonalData?.workPhone ? `, мій номер телефону ${assignedUser.userPersonalData.workPhone}` : ''
        }. Мене призначено юристом по вашій справі. 
        Залюбки допоможу вирішити Ваші питання!`,
        type: CHAT_TYPES.chatWithClient.key,
      };

      request.post(
        '/chatPrivate/chatSendMessage',
        messageData,
        () => {
          success('Клієнту було надіслано повідомлення про ваше призначення');
        },
        error,
      );
    }

    dispatch(setMainLoader(false));
  };

  return (
    <Card>
      {/* //* Інфо поле тільки для інспектора та вище, щоб розуміли з чим працюють вони*/}
      {inspector && (
        <Title level={2} style={{ margin: 0 }}>
          Блок
        </Title>
      )}
      {/* //* Право тільки у інспектора*/}
      {inspector && (
        <>
          <Text strong>Призначити блок:</Text>
          <SearchSelectBlock onChange={handleChangeBlock} value={block} />
        </>
      )}
      {/* //* Право від рівня керівника блоку*/}
      <Box>
        <Text strong>Призначити людей:</Text>
        <SearchSelectUsersMultiple
          disabled={blockManagerOnly && block === undefined}
          onChange={handleChangeAssigned}
          users={inspector ? preparedUsersForInspector : preparedUsers}
          value={data.accessBlock}
          style={{ width: '100%' }}
        />
      </Box>
      {blockManagerOnly && block === undefined && (
        <Box>
          <Text type="warning" className="sender-filter-box-text">
            Щоб призначити людину, треба спочатку щоб контракт був призначений за блоком.
          </Text>
        </Box>
      )}
    </Card>
  );
};

export default BlockManagement;
