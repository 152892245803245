import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useResize } from 'common_components';

import { SmallDashOutlined, SortAscendingOutlined, SortDescendingOutlined } from '@ant-design/icons';
import { Card, DatePicker, Divider, Empty, Input, List, Radio, Select, Space, Spin, Typography } from 'antd';

import { DATE_PERIOD_REACH, PRAVO, PROPOSED_AMOUNT_PERIOD_REACH, ROLES } from 'common_constants/business';
import Box from '../../components/Box';
import { setCurrentReachAnkList } from '../../store/commonReducer';
import { error, request } from '../../tools';
import phoneNumberValidatior from 'common_components/src/tools/phoneNumberValidator';
import HotlineReachItem from '../HotlineReach/HotlineReachItem';

import dayjs from 'dayjs';
import 'dayjs/locale/uk';
import locale from 'antd/locale/uk_UA';

import {
  SelectGeneralFil,
  SelectGeneralHellishnessReach,
  SelectGeneralOfferedAmount,
  SelectGeneralResultMeeting,
  SelectGeneralTypeReach,
  SelectGeneralTypeService,
  SelectGeneralWantsToSignContract,
} from '../../components/SelectGeneral';

import '../HotlineReach/HotlineReach.scss';

const validPravoIndices = ['1', '2', '3', '12', '21', '28', '37'];

const DozhimsANK = (props) => {
  const dateFormat = 'DD.MM.YYYY';
  const dispatch = useDispatch();
  const resize = useResize();

  const users = useSelector((state) => state.common.users);

  const list = useSelector((state) => state.common.currentReachAnkList);
  const userAuth = useSelector((state) => state.common.userAuth);

  const currentUser = users?.[userAuth?._id];
  const isBrigadeWorker = currentUser?.brigadeWorker;
  const isBrigadeManager = currentUser?.brigadeManager;

  const deputyManager = ROLES[props.role] <= ROLES.deputyManager;

  //* list
  const [listAndUsersState, setListAndUsersState] = useState(false);
  const [listNameClientsState, setListNameClientsState] = useState([]);
  const [listAssignedUsersState, setListAssignedUsersState] = useState([]);
  const [filteredList, setFilteredList] = useState([]);

  const core_megaState = {
      loading: false,
      page: 1,
      total: 0,
      //* filter
      fil: props.fil ?? '_',
      dateArr: '_',
      date: '_',
      assigneUser: '_',
      ampArr: '_',
      rsm: '_',
      pravo: '_',
      type: '_',
      amr: '_',
      rih: '_',
      qd: '_',
      stranger: '_',
      //* sort
      paySort: 0,
      dateSort: 0,
      hotSort: 0,
      //* find
      nameClient: '',
      numbClient: '',
    },
    [megaState, setMegaState] = useState(core_megaState);

  setTimeout(() => {
    if (!listAndUsersState && !!list && !!users) setListAndUsersState(true);
  }, 0);

  const relativeTime = require('dayjs/plugin/relativeTime');
  dayjs.extend(relativeTime).locale('uk');

  const { Text, Title } = Typography;
  const { Option } = Select;
  const { Search } = Input;

  useEffect(() => {
    if (list) {
      const filteredRecords = isBrigadeManager ? list : list.filter((record) => record.ank === userAuth?._id);

      setFilteredList(filteredRecords);
    }
  }, [list, isBrigadeManager, userAuth?._id]);

  const getAllReachHotline = () => {
    setMegaState((prev) => ({ ...prev, loading: true }));

    const transaction = {
      page: megaState.page,
      //* filter
      ...(megaState.fil !== '_' && megaState.fil !== 'undefined' ? { fil: megaState.fil } : {}),
      ...(Array.isArray(megaState.dateArr) ? { date: findDateRange() } : {}), //* Дата
      ...(megaState.assigneUser !== '_' && megaState.assigneUser !== 'undefined'
        ? { assigneUser: findAssigneUser(listAssignedUsersState[Number(megaState.assigneUser)]) }
        : {}), //* Призначено
      ...(megaState.ampArr !== '_' && megaState.ampArr !== 'undefined' ? { ampArr: megaState.ampArr } : {}), //* Масив запропонованих сум
      ...(megaState.rsm !== '_' && megaState.rsm !== 'undefined' ? { rsm: megaState.rsm } : {}), //* Тип дожиму
      ...(megaState.pravo !== '_' && megaState.pravo !== 'undefined' ? { pravo: megaState.pravo } : {}), //* Вид правовідносин //* Вид правовідносин
      ...(megaState.type !== '_' && megaState.type !== 'undefined' ? { type: megaState.type } : {}), //* Тип послуги
      ...(megaState.amr !== '_' && megaState.amr !== 'undefined' ? { amr: megaState.amr } : {}), //* Результат зустрічі
      ...(megaState.rih !== '_' && megaState.rih !== 'undefined' ? { rih: megaState.rih } : {}), //* Фільтр пекельності
      ...(megaState.qd !== '_' && megaState.qd !== 'undefined' ? { qd: megaState.qd } : {}), //* Мрія клієнта
      ...(megaState.stranger !== '_' && megaState.stranger !== 'undefined' ? { stranger: megaState.stranger } : {}),
      ...(megaState.date !== '_' && megaState.date !== 'undefined' ? { date: megaState.date } : {}), //* Дата
      //* sort
      ...(megaState.paySort !== '_' ? { paySort: megaState.paySort } : {}), //* По запропонованій сумі
      ...(megaState.dateSort !== '_' ? { dateSort: megaState.dateSort } : {}), //* По даті звітування
      ...(megaState.hotSort !== '_' ? { hotSort: megaState.hotSort } : {}), //* По пекельності дожиму
      //* find
      ...(megaState.nameClient !== '' && megaState.nameClient !== 'undefined'
        ? { nameClient: listNameClientsState[Number(megaState.nameClient)] }
        : {}),
      ...(megaState.numbClient !== '' && megaState.numbClient !== 'undefined' ? { numbClient: megaState.numbClient } : {}),
      ...(isBrigadeWorker ? { ank: userAuth?._id } : {}), //* Призначено АНК
    };

    request.post(
      '/hotline/toReachAnk',
      transaction,
      ({ cor, total }) => {
        dispatch(setCurrentReachAnkList(cor));
        setMegaState((prev) => ({ ...prev, total: total }));
      },
      error,
    );

    setMegaState((prev) => ({ ...prev, loading: false }));
  };
  const updateRecordInList = (updatedRecord) => {
    const updatedList = list.map((item) => (item.phone === updatedRecord.phone ? updatedRecord : item));

    dispatch(setCurrentReachAnkList(updatedList));
  };

  const findAssigneUser = (psevdo) => {
    for (let key in users) {
      if (users[key].p === psevdo) {
        return users[key]._id;
      }
    }
  };

  const findDateRange = () => {
    if (megaState.dateArr.length === 2) {
      const date = megaState.dateArr[0] / 86400000; //* Мілісекунди в дні
      const currentDate = new Date();
      const beforeDate = new Date(new Date(currentDate).setDate(new Date(currentDate).getDate() - date));
      return beforeDate;
    }
  };

  const handlePhoneNumberInput = (event) => {
    const validPhoneNumber = phoneNumberValidatior(event);

    setMegaState((prev) => ({ ...prev, numbClient: String(validPhoneNumber) }));
  };

  const styleSize = resize[0] >= 500 ? 'reach-filter-box-pc' : 'reach-filter-box-mob';

  useEffect(() => {
    getAllReachHotline();
  }, [
    megaState.page,
    megaState.fil,
    megaState.dateArr,
    megaState.assigneUser,
    megaState.ampArr,
    megaState.rsm,
    megaState.pravo,
    megaState.type,
    megaState.amr,
    megaState.rih,
    megaState.qd,
    megaState.stranger,
    megaState.paySort,
    megaState.dateSort,
    megaState.hotSort,
    megaState.nameClient,
    megaState.date,
  ]);

  useEffect(() => {
    if (!listAndUsersState || !window.users) return;

    const listNameClients =
      list
        ?.map((i) => {
          i = i.name ?? 'Немає';
          return i;
        })
        .filter((it, index, arr) => index === arr.indexOf((it = it.trim()))) ?? [];

    const listAssignedUsers =
      list
        ?.map((i) => {
          i = (users && users[i.ank]?.p) ?? 'Немає';
          return i;
        })
        .filter((it, index, arr) => index === arr.indexOf((it = it.trim()))) ?? [];

    setListNameClientsState(listNameClients);
    setListAssignedUsersState(listAssignedUsers);
  }, [listAndUsersState, list, users]); // * Важливо, щоб отримати гнучкий список фільтрів з массива дожима

  if (!users) return null;

  return (
    <Spin tip="Завантаження" spinning={megaState.loading}>
      <Space
        direction="vertical"
        size="middle"
        style={{
          display: 'flex',
        }}
        className="hotline-reach-page default-page"
      >
        <Title className="reach-title" level={2}>
          Дожими АНК:
        </Title>
        <Card>
          <Box mt={0} className={'reach-main-menu'}>
            <Box mt={8}>
              <Divider orientation="left">Фільтри:</Divider>

              <Box className={styleSize}>
                <SelectGeneralFil
                  className={'reach-filter'}
                  defaultValue={megaState.fil}
                  onChange={(value) => setMegaState((prev) => ({ ...prev, fil: String(value) }))}
                />
                <Text className="reach-filter-box-text"> Філія: </Text>
              </Box>

              {deputyManager && (
                <Box className={styleSize}>
                  <Select
                    className="reach-filter"
                    showSearch
                    allowClear
                    placeholder="Оберіть призначеного"
                    defaultValue={megaState.assigneUser}
                    onChange={(value) => setMegaState((prev) => ({ ...prev, assigneUser: String(value) }))}
                    filterOption={(input, option) => option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                    <Option value="_">Усі призначенні</Option>
                    {listAssignedUsersState.map((i, index) => (
                      <Option key={i} value={String(index)}>
                        {i}
                      </Option>
                    ))}
                  </Select>
                  <Text className="reach-filter-box-text"> Призначено: </Text>
                </Box>
              )}

              <Box className={styleSize}>
                <SelectGeneralOfferedAmount
                  className={'reach-filter'}
                  defaultValue={megaState.ampArr}
                  onChange={(value) => setMegaState((prev) => ({ ...prev, ampArr: PROPOSED_AMOUNT_PERIOD_REACH[String(value)]?.value }))}
                />
                <Text className="reach-filter-box-text"> Запропонована сума: </Text>
              </Box>

              <Box className={styleSize}>
                <Select
                  className="reach-filter"
                  showSearch
                  allowClear
                  placeholder="Оберіть дату звітування"
                  defaultValue={megaState.dateArr}
                  onChange={(value) => setMegaState((prev) => ({ ...prev, dateArr: DATE_PERIOD_REACH[String(value)]?.value }))}
                  filterOption={(input, option) => option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  <Option value="_">Усі дати звітувань</Option>
                  {DATE_PERIOD_REACH.map((i, index) => (
                    <Option key={i.text} value={String(index)}>
                      {i.text}
                    </Option>
                  ))}
                </Select>
                <Text className="reach-filter-box-text"> Дата звітування: </Text>
              </Box>

              <Box className={styleSize}>
                <SelectGeneralTypeReach
                  className={'reach-filter'}
                  defaultValue={megaState.rsm}
                  onChange={(value) => setMegaState((prev) => ({ ...prev, rsm: String(value) }))}
                />
                <Text className="reach-filter-box-text"> Тип дожиму: </Text>
              </Box>

              <Box className={styleSize}>
                <SelectGeneralTypeService
                  className={'reach-filter'}
                  defaultValue={megaState.type}
                  onChange={(value) => setMegaState((prev) => ({ ...prev, type: String(value) }))}
                />
                <Text className="reach-filter-box-text"> Тип послуги: </Text>
              </Box>

              <Box className={styleSize}>
                <SelectGeneralResultMeeting
                  className={'reach-filter'}
                  defaultValue={megaState.amr}
                  onChange={(value) => setMegaState((prev) => ({ ...prev, amr: String(value) }))}
                />
                <Text className="reach-filter-box-text"> Результат зустрічі: </Text>
              </Box>

              <Box className={styleSize}>
                <SelectGeneralHellishnessReach
                  className={'reach-filter'}
                  defaultValue={megaState.rih}
                  onChange={(value) => setMegaState((prev) => ({ ...prev, rih: String(value) }))}
                />
                <Text className="reach-filter-box-text"> Пекельність дожиму: </Text>
              </Box>

              <Box className={styleSize}>
                <SelectGeneralWantsToSignContract
                  className={'reach-filter'}
                  defaultValue={megaState.qd}
                  onChange={(value) => setMegaState((prev) => ({ ...prev, qd: String(value) }))}
                />
                <Text className="reach-filter-box-text"> Мрія клієнта: </Text>
              </Box>

              <Box className={resize[0] >= 500 ? 'reach-filter-box-pc' : 'reach-filter-box-mob'}>
                <Select
                  className={'reach-filter'}
                  showSearch
                  allowClear
                  placeholder="Оберіть належність дожиму"
                  defaultValue={megaState.stranger}
                  onChange={(value) => setMegaState((prev) => ({ ...prev, stranger: String(value) }))}
                  filterOption={(input, option) => option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  <Option value="_">Усі дожими</Option>
                  <Option value="0">Чужий</Option>
                  <Option value="1">Свій</Option>
                </Select>
                <Text className="reach-filter-box-text"> Чий дожим: </Text>
              </Box>

              <Box className={resize[0] >= 500 ? 'reach-filter-box-pc' : 'reach-filter-box-mob'}>
                <Select
                  className={'reach-filter'}
                  showSearch
                  allowClear
                  placeholder="Право"
                  defaultValue={megaState.pravo}
                  onChange={(value) => setMegaState((prev) => ({ ...prev, pravo: String(value) }))}
                  filterOption={(input, option) => option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  <Option value="_">Усі дожими</Option>
                  {validPravoIndices.map((value, index) => {
                    return (
                      <Select.Option key={value} value={value}>
                        {PRAVO[value]}
                      </Select.Option>
                    );
                  })}
                </Select>
                <Text className="reach-filter-box-text"> Право: </Text>
              </Box>

              <Box className={styleSize}>
                <DatePicker
                  format={dateFormat}
                  locale={locale}
                  className={'reach-filter'}
                  defaultValue={megaState.date === '_' ? undefined : megaState.date}
                  onChange={(value) => setMegaState((prev) => ({ ...prev, date: value }))}
                />
                <Text className="reach-filter-box-text"> Дата: </Text>
              </Box>
            </Box>
            <Box mt={8}>
              <Divider orientation="left">Сортування:</Divider>
              <Box className={'reach-sort'}>
                <Text className={'reach-sort-text'}>По запропонованій сумі: </Text> &nbsp;
                <Radio.Group
                  size="small"
                  onChange={({ target: { value } }) => setMegaState((prev) => ({ ...prev, paySort: value, dateSort: 0, hotSort: 0 }))}
                  buttonStyle="solid"
                  defaultValue={megaState.paySort}
                  value={megaState.paySort}
                >
                  <Radio.Button value={0}>
                    <SmallDashOutlined />
                  </Radio.Button>
                  <Radio.Button value={1}>
                    <SortDescendingOutlined />
                  </Radio.Button>
                  <Radio.Button value={-1}>
                    <SortAscendingOutlined />
                  </Radio.Button>
                </Radio.Group>
              </Box>
              <Box className={'reach-sort'}>
                <Text className={'reach-sort-text'}>По даті звітування: </Text> &nbsp;
                <Radio.Group
                  size="small"
                  onChange={({ target: { value } }) => setMegaState((prev) => ({ ...prev, paySort: 0, dateSort: value, hotSort: 0 }))}
                  buttonStyle="solid"
                  defaultValue={megaState.dateSort}
                  value={megaState.dateSort}
                >
                  <Radio.Button value={0}>
                    <SmallDashOutlined />
                  </Radio.Button>
                  <Radio.Button value={1}>
                    <SortDescendingOutlined />
                  </Radio.Button>
                  <Radio.Button value={-1}>
                    <SortAscendingOutlined />
                  </Radio.Button>
                </Radio.Group>
              </Box>
              <Box className={'reach-sort'}>
                <Text className={'reach-sort-text'}>По пекельності дожиму: </Text> &nbsp;
                <Radio.Group
                  size="small"
                  onChange={({ target: { value } }) => setMegaState((prev) => ({ ...prev, paySort: 0, dateSort: 0, hotSort: value }))}
                  buttonStyle="solid"
                  defaultValue={megaState.hotSort}
                  value={megaState.hotSort}
                >
                  <Radio.Button value={0}>
                    <SmallDashOutlined />
                  </Radio.Button>
                  <Radio.Button value={1}>
                    <SortDescendingOutlined />
                  </Radio.Button>
                  <Radio.Button value={-1}>
                    <SortAscendingOutlined />
                  </Radio.Button>
                </Radio.Group>
              </Box>
              <Divider orientation="left">Пошук:</Divider>
              <Box className={'reach-sort'}>
                <Text className={'reach-sort-text reach-find-text'}> По телефону: </Text> &nbsp;
                <Search
                  placeholder="Введіть номер телефону"
                  onSearch={() => getAllReachHotline()}
                  enterButton
                  onChange={(event) => handlePhoneNumberInput(event)}
                  value={megaState.numbClient}
                  defaultValue={megaState.numbClient}
                />
              </Box>
              <Box className={'reach-sort'}>
                <Text className={'reach-sort-text reach-find-text'}> По імені: </Text> &nbsp;
                <Select
                  className="reach-filter"
                  showSearch
                  allowClear
                  placeholder="Введіть ім'я клієнта"
                  value={megaState.nameClient}
                  defaultValue={megaState.nameClient}
                  onChange={(value) => setMegaState((prev) => ({ ...prev, nameClient: String(value) }))}
                  filterOption={(input, option) => option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  {listNameClientsState.map((i, index) => (
                    <Option key={i} value={String(index)}>
                      {i}
                    </Option>
                  ))}
                </Select>
              </Box>
            </Box>
          </Box>
        </Card>

        <Box className={'reach-list'} mt={16}>
          {filteredList ? (
            <List
              pagination={{
                hideOnSinglePage: true,
                position: 'bottom',
                align: 'end',
                defaultCurrent: 1,
                defaultPageSize: 20,
                current: megaState.page,
                total: megaState.total,
                showTotal: (total, range) => `${range[0]}-${range[1]} з ${total} дожимів`,
                onChange: (value) => setMegaState((prev) => ({ ...prev, page: value })),
              }}
              dataSource={filteredList ?? []}
              renderItem={(i, index) => (
                <HotlineReachItem
                  i={i}
                  key={i._id}
                  index={index}
                  role={props.role}
                  updateRecord={updateRecordInList}
                  blocked={i.bl}
                  ankLabel="DozhimsANK"
                />
              )}
            ></List>
          ) : (
            <Empty description={<Text>Нема дожимів</Text>} />
          )}
        </Box>
        <br />
      </Space>
    </Spin>
  );
};

export default DozhimsANK;
