import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { CloseOutlined, UserOutlined } from '@ant-design/icons';

import { Alert, Button, Input, InputNumber, Popover, Typography } from 'antd';

import { Box } from 'common_components';
import SearchSelectCurrencies from '../../components/SearchSelect/SerchSelectCurrencies';

import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
dayjs.extend(isBetween);

const Step2 = ({ isPopoverVisible, setIsPopoverVisible, userName, pravoRating, data, setData, ...props }) => {
  const { Text } = Typography;
  const names = userName?.split(' ');
  const [lastName, ...restNames] = names || [];
  const secondName = restNames.length ? restNames.join(' ') : '';

  const filii = useSelector((state) => state.common.filii);
  const defaultCurrency = filii.find((item) => item.i === Number(data?.fil))?.currency || 'UAH';

  const [name, setName] = useState(data?.name || '');
  const [surname, setSurname] = useState(data?.surname || '');
  const [cost, setCost] = useState(data?.cost || '');
  const [currency, setCurrency] = useState(defaultCurrency);

  const comment = data?.comment || '';

  const changeCost = (item) => {
    if (item >= 0) {
      setCost(item);
      setData({ ...data, cost: item });
    }
  };

  const handleChangeCurrency = (value) => {
    setCurrency(value);
    setData({ ...data, currency: value });
  };

  useEffect(() => {
    setCurrency(defaultCurrency);
    setData((prevData) => ({ ...prevData, currency: defaultCurrency }));
  }, [defaultCurrency]);

  useEffect(() => {
    setName(data.name);
    setSurname(data.surname);
  }, [data.surname, data.name]);

  const CustomPopoverContent = ({ handlePopoverClick, handlePopoverClose }) => {
    return (
      <div style={{ overflow: 'auto' }}>
        <span style={{ fontWeight: 'bold', fontSize: '16px', cursor: 'pointer' }} onClick={handlePopoverClick}>
          {<Button type="primary">Використати ім'я</Button>}
        </span>
        <Button
          type="text"
          icon={<CloseOutlined />}
          onClick={handlePopoverClose}
          style={{
            position: 'absolute',
            top: '5px',
            right: '5px',
          }}
        />
      </div>
    );
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    const newData = { [name]: value };
    setData({ ...data, ...newData });
  };

  const pravoRatingPrice = pravoRating?.find((item) => item?.pravo === data?.pravo)?.price || 500;

  const handleKeyPress = (e) => {
    const key = e.key;
    if (['.', ','].includes(key)) {
      e.preventDefault();
    }
  };

  return (
    <div className="step2">
      {props.tip && (
        <Alert
          message="Дані клієнта"
          description={
            <>
              <b>Добре, я записую Вас на прийом, назвіть будь ласка імʼя та як по батькові.</b>
            </>
          }
          type="info"
          showIcon
        />
      )}

      <Box>
        <Text strong>Ім'я, по батькові:</Text>

        <Popover
          placement="topLeft"
          content={
            <CustomPopoverContent
              userName={userName}
              handlePopoverClick={() => {
                setIsPopoverVisible(false);
                setName(secondName);
                setSurname(lastName);
                setData({ ...data, name: secondName, surname: lastName });
              }}
              handlePopoverClose={() => setIsPopoverVisible(false)}
            />
          }
          title={
            <div style={{ paddingRight: '24px' }}>
              Знайдено користувача: <span style={{ fontWeight: 'bold' }}>{userName}</span>
            </div>
          }
          trigger="click"
          open={isPopoverVisible && userName && userName !== name}
          getPopupContainer={(triggerNode) => triggerNode}
          overlayStyle={{ border: '1px solid #B4B4B4', borderRadius: '10px' }}
        >
          <Input
            name="name"
            placeholder=" Вказувати імʼя та по батькові"
            aria-label="write name client"
            style={{ marginBottom: 10 }}
            value={name}
            prefix={<UserOutlined />}
            onChange={(item) => {
              setName(item.target.value);
              setIsPopoverVisible(false);
            }}
            onBlur={(item) => handleChange(item)}
          />

          <Text strong>Прізвище:</Text>

          <Input
            name="surname"
            placeholder=" Вказувати тільки прізвище"
            aria-label="write surname client"
            value={surname}
            prefix={<UserOutlined />}
            onChange={(item) => {
              setSurname(item.target.value);
              setIsPopoverVisible(false);
            }}
            onBlur={(item) => handleChange(item)}
          />
        </Popover>
      </Box>

      {pravoRatingPrice && (
        <p>
          Ціна по рейтингу права: <br />
          Оплата <b> до </b> 2 годин від часу бронювання <b> ₴{pravoRatingPrice}</b> <br />
          Оплата <b> після </b> 2 годин від часу бронювання <b> ₴{pravoRatingPrice + 100}</b> <br />
          VIP <b> до </b> 2 годин від часу бронювання <b> ₴{pravoRatingPrice * 2}</b> <br />
          VIP <b> після </b> 2 годин від часу бронювання <b> ₴{pravoRatingPrice * 2 + 200}</b> <br />
        </p>
      )}

      <Box>
        <Text strong>Ціна консультації:</Text>
        <div style={{ display: 'flex' }}>
          <InputNumber
            onKeyDown={handleKeyPress}
            placeholder="Ціну вказувати від нуля"
            aria-label="write consultation price"
            value={Number.isInteger(cost) ? cost : ''}
            onChange={(item) => {
              changeCost(item);
            }}
            step={100}
            style={{ width: '100%', marginRight: '10px' }}
          />

          <SearchSelectCurrencies value={currency} onChange={handleChangeCurrency} />
        </div>
      </Box>

      <Box>
        <Text strong>Коментар:</Text>
        <Input
          name="comment"
          placeholder="Опишіть корисну інформацію"
          aria-label="write comment lawyer"
          value={comment}
          onChange={(item) => handleChange(item)}
        />
      </Box>
    </div>
  );
};

export default Step2;
