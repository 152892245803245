import { ROUTES } from 'common_constants/routes';
import { useSelector } from 'react-redux';
import { Route, Switch, useLocation, Redirect } from 'react-router-dom';

import Auth from './pages/Auth';
import HomeDashboard from './pages/HomeDashboard';

import Activity from './pages/Activity';
import Assignment from './pages/Assingment';
import Blocked from './pages/Blocked/Blocked';
import Cabinets from './pages/Cabinets';
import Chat from './pages/Chat';
import Clients from './pages/Clients';
import ClientsFeedback from './pages/ClientsFeedback';
import Contracts from './pages/Contracts';
import InsuranceContracts from './pages/Contracts/InsuranceContracts';
import FeeExploration from './pages/FeeExploration';
import Filii from './pages/Filii/Filii';
import HackworksPage from './pages/HackworksPage/HackworksPage';
import Harvest from './pages/Harvest';
import Helper from './pages/Helper';
import Hotline from './pages/Hotline';
import PsychologistHotline from './pages/PsychologistHotline';
import ListPsychologistHotline from './pages/ListPsychologistHotline/ListPsychologistHotline';
import HotlineReach from './pages/HotlineReach';
import FailureToPay from './pages/FailureToPay';
import HotlineSaved from './pages/HotlineSaved';
import HotlinesByClients from './pages/HotlinesByClients';
import { Journal, JournalItem } from './pages/Journal';
import Library from './pages/Library/Library';
import CorrespondencePage from './pages/CorrespondencePage/CorrespondencePage';
import List from './pages/List';
import Partners from './pages/Partners';
import Profile from './pages/Profile';
import Rating from './pages/Rating';
import PravoRating from './pages/PravoRating';
import Search from './pages/Search';
import PersonnelDirectory from './pages/PersonnelDirectory';
import ClubСards from './pages/ClubСards';
import Sender from './pages/Sender';
import BankCards from './pages/BankCards/BankCards';
import StatMeetCancelationReasons from './pages/StatMeetCancelationReasons';
import StatClientsDemography from './pages/StatClientsDemography';
import StatFilActiveClientsWithApp from './pages/StatFilActiveClientsWithApp';
import StatFilActiveContracts from './pages/StatFilActiveContracts';
import StatFilArchiveCandy from './pages/StatFilArchiveCandy';
import UserEdit from './pages/UserEdit';
import Users from './pages/Users';
import WebsiteNews from './pages/WebsiteNews';
import PublishWebsite from './pages/PublishWebsite';
import Complaints from './pages/Complaints';
import Expense from './pages/Expense';
import Tasks from './pages/Tasks';
import Sugar from './pages/Sugar';
import PushNotification from './pages/PushNotification';
import Tenders from './pages/Tenders';
import Insurance from './pages/Insurance';
import Webinars from './pages/Webinars';
import Development from './pages/Development';
import AccessList from './pages/AccessList';
import Suggestions from './pages/Suggestions';
import Mfo from './pages/Mfo';
import Conflicts from './pages/Conflicts';
import ConflictChat from './pages/ConflictChat';
import Twitter from './pages/Twitter';
import TweetPage from './pages/Twitter/TweetPage/TweetPage';
import RedButtonChat from './pages/RedButtonChat';
import Documents from './pages/Documents/';
import ClientsQuickChats from './pages/ClientsQuickChats';
import Wallet from './pages/Wallet/Wallet';
import FunctionSurvey from './pages/FunctionSurvey';
import Lateness from './pages/Lateness';
import WalletFilials from './pages/WalletFilials';
import FilledContractPM from './pages/FilledContractPM';
import ClientsDozhimsAIChats from './pages/ClientsDozhimsAIChats';
import FondyHotlines from './pages/FondyHotlines';
import InsuranceType from './pages/InsuranceType';
import ITServices from './pages/ITServices';
import Dublicates from './pages/Dublicates';
import CallsHotline from './pages/HotlineCalls';
import DozhimsANK from './pages/DozhimsANK/DozhimsANK';

export default function RouterSwitch() {
  const userAuth = useSelector((state) => state.common.userAuth);
  const { fil, _id, username, role = 99 } = userAuth;
  const location = useLocation();

  return (
    <Switch>
      <Route exact path={ROUTES.AUTH}>
        <Auth />
      </Route>
      {userAuth && (
        <>
          <Route exact path={ROUTES.HOME_DASHBOARD}>
            <HomeDashboard username={username} role={role} />
          </Route>
          <Route exact path={ROUTES.HOTLINE}>
            <Hotline fil={fil} role={role} data={null} updateClientHotline={null} />
          </Route>
          <Route exact path={ROUTES.PSYCHOLOGIST_HOTLINE}>
            <PsychologistHotline fil={fil} role={role} data={null} updateClientHotline={null} />
          </Route>
          <Route exact path={ROUTES.LIST_PSYCHOLOGIST_HOTLINE}>
            <ListPsychologistHotline fil={fil} role={role} username={username} />
          </Route>
          <Route exact path={ROUTES.PROFILE}>
            <Profile />
          </Route>
          <Route exact path={ROUTES.HOTLINE_UPDATE}>
            <Hotline data={location.state?.data} updateClientHotline={location.state?.updateClientHotline} />
          </Route>
          <Route exact path={ROUTES.HOTLINE_ID}>
            <Hotline data={location.state?.data} />
          </Route>
          <Route exact path={ROUTES.HOTLINE_SAVED}>
            <HotlineSaved />
          </Route>
          <Route exact path={ROUTES.LIST}>
            <List />
          </Route>
          <Route exact path={ROUTES.HACKWORKS}>
            <HackworksPage />
          </Route>
          <Route exact path={ROUTES.SEARCH}>
            <Search fil={fil} role={role} />
          </Route>
          <Route exact path={ROUTES.PERSONNEL_DIRECTORY} children={<PersonnelDirectory />} />
          <Route exact path={ROUTES.CLUB_CARDS} children={<ClubСards />} />
          <Route exact path={ROUTES.CLIENTS}>
            <Clients fil={fil} role={role} />
          </Route>
          <Route exact path={ROUTES.CONTRACTS}>
            <Contracts />
          </Route>
          <Route exact path={ROUTES.CONTRACTS_INSURANCE}>
            <InsuranceContracts fil={fil} role={role} withInsurance />
          </Route>
          <Route exact path={ROUTES.PARTNERS}>
            <Partners fil={fil} role={role} />
          </Route>
          <Route exact path={ROUTES.HOTLINES_BY_CLIENTS}>
            <HotlinesByClients />
          </Route>
          <Route exact path={ROUTES.RATING}>
            <Rating />
          </Route>
          <Route exact path={ROUTES.PRAVO_RATING}>
            <PravoRating />
          </Route>
          <Route exact path={ROUTES.HELPER}>
            <Helper />
          </Route>
          <Route exact path={ROUTES.HELPER_CALC}>
            <Helper calc={true} />
          </Route>
          <Route exact path={ROUTES.HOTLINE_REACH}>
            <HotlineReach fil={fil} role={role} username={username} />
          </Route>
          <Route exact path={ROUTES.FAILURE_TO_PAY}>
            <FailureToPay fil={fil} role={role} />
          </Route>
          <Route exact path={ROUTES.HARVEST}>
            <Harvest />
          </Route>
          <Route exact path={ROUTES.FEE}>
            <FeeExploration />
          </Route>
          <Route exact path={ROUTES.ASSIGNMENT}>
            <Assignment />
          </Route>
          <Route exact path={ROUTES.ACTIVITY}>
            <Activity />
          </Route>
          <Route exact path={ROUTES.CABINETS}>
            <Cabinets />
          </Route>
          <Route exact path={ROUTES.JOURNAL}>
            <Journal />
          </Route>
          <Route exact path={ROUTES.JOURNAL + '/:fileName'}>
            <JournalItem />
          </Route>
          <Route exact path={ROUTES.USERS}>
            <Users />
          </Route>
          <Route exact path={ROUTES.USER_ADD}>
            <UserEdit add={true} />
          </Route>
          <Route exact path={ROUTES.USER_EDIT_ID}>
            <UserEdit />
          </Route>
          <Route exact path={ROUTES.SENDER}>
            <Sender />
          </Route>
          <Route exact path={ROUTES.BANK_CARDS}>
            <BankCards />
          </Route>
          <Route exact path={ROUTES.FILII}>
            <Filii />
          </Route>
          <Route exact path={ROUTES.LIBRARY}>
            <Library />
          </Route>
          <Route exact path={ROUTES.CORRESPONDENCE_PAGE}>
            <CorrespondencePage role={role} />
          </Route>
          <Route exact path={ROUTES.BLOCKED}>
            <Blocked />
          </Route>
          <Route exact path={ROUTES.STATISTIC_MEET_CANCELATION_REASONS}>
            <StatMeetCancelationReasons />
          </Route>
          <Route exact path={ROUTES.STATISTIC_CLIENTS_DEMOGRAPHY}>
            <StatClientsDemography />
          </Route>
          <Route exact path={ROUTES.STATISTIC_FIL_ACTIVE_CLIENTS_WITH_APP}>
            <StatFilActiveClientsWithApp />
          </Route>
          <Route exact path={ROUTES.STATISTIC_FIL_ACTIVE_CONTRACTS}>
            <StatFilActiveContracts />
          </Route>
          <Route exact path={ROUTES.STATISTIC_FIL_ARCHIVE_CANDY}>
            <StatFilArchiveCandy />
          </Route>
          <Route exact path={ROUTES.CHAT}>
            {!window?.ReactNativeWebView ? <Chat /> : <Redirect to={ROUTES.HOME_DASHBOARD} />}
          </Route>
          <Route exact path={ROUTES.PUBLISH_WEBSITE}>
            <PublishWebsite />
          </Route>
          <Route exact path={ROUTES.WEBSITE_NEWS}>
            <WebsiteNews />
          </Route>
          <Route exact path={ROUTES.CLIENTS_FEEDBACK}>
            <ClientsFeedback />
          </Route>
          <Route exact path={ROUTES.COMPLAINTS}>
            <Complaints />
          </Route>
          <Route exact path={ROUTES.EXPENSE}>
            <Expense />
          </Route>
          <Route exact path={ROUTES.TASKS}>
            <Tasks />
          </Route>
          <Route exact path={ROUTES.SUGAR}>
            <Sugar />
          </Route>
          <Route exact path={ROUTES.PUSH_NOTIFICATION}>
            <PushNotification />
          </Route>
          <Route exact path={ROUTES.TENDERS}>
            <Tenders />
          </Route>
          <Route exact path={ROUTES.INSURANCES}>
            <Insurance filia={fil} role={role} _id={_id} />
          </Route>
          <Route exact path={ROUTES.DEVELOPMENT}>
            <Development />
          </Route>
          <Route exact path={ROUTES.ACCESS_LIST}>
            <AccessList />
          </Route>
          <Route exact path={ROUTES.SUGGESTIONS}>
            <Suggestions />
          </Route>
          <Route exact path={ROUTES.MFO}>
            <Mfo />
          </Route>
          <Route exact path={ROUTES.CONFLICTS}>
            <Conflicts />
          </Route>
          <Route exact path={ROUTES.CONFLICT_CHAT}>
            <ConflictChat />
          </Route>
          <Route exact path={ROUTES.TWITTER}>
            <Twitter />
          </Route>
          <Route exact path={ROUTES.TWITTER_ID}>
            <TweetPage />
          </Route>
          <Route exact path={ROUTES.RED_BUTTON_CHAT}>
            <RedButtonChat />
          </Route>
          <Route exact path={ROUTES.DOCUMENTS}>
            <Documents />
          </Route>
          <Route exact path={ROUTES.WEBINARS}>
            <Webinars />
          </Route>
          <Route exact path={ROUTES.CLIENTS_QUICK_CHATS}>
            <ClientsQuickChats />
          </Route>
          <Route exact path={ROUTES.CLIENTS_DOZHIMS_AI_CHATS}>
            <ClientsDozhimsAIChats />
          </Route>
          <Route exact path={ROUTES.WALLET}>
            <Wallet />
          </Route>
          <Route exact path={ROUTES.WALLET_MONTH_FILIALS}>
            <WalletFilials />
          </Route>
          <Route exact path={ROUTES.FUNCTION_SURVEY}>
            <FunctionSurvey />
          </Route>
          <Route exact path={ROUTES.LATENESS}>
            <Lateness />
          </Route>
          <Route exact path={ROUTES.PAYMENT_MANAGEMENT_FILL_CHART}>
            <FilledContractPM />
          </Route>
          <Route exact path={ROUTES.FONDY_HOTLINES}>
            <FondyHotlines />
          </Route>
          <Route exact path={ROUTES.INSURANCE_TYPE}>
            <InsuranceType />
          </Route>
          <Route exact path={ROUTES.IT_SERVICES}>
            <ITServices />
          </Route>
          <Route exact path={ROUTES.DUBLICATES}>
            <Dublicates />
          </Route>
          <Route exact path={ROUTES.HOTLINE_CALLS}>
            <CallsHotline />
          </Route>
          <Route exact path={ROUTES.DOZHIMS_ANK}>
            <DozhimsANK fil={fil} role={role} username={username} />
          </Route>
        </>
      )}
    </Switch>
  );
}
