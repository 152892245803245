import { CloseOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Table } from 'antd';
import dayjs from 'dayjs';
import { useState } from 'react';

export default function CurrentCall({ remove, current, handleAddHotlineCallsReport, mob }) {
  const [confirmOpen, setConfirmOpen] = useState(false);

  const columnsCurrent = [
    ...(mob
      ? [
          {
            title: "Ім'я/Номер",
            dataIndex: 'client',
            key: 'client',
            render: (client, item) => {
              return (
                <>
                  {client?.n ? client?.n : <span>Невідомо</span>}
                  <br />
                  <a href={`tel:0${item.from}`}>{item.from}</a>
                </>
              );
            },
          },
        ]
      : [
          {
            title: "Ім'я",
            dataIndex: 'client',
            key: 'client',
            render: (client) => {
              return <>{client?.n ? client?.n : <span>Невідомо</span>}</>;
            },
          },
          {
            title: 'Номер телефону',
            dataIndex: 'from',
            key: 'from',
            render: (from, client) => (
              <>
                <a href={`tel:0${from}`}>
                  {client?.countryPhCode ?? '38'}0{from}
                </a>
              </>
            ),
          },
        ]),
    !mob
      ? {
          title: 'Тривалість',
          dataIndex: 'total_seconds',
          key: 'total_seconds',
          align: 'center',
          render: (total_seconds) => <>{total_seconds ?? '-'}</>,
        }
      : null,
    !mob
      ? {
          title: 'Час очікування',
          dataIndex: 'start_time',
          key: 'start_time',
          render: (start_time) => <>{dayjs(start_time).format('HH:mm') ?? '-'}</>,
        }
      : null,
    {
      title: 'Передзвонено',
      dataIndex: 'calledBack',
      key: 'calledBack',
      align: 'center',
      render: (calledBack, item) =>
        item?.answer_seconds !== '0' ? (
          !item?.status ? (
            <Button type="primary" onClick={(e) => handleAddHotlineCallsReport(e, item)} style={{ height: 'auto' }}>
              Звітувати{mob ? <br /> : ' '}НЕ запис
            </Button>
          ) : (
            <Button onClick={(e) => handleAddHotlineCallsReport(e, item)}>Змінити</Button>
          )
        ) : null,
    },
    {
      title: '',
      dataIndex: 'remove',
      key: 'remove',
      render: () => {
        return (
          <>
            <Button type="dashed" danger onClick={() => setConfirmOpen(true)}>
              <CloseOutlined />
            </Button>
          </>
        );
      },
    },
  ].filter((i) => i);

  if (!current) return null;

  return (
    <>
      <h3>Обранний Дзвінок</h3>
      <Popconfirm
        open={confirmOpen}
        title="Ви точно впевнені шо хочете відминити запис на цей дзвінок?"
        onCancel={() => setConfirmOpen(false)}
        onConfirm={() => {
          setConfirmOpen(false);
          remove(current);
        }}
      >
        <Table
          dataSource={current ? [current] : []}
          columns={columnsCurrent}
          rowKey={(item) => item._id}
          onRow={(record) => ({
            onClick: () => {
              setConfirmOpen(true);
            },
          })}
          pagination={false}
          className="active_calls"
        />
      </Popconfirm>
    </>
  );
}
