import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Spin, Select, Button, Input } from 'antd';
import dayjs from 'dayjs';
import { INSURANCE_PERIOD, PAY_PURPOSES, COUNTRY_PHONE_CODES } from 'common_constants/business';
import { request, error, warn, success } from '../../tools';

import { setModal, addInsurance } from '../../store/commonReducer';

import './styles.scss';
import phoneNumberValidator from 'common_components/src/tools/phoneNumberValidator';

const AddInsurance = () => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [phoneLoading, setPhoneLoading] = useState(false);
  const [user, setUser] = useState(false);

  const insuranceTypes = useSelector((state) => state.common.allInsuranceTypes);

  const [name, setName] = useState('');
  const [comment, setComment] = useState('');
  const [phone, setPhone] = useState('');
  const [countryPhCode, setCountryPhCode] = useState(COUNTRY_PHONE_CODES.UA);

  const [sugarNumber, setSugarNumber] = useState(null);
  const [insurancePeriod, setInsurancePeriod] = useState(6);
  const insuranceStartDate = dayjs().add(1, 'day').format('DD-MM-YYYY');
  const [insuranceEndDate, setInsuranceEndDate] = useState(dayjs().add(insurancePeriod, 'month').add(1, 'day').format('DD-MM-YYYY'));
  const [insuranceState, setInsuranceState] = useState({
    type: null,
    payment: 2,
  });
  const [insurancePrice, setInsurancePrice] = useState(null);

  const [insuranceType, setInsuranceType] = useState({});
  const [sugar, setSugar] = useState(null);

  const findInsuranceType = () => {
    const insuranceType = insuranceTypes.find((el) => el.i === insuranceState.type);
    setInsurancePrice(insuranceType.minimalPrice[insurancePeriod]);
    return insuranceType;
  };

  const handleClick = async () => {
    if (insuranceState.type === null) return warn('Додавання страховки', 'Потрібно обрати тип страховки');
    if (sugar === null) return warn('Додавання страховки', 'Потрібно обрати сахарок');
    if (sugar.amount < insurancePrice)
      return warn(
        'Додавання страховки',
        `Сума сахарку (${sugar.amount}) повинна буті більшою чи дорівнювати мінімальному платежу за період (${insurancePrice})`,
      );

    setLoading(true);
    const reqBody = {
      ...insuranceState,
      phone,
      countryPhCode: countryPhCode,
      comment: comment,
      period: insurancePeriod,
      sugarNumber: sugarNumber,
      name,
    };

    await request.post(
      '/insurance/addInsurance',
      reqBody,
      ({ insurance }) => {
        dispatch(addInsurance(insurance));
        setLoading(false);
        setInsuranceState({ ...insuranceState, comment: '' });
        setComment('');
        setPhone('');
        setSugarNumber(null);
        setSugar(null);
        setName('');
        success('', 'Страховка успішно додана');
        dispatch(setModal());
      },
      () => {
        setLoading(false);
        error();
      },
    );
  };

  const getNameByPhone = async () => {
    setPhoneLoading(true);
    const body = { phone, countryPhCode };

    await request.post(
      '/hotline/getNameByPhone',
      body,
      ({ data }) => {
        setName(data.name);
        setUser(true);
      },
      error,
    );

    setPhoneLoading(false);
  };

  const getSugarByNumber = async () => {
    setLoading(true);
    const body = { number: sugarNumber };

    await request.post(
      '/sugar/getSugarForInsuranceByNumber',
      body,
      ({ sugar }) => {
        if (sugar === null) {
          setSugar(null);
          warn('', 'Такого сахарка не існує чи він вже використан для іншої страховки');
        } else {
          setSugar(sugar);
        }
      },
      error,
    );

    setLoading(false);
  };

  useEffect(() => {
    if (insuranceState.type !== null) {
      setInsuranceType(findInsuranceType());
    }
  }, [insuranceState.type]);

  const handlePhoneNumberInput = (event) => {
    const validPhoneNumber = phoneNumberValidator(event);

    setPhone(validPhoneNumber);
  };

  return (
    <Modal className="modalAddInsurance" title={'Додати страховку'} open onCancel={() => dispatch(setModal())} footer={null}>
      <Spin spinning={loading}>
        <div className="row">
          <div className="box phone">
            <div className="countryPhCode-wrapper">
              <h4>Код країни </h4>
              <Select
                value={countryPhCode}
                style={{ width: 80 }}
                onChange={(value) => {
                  setCountryPhCode(value);
                }}
              >
                <Select.Option value={COUNTRY_PHONE_CODES.UA}>+{COUNTRY_PHONE_CODES.UA}</Select.Option>
                <Select.Option value={COUNTRY_PHONE_CODES.DE}>+{COUNTRY_PHONE_CODES.DE}</Select.Option>
                <Select.Option value={COUNTRY_PHONE_CODES.CZ}>+{COUNTRY_PHONE_CODES.CZ}</Select.Option>
              </Select>
            </div>

            <div className="countryPh-wrapper">
              <h4>Номер телефону </h4>
              <Input
                style={{ width: 150 }}
                suffix={<Spin spinning={phoneLoading} />}
                loading={true}
                placeholder="Телефон"
                value={phone}
                onChange={(e) => {
                  handlePhoneNumberInput(e);
                }}
                onBlur={() => {
                  if (phone !== '') getNameByPhone();
                }}
              />
            </div>
          </div>
          <div className="box sugarNumber">
            <h4>Номер сахарка</h4>
            <div className="row">
              <Input
                value={sugarNumber}
                onChange={(e) => {
                  setSugarNumber(Number(e.target.value));
                }}
                onBlur={() => {
                  if (sugarNumber !== null) getSugarByNumber();
                }}
              />
              {sugar?.i && <span>{PAY_PURPOSES[sugar.payfor]?.label}</span>}
            </div>
          </div>
          <div className="box full">
            <h4>ПІБ Клієнта</h4>
            <Input
              value={name}
              placeholder="Введіть ПІБ"
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
          </div>
          <div className="box">
            <h4>Період</h4>
            <Select
              value={insurancePeriod}
              style={{
                width: 120,
              }}
              onChange={(value) => {
                setInsurancePeriod(value);
                setInsuranceEndDate(dayjs(insuranceStartDate, 'DD-MM-YYYY').add(value, 'month').format('DD-MM-YYYY'));
                setInsurancePrice(insuranceType?.minimalPrice?.[value]);
              }}
              options={Object.keys(INSURANCE_PERIOD).map((e) => ({
                value: Number(e),
                label: INSURANCE_PERIOD[e],
              }))}
            />
          </div>
          <div className="box">
            <h5>Дата початку</h5>
            {insuranceStartDate}
            <h5>Дата закінчення</h5>
            {insuranceEndDate}
            <h5>Сума сахарка</h5>
            {sugar ? sugar.amount : 'Немає сахарка'}
          </div>
          <div className="box"></div>
          <div className="box full">
            <h4>Тип страховки</h4>
            <Select
              onChange={(value) => setInsuranceState({ ...insuranceState, type: Number(value) })}
              options={insuranceTypes.map((key) => ({
                value: key.i,
                label: key.name,
              }))}
            />
          </div>
          <div className="box full">
            <h4>Коментар</h4>
            <Input value={comment} onChange={(e) => setComment(e.target.value)} />
          </div>
        </div>
        <Button type="primary" className="button" onClick={handleClick} disabled={!name || !user}>
          Додати страховку
        </Button>
      </Spin>
    </Modal>
  );
};

export default AddInsurance;
