import { useDispatch, useSelector } from 'react-redux';
import { Modal, Form, Button } from 'antd';
import { CloseOutlined, FileAddOutlined, CheckOutlined } from '@ant-design/icons';
import { lime, orange, volcano, yellow } from '@ant-design/colors';

import Box from '../../components/Box';
import { setModal, setCurrentPsychologistHotline } from '../../store/commonReducer';
import { setMainLoader } from '../../store/uiReducer';

import { error, info, request, success } from '../../tools';
import './style.scss';

const PsychologistHotlineReporting = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { _id, date, toDate } = useSelector((state) => state.common.modal);

  const handleUserBlock = (result) => {
    dispatch(setMainLoader(true));
    request.post(
      '/psychologistHotline/reporting',
      { _id: _id, result: result },
      () => {
        success();
        updateHotlineList();
        dispatch(setMainLoader(false));
      },
      error,
    );
    dispatch(setModal());
  };
  const updateHotlineList = async () => {
    const requestData = {
      startDate: date,
      endDate: toDate,
    };

    await request.post(
      '/psychologistHotline/get',
      requestData,
      (req) => {
        dispatch(setCurrentPsychologistHotline(req.data));
      },
      (error) => {
        console.error('Error in request:', error);
      },
    );
  };

  const onCancel = () => {
    dispatch(setModal());
    info('', 'Ви не згодні зі своїм рішенням.');
  };

  return (
    <Modal open className="block-add-user" title={'Звітування по консультації з психологом'} onCancel={onCancel} footer={null}>
      <Form className="block-form" layout="vertical" onFinish={() => handleUserBlock(true)} form={form}>
        <Box className="group-button">
          <Button className="group-button__btn" type="primary" style={{ backgroundColor: lime.primary, borderColor: lime.primary }} htmlType="submit">
            <CheckOutlined /> Консультація пройшла успішно
          </Button>
          <Button className="group-button__btn" type="primary" danger onClick={() => handleUserBlock(false)}>
            <CloseOutlined />
            Консультація не відбулася
          </Button>
        </Box>
      </Form>
    </Modal>
  );
};
export default PsychologistHotlineReporting;
