import { Modal, Spin } from 'antd';

import { CURRENCIES, HOTLINE_ADV_WHERE, HOTLINE_ADV_WHERE_MORE, POSLUGI, PRAVO, ROLES, SALES_TYPE } from 'common_constants/business';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { setModal } from '../../store/commonReducer';
import { setMainLoader } from '../../store/uiReducer';
import { error, request } from '../../tools';
import { CheckUserInfo } from '../../tools/hooks';
import dayjs from 'dayjs';

const HotlineSubmit = () => {
  const user = useSelector((state) => state.common.userAuth || {});
  const data = useSelector((state) => state.common.modal.data);
  const filii = useSelector((state) => state.common.filii);
  const showData = (hotline) => filii.find((item) => item.i === +hotline?.fil);
  const workerOnly = ROLES[user.role] === ROLES.worker;

  const [dispatch, history] = [useDispatch(), useHistory()];
  const { _id, hotlineIdFromWallet } = data;

  const [loading, setLoading] = useState(false);
  const [hotlineData, setHotlineData] = useState(null);

  const onCancel = () => {
    dispatch(setModal({ name: '', data: data }));
  };

  const onSave = () => {
    dispatch(setMainLoader(true));
    const requestData = {
      ...data,
      date: data.date.toISOString(),
      _id,
      sale: data?.sale?._id,
      crAt: data.crAt ?? null,
    };

    const onError = (_, __, { response }) => {
      const hotlineError = response?.data?.error;
      if (hotlineError) {
        error('Помилка!', hotlineError);
      }
      dispatch(setMainLoader(false));
    };

    data.byClient
      ? request.post('/hotline/updateClientHotline', requestData, saveCallback, onError)
      : request.post('/hotline/addNewHotline', requestData, saveCallback, onError);
  };

  const saveCallback = () => {
    dispatch(setMainLoader(false));
    dispatch(setModal());
    // if (!workerOnly) CheckUserInfo(dispatch);
    history.push('/hotlineSaved');
  };

  const handleSubmit = () => {
    onSave();
  };

  const getHotline = async () => {
    setLoading(true);

    await request.post('/hotline/getById', { id: hotlineIdFromWallet }, ({ hotline }) => setHotlineData(hotline), error);

    setLoading(false);
  };

  useEffect(() => {
    if (hotlineIdFromWallet) getHotline();
  }, []);

  return (
    <Modal open onCancel={onCancel} onOk={handleSubmit} okButtonProps={{ disabled: hotlineIdFromWallet }}>
      <Spin tip="Завантаження" spinning={loading}>
        <h2>{hotlineIdFromWallet ? 'Інформація про консультацію' : 'Перевір перед збереженням:'}</h2>
        <b>Філія:</b> {showData(data)?.name ?? showData(hotlineData)?.name ?? '-'}
        <br />
        <b>Послуга:</b> {POSLUGI[data?.type] ?? POSLUGI[hotlineData?.type] ?? '-'}
        <br />
        <b>Дата:</b> {data?.date?.format('DD.MM.YYYY') ?? dayjs(hotlineData?.date)?.format('DD.MM.YYYY') ?? '-'}
        <br />
        <b>Час:</b> {data?.startT ?? hotlineData?.startT} - {data?.endT ?? hotlineData?.endT}
        <br />
        <b>Ім'я:</b> {data?.name ?? hotlineData?.name ?? '-'}
        <br />
        <b>Прізвище:</b> {data?.surname ?? hotlineData?.surname ?? '-'}
        <br />
        <b>Телефон:</b> {data?.phone ?? hotlineData?.phone ?? '-'}
        <br />
        <b>Вид консультації:</b> {PRAVO[data?.pravo] ?? PRAVO[hotlineData?.pravo] ?? '-'}
        <br />
        <b>Ціна консультації:</b> {CURRENCIES[data?.currency]?.symbol ?? CURRENCIES[hotlineData?.currency]?.symbol ?? '-'}
        {data?.cost ?? hotlineData?.cost ?? '-'}
        <br />
        {(data?.type !== '7' || hotlineData?.type !== '7') && (
          <>
            <b>Категорія анкетування:</b> {HOTLINE_ADV_WHERE[data?.aw] ?? HOTLINE_ADV_WHERE[hotlineData?.aw] ?? '-'}
            <br />
            <b>Що саме:</b> {HOTLINE_ADV_WHERE_MORE[data?.am] ?? HOTLINE_ADV_WHERE_MORE[hotlineData?.am] ?? '-'}
            <br />
          </>
        )}
        <b>Коментар:</b> {data?.comment ?? hotlineData?.comment ?? '-'}
        <br />
        {!!data?.call_id && (
          <>
            <b>Дзвінок прив'язанний:</b> так <br />
          </>
        )}
        {data.sale && (
          <>
            <b>Використанні знижки:</b> -{data.sale.saleAmount * 100}% {SALES_TYPE[data.sale?.saleType]?.label}
          </>
        )}
        <br />
      </Spin>
    </Modal>
  );
};
export default HotlineSubmit;
