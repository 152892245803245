import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Spin, Modal, DatePicker, Input } from 'antd';
import {
  setModal,
  setCurrentReachList,
  setCurrentReachBellStatus,
  setCurrentReachAnkList,
  setCurrentReachBellStatusAnk,
} from '../../store/commonReducer';
import { request, error, success, warn } from '../../tools';

import Box from '../../components/Box';
import './styles.scss';

import dayjs from 'dayjs';
import 'dayjs/locale/uk';
import locale from 'antd/locale/uk_UA';

const BellToReach = () => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.common.reqLoader);
  const data = useSelector((state) => state.common.modal.data);
  const ankLabel = data?.ankLabel;
  const now = dayjs().set('hour', 3).set('minute', 0).set('second', 0); // * sort to 02:30:00 GMT+0300
  const list = useSelector((state) => state.common.currentReachList);
  const listAnk = useSelector((state) => state.common.currentReachAnkList);

  const [date, setDate] = useState(dayjs(data?.rrh?.bel) ?? dayjs());
  const [text, setText] = useState(data?.rrh?.nel ?? '');

  const [dateAnk, setDateAnk] = useState(dayjs(data?.rrh?.belAnk) ?? dayjs());
  const [textAnk, setTextAnk] = useState(data?.rrh?.nelAnk ?? '');

  const { TextArea } = Input;

  const onCancel = () => {
    dispatch(setModal());
  };

  const onDateChange = (value) => {
    setDate(value);
  };

  const onDateChangeAnk = (value) => {
    setDateAnk(value);
  };

  const onSubmit = () => {
    const validation = (_id, bel, nel) => {
      if (!nel) {
        warn('', 'Для додавання нагадування, потрібно його описати.', { nel: !!nel });
        return;
      }

      if (!bel) {
        warn('', 'Для додавання нагадування, потрібно вказати дату початку його дії.', { bel: bel });
        return;
      }

      if (!_id) {
        warn('', 'Для додавання нагадування, потрібно вказати чинного клієнта.', { 'data._id': !!data._id });
        return;
      }

      return true;
    };

    if (!validation(data._id, date, text)) return;

    const transaction = {
      _id: data._id,
      bel: date,
      nel: text,
    };

    request.post(
      '/hotline/saveBellToReach',
      transaction,
      () => {
        dispatch(
          setCurrentReachList(
            list.map((i) =>
              i._id === data._id
                ? {
                    ...i,
                    rrh: { ...i.rrh, bel: date, nel: text },
                  }
                : i,
            ),
          ),
        );
        dispatch(setCurrentReachBellStatus({ bellId: data._id, bellDate: date }));
        success();
        dispatch(setModal());
      },
      error,
    );
  };

  const onSubmitAnk = () => {
    const validation = (_id, belAnk, nelAnk) => {
      if (!nelAnk) {
        warn('', 'Для додавання нагадування, потрібно його описати.', { nelAnk: !!nelAnk });
        return;
      }

      if (!belAnk) {
        warn('', 'Для додавання нагадування, потрібно вказати дату початку його дії.', { belAnk: belAnk });
        return;
      }

      if (!_id) {
        warn('', 'Для додавання нагадування, потрібно вказати чинного клієнта.', { 'data._id': !!data._id });
        return;
      }

      return true;
    };

    if (!validation(data._id, dateAnk, textAnk)) return;

    const transaction = {
      _id: data._id,
      belAnk: dateAnk,
      nelAnk: textAnk,
    };

    request.post(
      '/hotline/saveBellToReachAnk',
      transaction,
      () => {
        dispatch(
          setCurrentReachAnkList(
            listAnk.map((i) =>
              i._id === data._id
                ? {
                    ...i,
                    rrh: { ...i.rrh, belAnk: dateAnk, nelAnk: textAnk },
                  }
                : i,
            ),
          ),
        );
        dispatch(setCurrentReachBellStatusAnk({ bellId: data._id, bellDateAnk: dateAnk }));
        success();
        dispatch(setModal());
      },
      error,
    );
  };

  return (
    <Modal open className="bell-to-reach" title={'Запускаємо собі нагадування по дожиму.'} onCancel={onCancel} footer={null}>
      <Spin tip="Завантаження" spinning={loading}>
        {ankLabel ? (
          <>
            <Box mt={12}>
              Обрати дату запуску нагадування: <br />
              <DatePicker
                disabledDate={(date) => date.unix() < now.unix()}
                locale={locale}
                defaultValue={dayjs(dateAnk, 'DD/MM/YYYY')}
                fullscreen={false}
                format={'DD/MM/YYYY'}
                onSelect={onDateChangeAnk}
              />
            </Box>
            <Box mt={12}>
              Опишіть саме нагадування: <br />
              <TextArea rows={10} value={textAnk} onChange={(e) => setTextAnk(e.target.value)} />
            </Box>
            <Box mt={12} className="group-button-bell">
              <Button type="primary" onClick={onSubmitAnk} className="bell-reach-btn">
                Запустити
              </Button>
              <Button danger type="primary" className="bell-reach-btn" onClick={onCancel}>
                Скасувати
              </Button>
            </Box>{' '}
          </>
        ) : (
          <>
            <Box mt={12}>
              Обрати дату запуску нагадування: <br />
              <DatePicker
                disabledDate={(date) => date.unix() < now.unix()}
                locale={locale}
                defaultValue={dayjs(date, 'DD/MM/YYYY')}
                fullscreen={false}
                format={'DD/MM/YYYY'}
                onSelect={onDateChange}
              />
            </Box>
            <Box mt={12}>
              Опишіть саме нагадування: <br />
              <TextArea rows={10} value={text} onChange={(e) => setText(e.target.value)} />
            </Box>
            <Box mt={12} className="group-button-bell">
              <Button type="primary" onClick={onSubmit} className="bell-reach-btn">
                Запустити
              </Button>
              <Button danger type="primary" className="bell-reach-btn" onClick={onCancel}>
                Скасувати
              </Button>
            </Box>
          </>
        )}
      </Spin>
    </Modal>
  );
};

export default BellToReach;
