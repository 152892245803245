import React, { useState, useEffect, useRef, useMemo } from 'react';
import { CHAT_TYPES } from 'common_constants/business';
import { DeleteOutlined, EditOutlined, DownloadOutlined } from '@ant-design/icons';
import { List, AutoSizer, CellMeasurer } from 'react-virtualized';

import { request, error } from '../../tools';
import ContextMenu from '../ContextMenu';
import Message from './Message';

const MessagesList = ({
  chatId,
  chatType,
  messages,
  messagesCount,
  heigthFull,
  inputRef,
  setMessagesLoader,
  onGetFile,
  editMode,
  cache,
  handleEditMode,
  isMoreMessagesFetching,
  setIsMoreMessagesFetching,
  prevMessagesCount,
}) => {
  const messagesWrapperRef = useRef(null);
  const contextMenuRef = useRef(null);
  const listRef = useRef();

  const [isFirstScroll, setIsFirstScroll] = useState(true);
  const [contextMenu, setContextMenu] = useState({
    position: {
      x: 0,
      y: 0,
    },
    toggled: false,
    messageData: {},
  });

  const rowCount = useMemo(() => (messagesCount > messages.length ? messages.length + 1 : messages.length), [messages]);
  const hasNext = useMemo(() => messagesCount > messages.length, [messagesCount, messages]);

  const isLawyerChat = chatType === CHAT_TYPES?.lawyerChat.key;

  const deleteMessage = (item) => {
    if (!item._id) {
      error('На жаль, дане повідомлення не можна видалити');
    }

    const requestData = {
      chatId,
      type: chatType,
      messageId: item._id,
    };
    request.post(
      '/chatPrivate/deleteMessage',
      requestData,
      () => {
        resetContextMenu();
      },
      error,
    );
  };

  const resetContextMenu = () => {
    setContextMenu({
      position: {
        x: 0,
        y: 0,
      },
      toggled: false,
    });
  };

  const handleDownloadFile = (fileId, fileName) => {
    if (!fileId) return;
    setMessagesLoader(true);
    onGetFile({ fileId, fileName, callback: () => setMessagesLoader(false) });
  };

  const handleListScroll = (e) => {
    if (!hasNext || !isLawyerChat) return;

    if (e.scrollTop < 150 && !isMoreMessagesFetching && !isFirstScroll) {
      setIsMoreMessagesFetching(true);
    }

    setIsFirstScroll(false);
  };

  const handleOnContextMenu = (e, item) => {
    e.preventDefault();

    const contextMenuArr = contextMenuRef.current.getBoundingClientRect();

    const isLeft = e.clientX < window?.clientX / 2;

    let x;
    let y = e.clientY;

    if (isLeft) {
      x = e.clientX;
    } else {
      x = e.clientX - contextMenuArr?.width;
    }

    setContextMenu({
      position: {
        x: x,
        y: y,
      },
      toggled: true,
      messageData: item,
    });
  };

  const onClickHandleEditMode = (item) => {
    handleEditMode(item);
    resetContextMenu();
  };

  useEffect(() => {
    function handler(e) {
      if (contextMenuRef.current) {
        if (!contextMenuRef.current.contains(e.target)) {
          resetContextMenu();
        }
      }
    }

    document.addEventListener('click', handler);

    return () => {
      document.removeEventListener('click', handler);
    };
  }, []);

  useEffect(() => {
    if (messagesWrapperRef?.current) {
      messagesWrapperRef.current.style.height = heigthFull ? window.innerHeight - 100 - inputRef?.current?.clientHeight + 'px' : '400px';
    }
  }, [heigthFull, editMode.isEditing]);

  return (
    <div ref={messagesWrapperRef} className="messages-wrapper" style={{ width: '100%', height: '400px' }}>
      <AutoSizer>
        {({ width, height }) => (
          <List
            width={width}
            height={height}
            rowHeight={cache.rowHeight}
            deferredMeasurementCache={cache}
            rowCount={rowCount ?? 1}
            overscanRowCount={0}
            ref={(list) => {
              listRef.current = list;
            }}
            scrollToIndex={messages?.length - 1 - prevMessagesCount}
            onScroll={handleListScroll}
            rowRenderer={({ key, index, style, parent }) => {
              let message = null;

              if (hasNext) {
                message = messages[index - 1];
              } else {
                message = messages[index];
              }

              return (
                <CellMeasurer key={key} cache={cache} parent={parent} columnIndex={0} rowIndex={index}>
                  <div onContextMenu={(e) => handleOnContextMenu(e, message)} style={style}>
                    <Message item={message} onDownload={() => handleDownloadFile(message.fileId, message.fileName)} />
                  </div>
                </CellMeasurer>
              );
            }}
          />
        )}
      </AutoSizer>
      <ContextMenu
        contextMenuRef={contextMenuRef}
        positionX={contextMenu.position.x}
        positionY={contextMenu.position.y}
        isToggled={contextMenu.toggled}
        rightClickItem={contextMenu.messageData}
        buttons={[
          ...(contextMenu.messageData?.fileId
            ? [
                {
                  text: 'Завантажити',
                  icon: DownloadOutlined,
                  onClick: (e, item) => handleDownloadFile(item.fileId, item.fileName ?? 'Файл'),
                },
              ]
            : []),
          ...(contextMenu.messageData?.isSentByCurrentUser && contextMenu.messageData?.message
            ? [
                {
                  text: 'Редагувати',
                  icon: EditOutlined,
                  onClick: (e, item) => onClickHandleEditMode(item),
                },
              ]
            : []),
          ...(contextMenu.messageData?.isSentByCurrentUser
            ? [
                {
                  text: 'Видалити',
                  icon: DeleteOutlined,
                  onClick: (e, item) => deleteMessage(item),
                },
              ]
            : []),
        ]}
      />
    </div>
  );
};

export default MessagesList;
