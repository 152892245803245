import { Button, Input, Table } from 'antd';
import dayjs from 'dayjs';
import { getClientColor } from '../../tools';

export const formatSecondsTime = (seconds) => {
  let minutes = String(Math.floor(seconds / 60)) ?? '00';
  if (minutes.length === 1) minutes = '0' + minutes;

  let secondsFormated = String(seconds % 60) ?? '00';
  if (secondsFormated.length === 1) secondsFormated = '0' + secondsFormated;

  return `${minutes}:${secondsFormated}`;
};

export const generateCallColumns = (mob, handleChangeClientName, handleClientBtnClick, handleAddHotlineCallsReport) =>
  mob
    ? [
        Table.EXPAND_COLUMN,
        {
          title: 'Дата',
          dataIndex: 'start_time',
          key: 'start_time',
          render: (start_time) => dayjs(start_time).format('DD.MM.YYYY HH:mm'),
        },
        {
          title: 'З номеру',
          dataIndex: 'from',
          key: 'from',
          render: (from) => <a href={`tel:0${from}`}>{from}</a> ?? '-',
        },
        {
          title: 'Клієнт',
          dataIndex: 'client',
          key: 'client',
          render: (client, item) => {
            const { color, background } = getClientColor(client);

            return (
              <Button
                className="client-btn"
                size="small"
                shape="round"
                style={{ color, background }}
                onClick={(e) => handleClientBtnClick(e, client)}
              >
                {client?.n ?? '-'}
              </Button>
            );
          },
        },
        {
          title: 'Передзвонено',
          dataIndex: 'calledBack',
          key: 'calledBack',
          align: 'center',
          render: (calledBack, item) =>
            item?.answer_seconds === '0' ? (
              !item?.status ? (
                <Button type="primary" onClick={(e) => handleAddHotlineCallsReport(e, item)}>
                  Звітувати
                </Button>
              ) : (
                <Button onClick={(e) => handleAddHotlineCallsReport(e, item)}>Змінити</Button>
              )
            ) : null,
        },
      ]
    : [
        Table.EXPAND_COLUMN,
        {
          title: 'Дата',
          dataIndex: 'start_time',
          key: 'start_time',
          render: (start_time) => dayjs(start_time).format('DD.MM.YYYY HH:mm'),
        },
        {
          title: 'З номеру',
          dataIndex: 'from',
          key: 'from',
          render: (from) => <a href={`tel:0${from}`}>{from}</a> ?? '-',
        },
        {
          title: 'Клієнт',
          dataIndex: 'client',
          key: 'client',
          render: (client, item) => {
            const { color, background } = getClientColor(client);

            return (
              <Button
                className="client-btn"
                size="small"
                shape="round"
                style={{ color, background }}
                onClick={(e) => handleClientBtnClick(e, client)}
              >
                {client?.n ?? '-'}
              </Button>
            );
          },
        },
        {
          title: 'Тривалість',
          dataIndex: 'answer_seconds',
          key: 'answer_seconds',
          align: 'center',
          render: (answer_seconds) => formatSecondsTime(answer_seconds) ?? '-',
        },
        {
          title: 'Передзвонено',
          dataIndex: 'calledBack',
          key: 'calledBack',
          align: 'center',
          render: (calledBack, item) =>
            item?.answer_seconds === '0' ? (
              !item?.status ? (
                <Button type="primary" onClick={(e) => handleAddHotlineCallsReport(e, item)}>
                  Звітувати
                </Button>
              ) : (
                <Button onClick={(e) => handleAddHotlineCallsReport(e, item)}>Змінити</Button>
              )
            ) : null,
        },
      ];
