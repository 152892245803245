import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Card, Button, Spin, Modal } from 'antd';
import { CSVLink } from 'react-csv';

import { setModal } from '../../store/commonReducer';
import { request, error } from '../../tools';
import Excel from '../../images/excel_logo.svg';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';

const csvDataTitles = ['№', 'Телефон', 'Тривалість', 'Дата звінка', 'Час звінка', `Ім'я`, 'Філія', 'Місто', 'Баланс', 'Імейл', 'Адреса'];

const ExportHotlineCalls = () => {
  const dispatch = useDispatch();
  const filii = useSelector((state) => state.common.filii);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([csvDataTitles]);
  const [generate, setGenerate] = useState(true);

  const onGenerate = async () => {
    setLoading(true);
    await request.post(
      '/spreadsheets/exportHotlineCalls',
      {},
      (res) => {
        setLoading(false);
        const cvsData = [csvDataTitles];
        res.data?.forEach((call, index) => {
          const d = dayjs(call.T);
          const client = call.client;
          console.log(client.location);
          cvsData.push([
            index + 1,
            `+${client.countryPhCode}${client.p}`,
            call.total_seconds,
            d.format('DD/MM/YYYY'),
            d.format('HH:mm:ss'),
            client.n,
            filii.find((f) => !!call.to && f?.hotlinePhones?.includes(call.to))?.name,
            client.location?.city,
            client?.clientBalance.toString(),
            client?.email,
            client?.address,
          ]);
        });
        setData(cvsData);
        setGenerate(false);
      },
      error,
    );
  };

  return (
    <Modal open className="export-hotline" title="Експорт дзвінків" onCancel={() => dispatch(setModal())} footer={null}>
      <Spin spinning={loading}>
        <Card className="change">
          <br />
          {generate ? (
            <Button onClick={onGenerate}>Генерувати</Button>
          ) : (
            <CSVLink filename={'hotline-calls.csv'} asyncOnClick={true} data={data}>
              <Button className="excel">
                <img className="excel-img" src={Excel} alt="Excel" /> Завантажити hotline-calls.csv
              </Button>
            </CSVLink>
          )}
        </Card>
      </Spin>
    </Modal>
  );
};

export default ExportHotlineCalls;
