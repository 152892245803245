import React from 'react';
import { Col, List, Card, Row, DatePicker, Spin, Pagination } from 'antd';
import SugarItem from './SugarItem';
import { pageSizeOptions } from '../../Helpers';

import './Sugar.scss';

const Sugar = ({ type, state, handleSetApproved, pageSize, onChangePageSize, handleDateChange, onChangePage }) => {
  const onShowSizeChange = (current, pageSize) => {
    onChangePageSize(current, pageSize);
  };
  const totalAmountLabel = state.sugarTotalAmount.toLocaleString('uk-UA');
  return (
    <Spin spinning={state.sugarLoading} tip="Завантаження...">
      <Col className="walletDetails">
        <Row justify={'space-between'} align={'middle'} style={{ margin: '0 0 16px 0' }}>
          <Row>
            <DatePicker placeholder={'місяць'} picker="month" onChange={handleDateChange} value={state.sugarSelectedDate} />
          </Row>
          <Row>{totalAmountLabel} грн.</Row>
        </Row>
        <Card className="sugarInner">
          <Row className="item" justify={'space-between'} align={'middle'}>
            <Col className="fields-inner" flex={'auto'}>
              <Row className="fields">
                <Row className="title">Дата</Row>

                <Row className="title desktop">Сума</Row>

                <Row className="title desktop">Відсоток</Row>

                <Row className="title desktop">Клієнт</Row>

                <Row className="title desktop">Спосіб оплати</Row>

                <Row className="title desktop">Перевірено</Row>
                <Row className="title mobile">Інфо</Row>
              </Row>
            </Col>
          </Row>
        </Card>
        <List
          itemLayout="vertical"
          size="large"
          dataSource={state.sugarData}
          renderItem={(sugar) => <SugarItem sugar={sugar} handleSetApproved={handleSetApproved} />}
        />
        <Row>
          <Pagination
            showSizeChanger
            onShowSizeChange={onShowSizeChange}
            total={state.sugarTotalRecords}
            onChange={onChangePage}
            pageSize={pageSize}
            current={state.sugarCurrentPage}
            pageSizeOptions={pageSizeOptions}
          />
        </Row>
      </Col>
    </Spin>
  );
};

export default React.memo(Sugar);
