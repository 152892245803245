import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { DATA_PAGE_SIZE } from 'common_constants/db';
import { CLIENT_INFO, HOTLINE_CALLS_FILTER, ADD_HOTLINE_CALLS_REPORT } from 'common_constants/modals';
import { Tabs, Row, Button, Table } from 'antd';
import { FilterOutlined } from '@ant-design/icons';
import clsx from 'clsx';

import { setHotlineCalls, setModal, updateHotlineCall, clearHotlineCallsFilter } from '../../store/commonReducer';
import { request, error } from '../../tools';
import TimeLineForHotlineCalls from '../../components/TimeLineForHotlineCalls';
import { generateCallColumns } from './helpers';
import ExportHotlineCalls from './ExportHotlineCalls';

import HotlineCallInfo from './HotlineCallInfo';

import './HotlineCalls.scss';
import { ROLES } from 'common_constants/business';

const INTERVALS = [1, 13, 25, 37, 49];

const DEFAULT_PAGE_SIZE = DATA_PAGE_SIZE;
const AUTOUPDATE_DELAY = 12000;

const CallsHotline = () => {
  const dispatch = useDispatch();
  const hotlineCalls = useSelector((state) => state.common.hotlineCalls);
  const hotlineCallsFilter = useSelector((state) => state.common.hotlineCallsFilter);
  const modal = useSelector((state) => state.common.modal);
  const mob = useSelector((state) => state.ui.mob);
  const client = useSelector((state) => state.common.userAuth);
  const filii = useSelector((state) => state.common.filii);
  const allowedPhones = filii.find((el) => el.i == client.fil)?.hotlinePhones ?? [];

  const [activeTab, setActiveTab] = useState('missed3Days');
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const tabItems = [
    {
      key: 'activeCalls',
      label: 'Активні дзвінки',
    },
    {
      key: 'missed3Days',
      label: 'Пропущені за 3 днів',
    },
    {
      key: 'all',
      label: 'Всі',
    },
    {
      key: 'answered3Days',
      label: 'Прийняті за 3 днів',
    },
  ];

  if (ROLES[client?.role] > 4) {
    tabItems.splice(3, 1);
    if (client?.role !== 'manager') {
      tabItems.splice(2, 1);
    }
    tabItems.splice(0, 1);
  }

  const isFilterSelected = useMemo(
    () =>
      hotlineCallsFilter?.date ||
      hotlineCallsFilter?.from ||
      hotlineCallsFilter?.to ||
      hotlineCallsFilter?.callback?.length <= 1 ||
      hotlineCallsFilter?.isMissed?.length <= 1,
    [hotlineCallsFilter],
  );

  const expandable = {
    expandRowByClick: true,
    expandedRowRender: (item) => <HotlineCallInfo key={item.id} data={item} setLoading={setLoading} />,
  };

  // const handleChangeCalledBackCheckbox = (val, item) => {
  //   setLoading(true);

  //   const reqBody = {
  //     _id: item._id,
  //     calledBack: val,
  //   };

  //   request.post(
  //     '/hotlineCalls/update',
  //     reqBody,
  //     () => {
  //       setLoading(false);
  //       dispatch(updateHotlineCall({ ...reqBody }));
  //     },
  //     () => {
  //       setLoading(false);
  //       error();
  //     },
  //   );
  // };

  const handleAddHotlineCallsReport = (e, item) => {
    e.stopPropagation();
    dispatch(setModal({ name: ADD_HOTLINE_CALLS_REPORT, data: item }));
  };

  const handleChangeClientName = (e, item) => {
    dispatch(updateHotlineCall({ _id: item._id, clientName: e.target.value }));
  };

  const handleClientBtnClick = (e, data) => {
    e.stopPropagation();
    dispatch(setModal({ name: CLIENT_INFO, data }));
  };

  const handleTabChange = (key) => {
    setActiveTab(key);
    setCurrentPage(1);
  };

  const columns = generateCallColumns(mob, handleChangeClientName, handleClientBtnClick, handleAddHotlineCallsReport);

  const handleTableChange = (pagination) => setCurrentPage(pagination.current);

  const handleClickFilter = () => dispatch(setModal({ name: HOTLINE_CALLS_FILTER }));

  const handleClearFilter = () => dispatch(clearHotlineCallsFilter());

  const getHotlineCalls = () => {
    setLoading(true);

    const reqBody = {
      type: activeTab,
      pageSize: DEFAULT_PAGE_SIZE,
      page: currentPage,
      ...hotlineCallsFilter,
      fil: client.contactCenter ? undefined : client.fil,
    };

    request.post(
      '/hotlineCalls/get',
      reqBody,
      ({ data, total }) => {
        dispatch(setHotlineCalls(data));
        setTotal(total);
        setLoading(false);
      },
      () => {
        error();
        setLoading(false);
      },
    );
  };

  const getActiveCalls = () => {
    setLoading(true);

    const reqBody = {};

    request.post(
      '/hotlineCalls/getActiveCalls',
      reqBody,
      ({ data, total }) => {
        console.log(allowedPhones);
        dispatch(setHotlineCalls(data.filter((el) => allowedPhones.includes(el.to))));
        setLoading(false);
        setTotal(total);
      },
      () => {
        error();
        setLoading(false);
      },
    );
  };

  useEffect(() => {
    if (activeTab === 'activeCalls') return;
    getHotlineCalls();

    const interval = setInterval(getHotlineCalls, AUTOUPDATE_DELAY);
    return () => clearInterval(interval);
  }, [activeTab, currentPage, hotlineCallsFilter]);

  useEffect(() => {
    if (modal?.prev?.name === ADD_HOTLINE_CALLS_REPORT) {
      getHotlineCalls();
    }
  }, [modal]);
  useEffect(() => {
    if (activeTab !== 'activeCalls') return;
    getActiveCalls();

    const interval = setInterval(() => {
      const currentTime = new Date().getSeconds();
      if (INTERVALS.includes(currentTime)) {
        getActiveCalls();
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [activeTab]);

  return (
    <section className="default_page hotline-calls">
      <Row align="start" justify="space-between">
        <div className="header-left">
          <h2>Гаряча лінія дзвінків</h2>
          <TimeLineForHotlineCalls period={AUTOUPDATE_DELAY} />
        </div>

        <div className="">
          <Button className={clsx('filter-btn', isFilterSelected ? 'active' : '')} type="text" icon={<FilterOutlined />} onClick={handleClickFilter}>
            Фільтр
          </Button>
          {isFilterSelected ? (
            <Button type="link" onClick={handleClearFilter}>
              <u>Очистити</u>
            </Button>
          ) : null}
        </div>
      </Row>

      <Tabs className="tabs" activeKey={activeTab} onChange={handleTabChange} items={tabItems} />

      <div className="">
        <Table
          dataSource={hotlineCalls}
          columns={columns}
          rowKey={(item) => item._id}
          loading={loading}
          expandable={expandable}
          pagination={{ total, current: currentPage, defaultPageSize: DEFAULT_PAGE_SIZE }}
          onChange={handleTableChange}
        />
      </div>
      <ExportHotlineCalls />
    </section>
  );
};

export default CallsHotline;
