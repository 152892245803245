import { useState, useEffect } from 'react';
import { error, request } from '../../tools';
import { useDispatch, useSelector } from 'react-redux';
import { setMainLoader } from '../../store/uiReducer';
import { Row, Col, Typography, Spin, Card, Pagination, DatePicker, Row as AntdRow, Button } from 'antd';
import { ExcelBtn } from 'common_components';
import { CSVLink } from 'react-csv';
import { ROLES } from 'common_constants/business';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import './Suggestions.scss';
import { setModal } from '../../store/commonReducer';
import { EXPORT_SUGGESTION_AND_COMPLAINTS } from 'common_constants/modals';

import Excel from '../../images/excel_logo.svg';

dayjs.extend(relativeTime);

const Suggestions = () => {
  const dispatch = useDispatch();
  const userAuth = useSelector((state) => state.common.userAuth);
  const admin = ROLES[userAuth.role] === ROLES.admin;
  const [suggestions, setSuggestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [suggestionsLength, setSuggestionsLength] = useState(0);
  const [exportData, setExportData] = useState(null);
  const [exportRange, setExportRange] = useState(null);

  const { Text } = Typography;

  const SUGGESTIONS_PER_PAGE = 12;
  const getSuggestions = (page) => {
    setLoading(true);
    request.post(
      '/suggestions/get',
      { page: page },
      ({ data, total }) => {
        setSuggestions(data);
        setSuggestionsLength(total);
        setLoading(false);
      },
      error,
    );
  };

  const loadExport = () => {
    dispatch(setMainLoader(true));

    request.post('/suggestions/export', { exportRange }, ({ data }) => {
      setExportData(data);
      dispatch(setMainLoader(false));
    });
  };

  const formatedExportData =
    exportData && exportData.map((item) => [item.date && dayjs(item.date).format('DD.MM.YYYY HH:mm'), item.phone, item.name, item.suggestion]);

  formatedExportData?.unshift(['Дата', 'Телефон', 'Імя', 'Пропозиція']);

  useEffect(() => {
    getSuggestions(1);
  }, []);

  const onChangePagination = (event) => {
    getSuggestions(event);
  };
  return (
    <section className="suggestions_page default_page">
      <AntdRow justify={'center'} align={'middle'}>
        <h2>Пропозиції</h2>
      </AntdRow>
      <br />
      {admin && (
        <Row style={{ justifyContent: 'center', marginBottom: 15 }}>
          {exportData ? (
            <CSVLink asyncOnClick={true} data={exportData && formatedExportData}>
              <ExcelBtn style={{ width: 'auto' }}>Скачати файл</ExcelBtn>
            </CSVLink>
          ) : (
            <>
              <DatePicker.RangePicker style={{ marginRight: 16 }} onChange={(e) => setExportRange(e)} format="DD.MM.YYYY" />
              <ExcelBtn style={{ width: 'auto' }} onClick={loadExport}>
                Export
              </ExcelBtn>
            </>
          )}
        </Row>
      )}

      {admin && (
        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 16 }}>
          <Button
            style={{ display: 'flex', justifyContent: 'center' }}
            onClick={() => dispatch(setModal({ name: EXPORT_SUGGESTION_AND_COMPLAINTS }))}
          >
            <img src={Excel} alt="Excel" width={24} />
            <Text style={{ marginLeft: 8 }}>Експорт скарг + пропозицій</Text>
          </Button>
        </div>
      )}
      <div>
        <Spin spinning={loading}>
          <Row gutter={[16, 16]}>
            {suggestions.map((suggestion, i) => (
              <Col key={i} xs={{ span: 12 }} lg={{ span: 8 }} xl={{ span: 6 }}>
                <Card className="suggestion">
                  <Typography>
                    <b>Імя:</b> {suggestion.name}
                  </Typography>
                  <Typography>
                    <b>Мобільний:</b> {suggestion.phone}
                  </Typography>
                  <Typography>
                    <b>Дата:</b> {dayjs(suggestion.date).fromNow()}
                  </Typography>
                  <Typography>
                    <b>Пропозиція:</b> {suggestion.suggestion}
                  </Typography>
                </Card>
              </Col>
            ))}
          </Row>
          {suggestionsLength !== 0 && (
            <Pagination
              className="suggestions_page__pagination"
              onChange={onChangePagination}
              defaultPageSize={SUGGESTIONS_PER_PAGE}
              defaultCurrent={1}
              total={suggestionsLength}
            />
          )}
        </Spin>
      </div>
    </section>
  );
};

export default Suggestions;
