import React, { useState, useEffect } from 'react';
import { Button, Divider } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { PaymentManagementBox } from 'common_components';
import { CURRENCIES } from 'common_constants/business';
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs';

import { editContract } from '../../store/commonReducer';
import { request, error, success } from '../../tools';

import './PaymentManagementComponent.scss';

const isPastDate = (date) => {
  return dayjs(date).startOf('day').isBefore(dayjs());
};

const PaymentManagementComponent = ({
  paymentManagement,
  setPaymentManagement,
  monthlyPayments,
  setMonthlyPayments,
  totalInstallment,
  setTotalInstallment,
  paymentManagementForm,
  contract,
  cancelEdit,
  setLoading,
  isNewContract,
  setRemainingMoney,
  setTotalPayByClient = () => {},
  currentCurrency,
}) => {
  const dispatch = useDispatch();
  const [isPaidStatus, setIsPaidStatus] = useState({
    firstPayment: false,
    monthlyPayments: [],
    paymentManagement: [],
    assignments: [],
  });

  const assignments = contract?.assignments?.filter((item) => !item?.incorrect && dayjs(item?.date).startOf('day') > dayjs(contract?.md)) || [];

  // const generatePaymentManagement = () => {
  //   const leftToPay = paymentManagementForm?.pa - (paymentManagementForm?.fp || 0);
  //   const months = paymentManagementForm?.installmentMonths;
  //   const monthlyPayment = leftToPay / months;
  //   const paymentManagement = [];
  //   for (let i = 1; i <= months; i++) {
  //     if (!leftToPay || leftToPay < 0) return;

  //     const paymentManagementItem = {
  //       amount: Math.round(monthlyPayment),
  //       date: dayjs(paymentManagementForm?.md).add(i, 'month').toDate(),
  //     };

  //     paymentManagement.push(paymentManagementItem);
  //   }

  //   setPaymentManagement(paymentManagement);
  // };

  // const generateMonthlyPaymentsManagement = () => {
  //   const paymentsArr = [];
  //   if (paymentManagementForm?.monthlyFee) {
  //     const length = dayjs().diff(dayjs(paymentManagementForm?.md).startOf('month'), 'month');
  //     for (let i = 1; i <= length + 3; i++) {
  //       paymentsArr.push({ date: dayjs(paymentManagementForm?.md).add(i, 'month').toDate(), amount: Number(paymentManagementForm?.monthlyFee) });
  //     }
  //   }

  //   setMonthlyPayments(paymentsArr);
  // };

  const countTotalInstallment = () => {
    const paymentSum = paymentManagement.reduce((acc, item) => acc + +item.amount, 0);
    const sum = (+paymentManagementForm?.pa || 0) - (paymentManagementForm?.fp || 0) - paymentSum;
    setTotalInstallment(sum);
  };

  const fillPaymentManagement = (regenerate = false) => {
    setLoading(true);
    const onSuccess = (res) => {
      if (res.paymentManagement) {
        setPaymentManagement(res.paymentManagement);
        dispatch(editContract({ ...contract, paymentManagement: res.paymentManagement }));
        success('Менеджер платежів заповнено');
        setLoading(false);
      }
    };
    const onError = () => {
      error('Помилка, спробуйте ще раз');
      setLoading(false);
    };
    request.post('/contracts/fillPaymentManagement', { _id: contract._id, regenerate }, onSuccess, onError);
  };

  const addMonth = () => {
    setPaymentManagement([
      ...paymentManagement,
      {
        amount: 0,
        date: dayjs(paymentManagement[paymentManagement.length - 1].date)
          .add(1, 'month')
          .toISOString(),
      },
    ]);
  };

  const editPaymentManagement = () => {
    setLoading(true);
    const onSuccess = () => {
      const data = { ...contract, paymentManagement };
      dispatch(editContract(data));
      success('Менеджер платежів змінено');
      setLoading(false);
      countRemainingMoney();
    };
    const onError = (err) => {
      error('Помилка, спробуйте ще раз');
      setLoading(false);
    };
    request.post('/contracts/editPaymentManagement', { _id: contract._id, paymentManagement }, onSuccess, onError);
  };

  const firstPaymentBox = () => {
    if (!paymentManagementForm?.fp || +paymentManagementForm?.fp === 0) return null;
    const firstPayment = Number(paymentManagementForm?.fp) || 0;
    const firstPaymentDate = paymentManagementForm?.md || paymentManagementForm?.sd;
    const isPastItemDate = isPastDate(firstPaymentDate);
    let style =
      isPaidStatus.firstPayment && isPastItemDate
        ? { background: 'lightgreen' }
        : !isPaidStatus.firstPayment && isPastItemDate
        ? { background: 'lightcoral' }
        : {};

    return <PaymentManagementBox key={firstPaymentDate} amount={firstPayment} date={firstPaymentDate} style={style} disabled />;
  };

  const countRemainingMoney = () => {
    setLoading(true);

    const reqData = {
      contractI: contract?.i,
    };

    request.post(
      '/contracts/paymentManagementRemainingMoneyCrm',
      reqData,
      ({ data }) => {
        setIsPaidStatus(data?.paymentManagementPaid);
        setRemainingMoney(data?.remainingMoney);
        setTotalPayByClient(data?.totalClientPaid);
        setLoading(false);
      },
      () => {
        setLoading(false);
        error();
      },
    );
  };

  useEffect(() => {
    countTotalInstallment();
  }, [paymentManagement, monthlyPayments, paymentManagementForm?.pa]);

  useEffect(() => {
    if (!contract?.i) return;

    countRemainingMoney();
  }, [contract]);

  const firstPaymentBoxRender = firstPaymentBox();

  return (
    <div>
      <Divider orientation="left" plain>
        <b>Розстрочка</b>
      </Divider>
      {contract?.installmentMonths ? (
        <div className="payment-management">
          <div style={{ textAlign: 'right', margin: 20 }}>
            <Button disabled={isNewContract} type="primary" onClick={() => fillPaymentManagement(true)}>
              Перегенерувати
            </Button>
          </div>
          <div className="payment-management-block">
            {paymentManagement?.map((item, index) => {
              const isPaid = isPaidStatus.paymentManagement?.[index];
              const isPastItemDate = isPastDate(item?.date);

              return (
                <PaymentManagementBox
                  key={item.date}
                  amount={item.amount}
                  index={index}
                  date={item.date}
                  paymentManagement={paymentManagement}
                  setPaymentManagement={setPaymentManagement}
                  style={isPaid && isPastItemDate ? { background: 'lightgreen' } : !isPaid && isPastItemDate ? { background: 'lightcoral' } : null}
                />
              );
            })}
            <div onClick={addMonth} className="plus-button">
              <PlusCircleOutlined />
            </div>
          </div>
          <div style={{ textAlign: 'right', fontSize: 20, height: 30, marginTop: 20 }}>
            {totalInstallment !== 0 && (
              <>
                {totalInstallment < 0 ? (
                  <>
                    Переплата:
                    <b style={{ color: 'green' }}>
                      {Math.abs(totalInstallment)}
                      {CURRENCIES[currentCurrency].symbol}
                    </b>
                  </>
                ) : (
                  <>
                    Розподіліть ще:
                    <b style={{ color: 'red' }}>
                      {totalInstallment}
                      {CURRENCIES[currentCurrency].symbol}
                    </b>
                  </>
                )}
              </>
            )}
          </div>
          <div style={{ textAlign: 'right', margin: 20 }}>
            <Button style={{ marginRight: 10 }} onClick={cancelEdit}>
              Відмінити
            </Button>
            <Button disabled={isNewContract || totalInstallment !== 0} type="primary" onClick={editPaymentManagement}>
              Зберегти
            </Button>
          </div>
        </div>
      ) : (
        <div>Немає даних</div>
      )}
      <Divider orientation="left" plain>
        <b>Перший платіж:</b>
      </Divider>
      <div className="payment-management">
        <div className="payment-management-block">{firstPaymentBoxRender}</div>
      </div>
      {assignments?.length > 0 ? (
        <>
          <Divider orientation="left" plain>
            <b>Виходи</b>
          </Divider>
          <div className="payment-management">
            <div className="payment-management-block">
              {assignments?.map((item, index) => (
                <PaymentManagementBox
                  key={index}
                  amount={item.clientAmount}
                  index={index}
                  date={item.date}
                  style={isPaidStatus.assignments?.[index] ? { background: 'lightgreen' } : { background: 'lightcoral' }}
                  disabled
                />
              ))}
            </div>
          </div>
        </>
      ) : null}
      {monthlyPayments?.length > 0 ? (
        <>
          <Divider orientation="left" plain>
            <b>Щомісячні платежі</b>
          </Divider>
          <div className="payment-management">
            <div className="payment-management-block">
              {monthlyPayments?.map((item, index) => {
                const isPaid = isPaidStatus.monthlyPayments?.[index];
                const isPastItemDate = isPastDate(item?.date);
                const isPaidInAdvance = item?.isPaidInAdvance;
                return (
                  <PaymentManagementBox
                    key={item.date}
                    amount={item.amount}
                    index={index}
                    date={item.date}
                    style={
                      isPaid && (isPastItemDate || isPaidInAdvance)
                        ? { background: 'lightgreen' }
                        : !isPaid && isPastItemDate
                        ? { background: 'lightcoral' }
                        : null
                    }
                    disabled
                  />
                );
              })}
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default PaymentManagementComponent;
