import { useState, useImperativeHandle, forwardRef, useEffect } from 'react';
import { Form, Divider, Input, DatePicker, Button, Checkbox, Upload } from 'antd';
import { PlusOutlined, CloseOutlined, UploadOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

const { Item, Provider } = Form;
const { TextArea } = Input;

const ShortQuestionnaireComponent = forwardRef(({ data }, ref) => {
  const [mainForm] = Form.useForm();
  const [taskForm] = Form.useForm();

  const [isCreating, setIsCreating] = useState(false);
  const [checkNotDate, setCheckNotDate] = useState(false);
  const [checkNotDateFinalResult, setCheckNotDateFinalResult] = useState(false);

  const [questionnaireForm, setQuestionnaireForm] = useState({
    plotCase: '',
    customerWants: '',
    promiseClient: '',
    dateFinalResult: null,
    note: '',
    tasks: [],
  });

  const [fileList, setFileList] = useState([]);

  const props = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList((prevFileList) => [...prevFileList, file]);
      return false;
    },
    fileList,
  };

  const handlerCreatingTask = () => {
    setIsCreating((prev) => !prev);
  };

  const handleTaskFormSubmit = (values) => {
    const newTask = {
      id: generateKey(),
      description: values.description,
      ...(values.date !== undefined && values.date !== null && { date: values.date }),
      ...(values.notDate !== undefined && { notDate: values.notDate }),
    };
    setQuestionnaireForm({ ...questionnaireForm, tasks: [...questionnaireForm.tasks, newTask] });

    setIsCreating(false);
    taskForm.resetFields();
    setCheckNotDate(false);
  };

  const handleCheckNotDate = (e) => {
    setCheckNotDate(e.target.checked);
    taskForm.validateFields(['date']);
  };

  const handleCheckNotDateFinalResult = (e) => {
    setCheckNotDateFinalResult(e.target.checked);
    mainForm.validateFields(['dateFinalResult']);
  };

  const updateFieldTask = (id, index, field, newValue) => {
    const updatedTasks = [...questionnaireForm.tasks];
    updatedTasks[index] = { ...updatedTasks[index], [field]: newValue };
    setQuestionnaireForm({ ...questionnaireForm, tasks: updatedTasks });

    mainForm.validateFields([`date${id}`, `notDate${id}`]);
  };

  const deleteField = (id) => {
    const updatedTasks = questionnaireForm.tasks.filter((task) => task.id !== id);
    setQuestionnaireForm({ ...questionnaireForm, tasks: updatedTasks });
  };

  const generateKey = () => `${Math.random().toString(36).slice(2, 11)}`;

  const prepareQuestionnaireData = () => {
    const questionnaireData = {
      ...mainForm.getFieldsValue(['plotCase', 'customerWants', 'promiseClient', 'note']),
      ...(checkNotDateFinalResult ? { notDateFinalResult: true } : {}),
      ...(mainForm.getFieldValue('dateFinalResult') ? { dateFinalResult: mainForm.getFieldValue('dateFinalResult').toISOString() } : {}),
      ...(fileList.length > 0 ? { files: fileList } : {}),
      ...(questionnaireForm.tasks.length > 0 ? { tasks: questionnaireForm.tasks } : {}),
    };

    return questionnaireData;
  };

  const submitQuestionnaireForm = async () => {
    try {
      await mainForm.validateFields();

      if (questionnaireForm.tasks.length > 0) {
        const questionnaireData = {
          ...mainForm.getFieldsValue(['plotCase', 'customerWants', 'promiseClient', 'note']),
          ...(checkNotDateFinalResult ? { notDateFinalResult: true } : {}),
          ...(mainForm.getFieldValue('dateFinalResult') ? { dateFinalResult: mainForm.getFieldValue('dateFinalResult').toISOString() } : {}),
          ...(fileList.length > 0 ? { files: fileList } : {}),
          tasks: questionnaireForm.tasks,
        };

        return questionnaireData;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  };

  useImperativeHandle(ref, () => ({
    submitQuestionnaireForm,
    prepareQuestionnaireData,
  }));

  useEffect(() => {
    if (data?.shortQuestionnaire) {
      setCheckNotDateFinalResult(data.shortQuestionnaire.notDateFinalResult || false);

      setFileList(data.shortQuestionnaire?.files || []);

      const updatedForm = {
        plotCase: data.shortQuestionnaire.plotCase || '',
        customerWants: data.shortQuestionnaire.customerWants || '',
        promiseClient: data.shortQuestionnaire.promiseClient || '',
        dateFinalResult: data.shortQuestionnaire.dateFinalResult ? dayjs(data.shortQuestionnaire.dateFinalResult) : null,
        note: data.shortQuestionnaire.note || '',
      };

      let newTasks = data.shortQuestionnaire.tasks || [];

      if (data.shortQuestionnaire?.tasks?.length > 0 && data.shortQuestionnaire.tasks.every((task) => !task.id)) {
        newTasks = data.shortQuestionnaire.tasks.map((task) => ({
          ...task,
          id: generateKey(),
        }));
      }

      setQuestionnaireForm({
        ...updatedForm,
        tasks: newTasks,
      });

      mainForm.setFieldsValue(updatedForm);
    }
  }, [data?.shortQuestionnaire]);

  return (
    <div className="short-questionnaire-component">
      <Divider orientation="left" plain>
        <b>Довідка меморандум</b>
      </Divider>
      <Provider
        onFormFinish={(name, { values }) => {
          if (name === 'taskForm') {
            handleTaskFormSubmit(values);
          }
        }}
      >
        <Form layout="vertical" form={mainForm}>
          <Item
            label="Фабула справи"
            name="plotCase"
            rules={[
              {
                required: true,
                message: "Обов'язково для заповнення",
              },
              { min: 500, message: 'Мінімум 500 символів!' },
            ]}
          >
            <TextArea value={questionnaireForm.plotCase} placeholder="Фабула справи" />
          </Item>

          <Item
            label="Що хоче клієнт"
            name="customerWants"
            rules={[
              {
                required: true,
                message: "Обов'язково для заповнення",
              },
              { min: 100, message: 'Мінімум 100 символів!' },
            ]}
          >
            <TextArea value={questionnaireForm.customerWants} placeholder="Що хоче клієнт" />
          </Item>

          <Item
            label="Що обіцяли клієнту"
            name="promiseClient"
            rules={[
              {
                required: true,
                message: "Обов'язково для заповнення",
              },
              { min: 100, message: 'Мінімум 100 символів!' },
            ]}
          >
            <TextArea value={questionnaireForm.promiseClient} placeholder="Що обіцяли клієнту" />
          </Item>

          <p>
            <b style={{ color: 'red' }}>*</b> Орієнтовні завдання
          </p>

          {questionnaireForm.tasks.length === 0 ? (
            <div style={{ textAlign: 'center', color: 'red' }}>Додайте хоча б одне завдання!</div>
          ) : (
            questionnaireForm.tasks.map((task, index) => (
              <div key={task.id} className="task">
                <Item
                  label="Опис"
                  name={`description${task.id}`}
                  rules={[
                    {
                      required: true,
                      message: "Обов'язково для заповнення",
                    },
                  ]}
                  initialValue={task.description}
                >
                  <TextArea onBlur={(e) => updateFieldTask(task.id, index, 'description', e.target.value)} placeholder="Введіть назву..." />
                </Item>

                <Item
                  label="Термін"
                  layout="horizontal"
                  name={`date${task.id}`}
                  rules={[
                    {
                      required: task?.notDate ? false : true,
                      message: "Обов'язково для заповнення",
                    },
                  ]}
                  initialValue={task?.date ? dayjs(task?.date) : null}
                >
                  <DatePicker onChange={(e) => updateFieldTask(task.id, index, 'date', e ? e.toISOString() : null)} />
                </Item>

                <Item layout="horizontal" name={`notDate${task.id}`}>
                  <Checkbox checked={task?.notDate} onChange={(e) => updateFieldTask(task.id, index, 'notDate', e.target.checked)}>
                    Термін не обговорювався
                  </Checkbox>
                </Item>

                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button onClick={() => deleteField(task.id)}>Видалити</Button>
                </div>
              </div>
            ))
          )}

          {isCreating && (
            <Form className="task-form" name="taskForm" layout="vertical" form={taskForm}>
              <Item
                label="Опис"
                name="description"
                rules={[
                  {
                    required: true,
                    message: "Обов'язково для заповнення",
                  },
                ]}
              >
                <TextArea placeholder="Введіть назву..." />
              </Item>
              <Item
                label="Термін"
                name="date"
                rules={[
                  {
                    required: checkNotDate ? false : true,
                    message: "Обов'язково для заповнення",
                  },
                ]}
              >
                <DatePicker />
              </Item>

              <Item name="notDate" valuePropName="checked" onChange={handleCheckNotDate}>
                <Checkbox>Термін не обговорювався</Checkbox>
              </Item>

              <Button type="primary" onClick={() => taskForm.submit()}>
                Створити
              </Button>
              <Button type="text" icon={<CloseOutlined />} onClick={handlerCreatingTask}></Button>
            </Form>
          )}

          <Button style={{ display: isCreating ? 'none' : 'block', margin: '15px auto 0' }} icon={<PlusOutlined />} onClick={handlerCreatingTask}>
            Додати завдання
          </Button>

          <Item
            style={{ marginTop: 24 }}
            label="Дата кінцевого результату"
            name="dateFinalResult"
            rules={[{ required: checkNotDateFinalResult ? false : true, message: "Обов'язково для заповнення" }]}
            initialValue={questionnaireForm?.dateFinalResult ? dayjs(questionnaireForm?.dateFinalResult) : null}
          >
            <DatePicker />
          </Item>

          <Item layout="horizontal">
            <Checkbox checked={checkNotDateFinalResult} onChange={handleCheckNotDateFinalResult}>
              Термін не обговорювався
            </Checkbox>
          </Item>

          <Item label="Примітка" name="note">
            <TextArea value={questionnaireForm.note} placeholder="Примітка" />
          </Item>

          {data.isNewContract && (
            <div className="image-uploader">
              <Upload {...props} multiple>
                <Button icon={<UploadOutlined />}>Завантажити файли в чат з клієнтом</Button>
              </Upload>
            </div>
          )}
        </Form>
      </Provider>
    </div>
  );
});

export default ShortQuestionnaireComponent;
