import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Badge, Button } from 'antd';
import { URIST_ACTIVITY, URIST_ACTIVITY_REASONS, CHAT_TYPES, ROLES } from 'common_constants/business';
import dayjs from 'dayjs';
import { ReloadOutlined } from '@ant-design/icons';

import { error, request } from '../../tools';
import { setMainLoader } from '../../store/uiReducer';
import ActivitySwitcherModal from './ActivitySwitcherModal';
import { setChatsData, setCurrentHotlineList } from '../../store/commonReducer';

// Activity - component for changing user activity status
// (on work place or not)
// It is used in Menu component
const ActivitySwitcher = () => {
  // component logic includes chaking if sending data is not equal to DB data
  // to avoid not syncronized data sending
  const dispatch = useDispatch();
  const userAuth = useSelector((state) => state.common.userAuth);
  const filii = useSelector((state) => state.common.filii) || [];

  const [value, setValue] = useState(null);
  const [modal, setModal] = useState(false);
  const [reason, setReason] = useState(null);
  const [ownReason, setOwnReason] = useState('');
  const [isNotReportedDialogues, setIsNotReportedDialogues] = useState(false);
  const [isUnansweredDialogues, setisUnansweredDialogues] = useState(false);

  const { activity, role: userRole, _id: userId } = userAuth;

  const inspector = ROLES[userRole] <= ROLES.inspector;
  const psychologistOnly = ROLES[userRole] === ROLES.psychologist;

  const checkIfThereAreUnansweredMessages = async () => {
    let result = false;

    await request.post(
      '/chatPrivate/getAllChatsForUser',
      {
        type: CHAT_TYPES.chatWithClient.key,
        offHoursSwitch: true,
      },
      ({ data }) => {
        const chatsWithClient = data?.map((item) => ({ ...item, messages: item?.messages?.filter((message) => !message?.removed) }));

        const isUnansweredDialogues = chatsWithClient.find(({ clientId, messages }) => {
          const lastMessage = messages?.[messages.length - 1];

          return lastMessage?.sender === clientId;
        });

        dispatch(setChatsData({ type: CHAT_TYPES.chatWithClient.key, data: chatsWithClient }));

        if (isUnansweredDialogues) result = true;
      },
      error,
    );

    return result;
  };

  const isUnannouncedHotline = (unannouncedHotlin, userId) => {
    if (unannouncedHotlin?.amm) {
      return unannouncedHotlin?.amm === userId ? true : false;
    }
    if (unannouncedHotlin?.bmm) {
      return unannouncedHotlin?.bmm === userId ? true : false;
    }
    if (!unannouncedHotlin?.amm && !unannouncedHotlin?.bmm) {
      const hotlineFil = filii.find((fil) => parseInt(unannouncedHotlin?.fil) === fil.i);
      if (hotlineFil?.managerId === userId) {
        return true;
      }
    }
  };

  const checkingReportingOnHotlines = async () => {
    const transaction = {
      date: new Date().toISOString(),
      toDate: null,
    };
    let result = false;
    await request.post(
      '/hotline/getAll',
      transaction,
      (req) => {
        dispatch(setCurrentHotlineList(req.result));
        const now = dayjs();
        const filteredHotlines = req.result.filter((hotline) => {
          const startT = dayjs(hotline.startT, 'HH:mm');
          return startT.isBefore(now);
        });

        const unannouncedHotline = filteredHotlines.find((hotline) => !hotline?.s);
        if (unannouncedHotline) {
          result = isUnannouncedHotline(unannouncedHotline, userId);
        }
      },
      error,
    );
    return result;
  };

  const onChange = async (checked) => {
    dispatch(setMainLoader(true));

    if (!inspector && !checked && !psychologistOnly) {
      const isUnansweredDialogues = await checkIfThereAreUnansweredMessages();

      if (isUnansweredDialogues) {
        setisUnansweredDialogues(isUnansweredDialogues);
      } else {
        setisUnansweredDialogues(false);
      }

      const isNotReportedDialogues = await checkingReportingOnHotlines();

      if (isNotReportedDialogues) {
        setIsNotReportedDialogues(isNotReportedDialogues);
      } else {
        setIsNotReportedDialogues(false);
      }
    }

    request.post(
      '/profile/activity',
      { value: checked ? 1 : 2, reason, dateObj: new Date() },
      (res) => {
        dispatch(setMainLoader(false));
        if (res.oldData) return setValue(res.value === 1 ? true : false);
        if (!checked) setModal(true);
        else setValue(checked);
        setReason(null);
        setOwnReason('');
      },
      errorHandle,
    );
  };

  const clickHandler = () => onChange(!value);

  const errorHandle = () => {
    dispatch(setMainLoader(false));
    error('Помилка зміни статусу');
  };

  const updateActivity = ({ value, reason, ownReason }) => {
    setValue(value === 1 ? true : false);
    if (value === 1) return;
    setReason(reason);
    setOwnReason(ownReason);
  };

  const getMyStatus = () => {
    request.post('/profile/getMyActivity', {}, updateActivity, errorHandle);
  };

  useEffect(() => {
    if (activity && value === null) {
      setValue(activity?.value === 1 ? true : false);
      setReason(activity?.reason);
      setOwnReason(activity?.ownReason);
    }
  }, [activity]);

  useEffect(() => {
    setInterval(getMyStatus, 1000 * 60 * 10); // 10 min
  }, []);

  const noStatus = value === (false || null) && reason === (undefined || null);

  const statusText = noStatus
    ? URIST_ACTIVITY[0].label
    : value
    ? URIST_ACTIVITY[1].label
    : reason === 21
    ? ownReason
    : URIST_ACTIVITY_REASONS[reason]?.label;

  return (
    <div className="activity">
      <Switch className="switch" checked={value} onChange={onChange} />
      <Badge dot color={value ? 'green' : 'red'} size="big" style={{ display: 'block' }}>
        <div className={noStatus ? 'red' : 'status'} onClick={clickHandler}>
          {statusText}
        </div>
      </Badge>
      {!statusText && (
        <Button icon={<ReloadOutlined />} onClick={getMyStatus} type="link">
          Оновити
        </Button>
      )}
      {modal && (
        <ActivitySwitcherModal
          value={value}
          reason={reason}
          ownReason={ownReason}
          setValue={setValue}
          setModal={setModal}
          setReason={setReason}
          setOwnReason={setOwnReason}
          inspector={inspector}
          isUnansweredDialogues={isUnansweredDialogues}
          isNotReportedDialogues={isNotReportedDialogues}
        />
      )}
    </div>
  );
};

export default React.memo(ActivitySwitcher);
