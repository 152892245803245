import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { Modal, Form, Button, Select, DatePicker } from 'antd';
import { CloseOutlined, FileAddOutlined, CheckOutlined } from '@ant-design/icons';
import { lime, orange, volcano, yellow } from '@ant-design/colors';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { HotlineTimePicker } from 'common_components';
import { PSYCHOLOGIST_WORK_TIME } from 'common_constants/business';

import { onTimeClear, onTimeClick } from 'common_components/src/HotlineStep1/Step1/timeUtils';

import Box from '../../components/Box';
import { setModal, setCurrentPsychologistHotline, setCurrentPsychologistHotlineCalendar } from '../../store/commonReducer';
import { setMainLoader } from '../../store/uiReducer';

import { error, info, request, success, warn } from '../../tools';
import './style.scss';
const { Option } = Select;
dayjs.extend(utc);

const PsychologistHotlineEdit = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const users = useSelector((state) => state.common.users);
  const { _id, date, endT, fil, startT, toDate, clientId, dateList, selectedDate, psychologistOnly } = useSelector((state) => state.common.modal);

  const [currentUser, setCurrentUser] = useState(users[clientId]);
  const [currentStartT, setStartT] = useState(startT);
  const [newEndT, setNewEndT] = useState(endT);
  const [newStart, setNewStart] = useState(startT);
  const [endT1, setEndT1] = useState();
  const [endT2, setEndT2] = useState();
  const [blockedArr, setBlockedArr] = useState([]);
  const [blockedArrInfo, setBlockedArrInfo] = useState({});
  const [currentSelectedDate, setCurrentSelectedDate] = useState(dayjs(date).utc().subtract(1, 'day'));
  const [phone, setPhone] = useState(currentUser?.userPersonalData?.workPhone);

  const currentendT = endT2 || endT1;

  const handleUserSelect = (value) => {
    const selectedUser = Object.values(users).find(({ _id }) => _id === value);
    setCurrentUser(selectedUser);
  };
  const handleChangePhone = (value) => {
    setPhone(value);
  };

  const handleDateChange = (date) => {
    setCurrentSelectedDate(date);
  };

  const handleStartChange = (value) => {
    setNewStart(value);
  };

  const handleEndChange = (value) => {
    setNewEndT(value);
  };
  const handleUserBlock = async () => {
    dispatch(setMainLoader(true));
    request.post(
      '/psychologistHotline/edit',
      {
        _id,
        startT: newStart,
        endT: newEndT,
        date: dayjs(currentSelectedDate).add(1, 'day').utc(),
        newClientId: currentUser._id,
        newClientPh: phone,
        newClientName: currentUser.name,
      },
      () => {
        success();
        updateHotlineList();
        updatedpsychologistHotlineCalendar(selectedDate);
        dispatch(setMainLoader(false));
      },
      error,
    );
    dispatch(setModal());
  };
  const updatedpsychologistHotlineCalendar = (selectedDate) => {
    const requestData = {
      startDate: selectedDate.startOfWeek,
      endDate: selectedDate.endOfWeek,
      calendar: true,
    };

    const onSuccess = (req) => {
      dispatch(setCurrentPsychologistHotlineCalendar(req.data));
    };

    const onError = (_, __, { response }) => {
      const hotlineError = response?.data?.error;
      if (hotlineError) {
        error('Помилка!', hotlineError);
      }
      dispatch(setMainLoader(false));
    };

    request.post('/psychologistHotline/get', requestData, onSuccess, onError);
  };
  const updateHotlineList = async () => {
    const requestData = {
      startDate: dateList,
      endDate: toDate,
    };

    await request.post(
      '/psychologistHotline/get',
      requestData,
      (req) => {
        dispatch(setCurrentPsychologistHotline(req.data));
      },
      (error) => {
        console.error('Error in request:', error);
      },
    );
  };

  const onCancel = () => {
    dispatch(setModal());
    info('', 'Ви не згодні зі своїм рішенням.');
  };
  const handleTimeClick = (e) => {
    const index = PSYCHOLOGIST_WORK_TIME.indexOf(e.target.innerText);
    if (index > 32) {
      warn('', 'Мінімальний час прийому — 1 година. Ви обрали занадто пізній час.');
      return;
    }

    onTimeClick(startT, endT, endT1, endT2, setEndT1, setEndT2, setStartT, e.target, '8', true);
  };
  const handleClickClearTime = () => onTimeClear(setStartT, setEndT1, setEndT2);

  const isOptionDisabled = (time) => {
    const startIndex = PSYCHOLOGIST_WORK_TIME.indexOf(startT);
    const endIndex = PSYCHOLOGIST_WORK_TIME.indexOf(endT);
    const currentTime = Array.from({ length: endIndex - startIndex + 1 }, (_, i) => startIndex + i);
    const timeIndex = PSYCHOLOGIST_WORK_TIME.indexOf(time);

    const isToday = dayjs(date).utc().isSame(dayjs().utc(), 'day');
    const isTimePassed = dayjs()
      .utc()
      .isAfter(dayjs(`${dayjs().format('YYYY-MM-DD')} ${time}`));

    return (blockedArr.includes(timeIndex) && !currentTime.includes(timeIndex)) || (isToday && isTimePassed);
  };

  const disablePastDates = (current) => {
    return current && current.isBefore(dayjs().utc().startOf('day'));
  };

  useEffect(() => {
    const requestData = {
      startDate: currentSelectedDate,
      endDate: currentSelectedDate,
      edit: true,
    };

    const onSuccess = (req) => {
      const dayIndicesArray = [];
      const dayIndicesObject = {};

      req.data.forEach((h) => {
        const startIndex = PSYCHOLOGIST_WORK_TIME.indexOf(h.startT);
        const endIndex = PSYCHOLOGIST_WORK_TIME.indexOf(h.endT);

        if (startIndex !== -1 && endIndex !== -1 && startIndex <= endIndex) {
          dayIndicesArray.push(...Array.from({ length: endIndex - startIndex + 1 }, (_, i) => startIndex + i));
          for (let i = startIndex; i <= endIndex; i++) {
            dayIndicesObject[i] = h;
          }
        }
      });

      setBlockedArr(dayIndicesArray);
      setBlockedArrInfo(dayIndicesObject);
    };

    const onError = (_, __, { response }) => {
      const hotlineError = response?.data?.error;
      if (hotlineError) {
        error('Помилка!', hotlineError);
      }
      dispatch(setMainLoader(false));
    };

    request.post('/psychologistHotline/get', requestData, onSuccess, onError);
  }, [currentSelectedDate]);

  return (
    <Modal open className="block-add-user" title={'Редагувати консультацію'} onCancel={onCancel} footer={null}>
      <Form className="block-form" layout="vertical" onFinish={handleUserBlock} form={form}>
        <div className="name-select-wrapper">
          <label>
            ПІБ
            <Select
              showSearch
              value={currentUser.name}
              onChange={handleUserSelect}
              className="Select"
              style={{ width: '100%' }}
              placeholder="Введіть ім'я"
              optionFilterProp="children"
              filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
              filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
            >
              {Object.values(users).map((user) => (
                <Option key={user._id} value={user._id}>
                  {user.name}
                </Option>
              ))}
            </Select>
          </label>
          <label>
            Телефон
            <Select
              className="Select"
              style={{ width: '100%' }}
              value={phone}
              onChange={handleChangePhone}
              mode="combobox"
              placeholder="Оберіть номер"
            >
              <Option key={currentUser.userPersonalData?.workPhone}>{currentUser.userPersonalData?.workPhone}</Option>
              <Option key={currentUser.userPersonalData?.personalPhone}>{currentUser.userPersonalData?.personalPhone}</Option>
            </Select>
          </label>
        </div>

        <HotlineTimePicker
          startT={newStart}
          endT={newEndT}
          blockedArr={blockedArr}
          onTimeClick={handleTimeClick}
          onTimeClear={handleClickClearTime}
          isForCRM={true}
          date={currentSelectedDate}
          psychologist={true}
          edit={true}
          psychologistOnly={psychologistOnly}
          blockedArrInfo={blockedArrInfo}
        />
        <div className="time-date-wrapper">
          <div className="time-wrapper">
            <Select placeholder="Початок консультації" value={newStart} className="Select" style={{ width: 100 }} onChange={handleStartChange}>
              {PSYCHOLOGIST_WORK_TIME.map((time, index) => (
                <Option key={index} value={time} disabled={isOptionDisabled(time)}>
                  {time}
                </Option>
              ))}
            </Select>

            <Select placeholder="Кінець консультації" className="Select" value={newEndT} style={{ width: 100 }} onChange={handleEndChange}>
              {PSYCHOLOGIST_WORK_TIME.map((time, index) => (
                <Option key={index} value={time} disabled={isOptionDisabled(time)}>
                  {time}
                </Option>
              ))}
            </Select>
          </div>
          <DatePicker className="DatePicker" disabledDate={disablePastDates} onChange={handleDateChange} value={currentSelectedDate} />
        </div>
        <Box className="group-button">
          <Button className="group-button__btn" type="primary" style={{ backgroundColor: lime.primary, borderColor: lime.primary }} htmlType="submit">
            <CheckOutlined /> Змінити
          </Button>
          <Button className="group-button__btn" type="primary" danger onClick={onCancel}>
            <CloseOutlined />
            Скасувати
          </Button>
        </Box>
      </Form>
    </Modal>
  );
};
export default PsychologistHotlineEdit;
