import React from 'react';
import { Row, Progress, Tooltip, Table, Rate, Col, Button, Typography } from 'antd';
import { FolderOpenOutlined, CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';

import { setModal } from '../../../../store/commonReducer';
import { ARCHIVE_CONTRACT } from 'common_constants/modals';
import { ROLES } from 'common_constants/business';

import getResultForInspectorColumns from '../../VotingHelpers/getResultForInspectorColumns';

import './VotingResult.scss';

const VotingResult = ({ allVotes = [], contract, notMyBranch, archiveCandy }) => {
  const dispatch = useDispatch();

  const users = useSelector((state) => state.common.users);
  const contracts = useSelector((state) => state.common.contracts);
  const { role, _id: userId } = useSelector((state) => state.common.userAuth);
  const inspector = ROLES[role] <= ROLES.inspector;

  const currentContract = contracts.find((contractItem) => contract._id === contractItem._id);

  const votesWithRating = allVotes.filter((voteItem) =>
    'rating' in voteItem && voteItem.vote && contract?.block !== users[voteItem.votedUserId]?.block ? true : false,
  );

  const blockAverageScore = votesWithRating.reduce((acc, currentVoteItem) => acc + currentVoteItem.rating, 0) / votesWithRating.length;
  const forVotes = allVotes.reduce((acc, currentVoteItem) => (currentVoteItem.vote ? acc + 1 : acc), 0);
  const totalVotes = allVotes.length;
  const againstVotes = totalVotes - forVotes;

  const forPercentage = (forVotes / totalVotes) * 100;

  const onArchive = () => dispatch(setModal({ name: ARCHIVE_CONTRACT, data: currentContract }));

  const columns = getResultForInspectorColumns(contract?.block, users, notMyBranch);

  return (
    <>
      <Row justify={'center'} className="result__title">
        Результати голосування
      </Row>

      {totalVotes > 0 ? (
        <div className="archive-candy-result">
          <p>
            Архівну цукерку {!archiveCandy?.candyGiven ? <b>не</b> : null} було надано {archiveCandy?.candyGiven ? '✔' : '❌'}
          </p>
          {!archiveCandy?.candyGiven ? <Typography>Причина: {archiveCandy.candyNotGivenReason}</Typography> : null}
        </div>
      ) : null}

      <Tooltip
        title={
          <>
            <CheckCircleTwoTone twoToneColor="#52c41a" className="voting-contracts-result-tooltip-success__icon" />
            &nbsp;{`- ${forVotes} `}
            /&nbsp;
            <CloseCircleTwoTone twoToneColor="red" className="voting-contracts-result-tooltip-failure__icon" />
            {`- ${againstVotes}`}
          </>
        }
      >
        <Progress
          percent={100}
          strokeColor={allVotes.length ? '#ff4d4f' : '#d2d2d4'}
          success={{
            percent: forPercentage,
            strokeColor: '#52c41a',
            showInfo: false,
          }}
          showInfo={false}
        />
      </Tooltip>

      {notMyBranch && contract?.block && (
        <Col className="result__rate">
          <Row justify={'center'}> {blockAverageScore ? 'Середня оцінка блоку' : 'Поки нема жодної оцінки блоку'} </Row>
          <Row justify={'center'}>{blockAverageScore ? <Rate disabled value={Math.round(blockAverageScore)} justify={'center'} /> : null}</Row>
        </Col>
      )}

      <Table
        rowKey={(record) => record._id}
        columns={columns}
        dataSource={allVotes}
        pagination={{
          pageSize: 5,
          style: { justifyContent: 'center' },
        }}
      />
      {inspector && (
        <Row>
          <Button icon={<FolderOpenOutlined />} type="primary" onClick={onArchive} className="result-toArchive__bitton">
            В архів
          </Button>
        </Row>
      )}
    </>
  );
};

export default VotingResult;
